import Axios from '@/api/provider/axios';
import processMessages from '@/modules/mapping/api/process-messages';

export default class MappingProvider extends Axios {
    constructor() {
        super();

        this.instance.interceptors.response.use(undefined, (response) => {
            processMessages(response);

            return Promise.reject(response);
        });
    }
}
