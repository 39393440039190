<template>
    <transition
        enter-active-class="transition-opacity duration-300 ease-in-out"
        leave-active-class="transition-opacity duration-300 ease-in-out"
        enter-class="opacity-0"
        leave-to-class="opacity-0"
        mode="out-in"
        @after-leave="handleAfterLeave"
    >
        <div
            v-show="show"
            class="left-0 top-0 !m-0 flex flex-col items-center justify-center border-none bg-white p-3"
            :class="overlayClass"
        >
            <div v-if="showMessage" class="max-w-full break-words text-center text-science-blue-900" :class="messageClass">
                <span v-html="text" />
                <span v-if="messageDots">...</span>
            </div>
            <div class="relative flex items-center justify-center" :class="wrapperClass">
                <template v-if="dot">
                    <div
                        class="animate-wave rounded-full bg-science-blue-900"
                        :class="dotClass"
                    />
                    <div
                        class="animate-wave rounded-full bg-science-blue-900 animation-delay-150"
                        :class="dotClass"
                    />
                    <div
                        class="animate-wave rounded-full bg-science-blue-900 animation-delay-300"
                        :class="dotClass"
                    />
                </template>
                <template v-else>
                    <svg
                        class="animate-spin text-science-blue-900"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        :class="spinnerClass"
                    >
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4" />
                        <path
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        />
                    </svg>
                </template>
            </div>
        </div>
    </transition>
</template>

<script>
import { get } from 'lodash';
import useWebSockets from '@/composables/useWebSockets';

const {
    on:  onWebSocket,
    off: offWebSocket,
} = useWebSockets();

export default {
    data() {
        return {
            show:          false,
            full:          false,
            noZ:           false,
            opaque:        true,
            showMessage:   true,
            message:       '',
            messageDots:   true,
            dot:           false,
            size:          'md',
            socketMessage: undefined,
            onShow:        undefined,
            onHide:        undefined,
        };
    },
    computed: {
        text() {
            return this.message || 'Loading';
        },
        overlayClass() {
            const sizes = {
                sm:    'space-y-1',
                md:    'space-y-2',
                lg:    'space-y-3',
                xl:    'space-y-4',
                '2xl': 'space-y-5',
            };

            return {
                'z-100':                         !this.noZ,
                'absolute w-full h-full':        !this.full,
                'fixed w-screen h-screen z-max': this.full,
                'bg-opacity-90':                 !this.opaque,
                'bg-opacity-100':                this.opaque,
                [sizes[this.size]]:              true,
            };
        },
        wrapperClass() {
            const sizes = {
                sm:    'space-x-1',
                md:    'space-x-2',
                lg:    'space-x-3',
                xl:    'space-x-4',
                '2xl': 'space-x-5',
            };

            return sizes[this.size];
        },
        dotClass() {
            const sizes = {
                sm:    'w-2 h-2',
                md:    'w-3 h-3',
                lg:    'w-4 h-4',
                xl:    'w-5 h-5',
                '2xl': 'w-6 h-6',
            };

            return sizes[this.size];
        },
        spinnerClass() {
            const sizes = {
                sm:    'w-5 h-5',
                md:    'w-7 h-7',
                lg:    'w-9 h-9',
                xl:    'w-11 h-11',
                '2xl': 'w-14 h-14',
            };

            return sizes[this.size];
        },
        messageClass() {
            const sizes = {
                sm:    'text-md',
                md:    'text-md',
                lg:    'text-lg',
                xl:    'text-xl',
                '2xl': 'text-2xl',
            };

            return sizes[this.size];
        },
    },
    watch:    {
        socketMessage(newChannel, oldChannel) {
            if (newChannel === oldChannel) {
                return;
            }

            if (oldChannel) {
                offWebSocket(oldChannel, this.onSocketMessage);
            }

            if (newChannel) {
                onWebSocket(newChannel, this.onSocketMessage, 3000);
            }
        },
        show(newValue, oldValue) {
            if (newValue !== oldValue && !newValue && this.socketMessage) {
                offWebSocket(this.socketMessage, this.onSocketMessage);
                this.socketMessage = undefined;
            }

            const callback = this.show ? this.onShow : this.onHide;

            callback?.();
        },
    },
    methods:  {
        onSocketMessage(socketMessage) {
            const data = JSON.parse(socketMessage);

            if (!this.showMessage) {
                this.showMessage = true;
            }

            const message = get(data, 'message');

            if (message) {
                this.message = message;
            }
        },
        handleAfterLeave() {
            this.$emit('after-leave');
        },
    },
};
</script>
