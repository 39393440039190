<script>
export default {
    name: 'BaseColorPalette',
};
</script>

<script setup>
import { DEFAULT_PALETTE, SIZE } from '@/components/base-components/components/color/index';

defineProps({
    value:   {
        type: String,
    },
    size:    {
        type:       String,
        default:    SIZE.MEDIUM,
        validation: size => Object.values(SIZE).includes(size),
    },
    palette: {
        type:    Array,
        default: () => DEFAULT_PALETTE,
    },
});
</script>

<template>
    <div class="grid grid-cols-7 gap-2">
        <base-color-palette-item
            v-for="color in palette"
            :key="color"
            :value="color"
            :active="color === value"
            :size="size"
            @click="$emit('input', color)"
        />
    </div>
</template>
