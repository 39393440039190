<script>
export default {
    name: 'BaseOverlay',
};
</script>

<script setup>
defineProps({
    show:        {
        type: Boolean,
    },
    customClass: {
        type:    [String, Array, Object],
        default: 'bg-black/50',
    },
    zIndex:      {
        type:    Number,
        default: 20120,
    },
    transition:  {
        type:    [String, Object],
        default: () => ({
            'enter-active-class': 'transition-opacity duration-300 ease-in-out',
            'leave-active-class': 'transition-opacity duration-500 ease-in-out',
            'leave-to-class':     'opacity-0',
            'enter-class':        'opacity-0',
        }),
    },
});

const emit = defineEmits({
    click:         null,
    'after-enter': null,
    enter:         null,
    'after-leave': null,
});
</script>

<template>
    <transition
        v-bind="transition"
        appear
        @after-enter="emit('after-enter')"
        @enter="emit('enter')"
        @after-leave="emit('after-leave')"
    >
        <div
            v-if="show"
            class="fixed inset-0 flex h-screen w-screen items-center justify-center"
            :class="[
                customClass,
            ]"
            :style="{
                'z-index': zIndex,
            }"
            @click="emit('click')"
        />
    </transition>
</template>
