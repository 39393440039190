<template>
    <div
        v-show="visible"
        class="absolute top-0 left-0 z-10 flex h-full w-full items-center justify-center bg-white bg-opacity-25 backdrop-blur-sm backdrop-filter"
        @dragleave.prevent="visible = false"
        @drop.prevent="drop"
    >
        <span class="text-2xl font-semibold text-gray-600 font-avenir">{{ text }}</span>
    </div>
</template>

<script>
export default {
    data() {
        return {
            text:      '',
            visible:   false,
            onDrop:    undefined,
            applyDrop: undefined,
        };
    },
    methods: {
        setText(text) {
            this.text = text;
        },
        drop(event) {
            this.visible = false;

            const apply = this.applyDrop?.(event) ?? true;

            if (!apply) return;

            this.onDrop?.(event);
        },
    },
};
</script>
