<script>
export default {
    name: 'BaseMessage',
};
</script>

<script setup>
import { computed, onMounted, ref } from 'vue';
import animate from '@/assets/util/animate';
import { useStack } from '@/components/base-components/composables/stack';
import { TYPE } from '@/components/base-components/components/message';

const props = defineProps({
    id:        {
        type: String,
    },
    message:   {
        type: String,
    },
    duration:  {
        type:    Number,
        default: 4000,
    },
    icon:      {
        type: Object,
    },
    type:      {
        type:       String,
        default:    TYPE.INFO,
        validation: type => Object.values(TYPE).includes(type),
    },
    offset:    {
        type:    Number,
        default: 0,
    },
    html:      {
        type: Boolean,
    },
    progress:  {
        type: Boolean,
    },
    showClose: {
        type: Boolean,
    },
    onClose:   {
        type:     Function,
        required: true,
    },
    onDestroy: {
        type:     Function,
        required: true,
    },
});

const visible = ref(false);

function close() {
    visible.value = false;
}

const { zIndex } = useStack(visible, 99999999);

const timeoutID = ref(null);
const progressWidth = ref(0);
let animationFunction;

function startTimer() {
    if (props.duration <= 0) return;

    timeoutID.value = setTimeout(() => {
        if (visible.value) close();
    }, props.duration);

    if (!props.progress) return;

    animationFunction = animate((value) => {
        progressWidth.value = value * 100;
    }, null, props.duration, { x1: 0, y1: 0, x2: 1, y2: 1 });
}

function clearTimer() {
    if (!timeoutID.value) return;

    animationFunction?.();
    progressWidth.value = 0;
    clearTimeout(timeoutID.value);
    timeoutID.value = null;
}

onMounted(() => {
    startTimer();
    visible.value = true;
});

const messageClass = computed(() => ({
    [TYPE.ERROR]:   'bg-red-50 text-red-600 border-red-100',
    [TYPE.INFO]:    'bg-gray-50 text-gray-400 border-gray-100',
    [TYPE.SUCCESS]: 'bg-emerald-50 text-emerald-500 border-emerald-100',
    [TYPE.WARNING]: 'bg-amber-50 text-amber-500 border-amber-100',
}[props.type]));

const messageIcon = computed(() => {
    if (props.icon) return props.icon;
    if (!props.type) return;

    return {
        [TYPE.ERROR]:   {
            name:  'triangle-exclamation',
            class: 'text-red-600',
        },
        [TYPE.INFO]:    {
            name:  'info-circle',
            class: 'text-gray-400',
        },
        [TYPE.SUCCESS]: {
            name:  'check-circle',
            class: 'text-emerald-500',
        },
        [TYPE.WARNING]: {
            name:  'exclamation-circle',
            class: 'text-amber-500',
        },
    }[props.type];
});

const showProgress = computed(() => props.progress && !!timeoutID.value);

defineExpose({
    close,
});
</script>

<template>
    <transition
        enter-active-class="transition duration-300 ease-in-out"
        leave-active-class="transition duration-300 ease-in-out"
        leave-to-class="-translate-y-full opacity-0"
        enter-class="-translate-y-full opacity-0"
        @before-leave="onClose"
        @after-leave="onDestroy"
    >
        <div
            v-show="visible"
            class="pointer-events-none fixed inset-x-0 flex items-center justify-center transition-all"
            :style="{
                'z-index': zIndex,
                'top': `${offset}px`
            }"
        >
            <div
                class="pointer-events-auto relative flex max-w-xl items-center justify-between gap-x-3 overflow-hidden rounded-md border px-4 py-3 shadow-md min-w-80"
                :class="messageClass"
                @mouseenter="clearTimer"
                @mouseleave="startTimer"
            >
                <div class="flex items-center gap-x-3">
                    <base-icon
                        v-if="messageIcon"
                        v-bind="messageIcon"
                    />

                    <div class="text-sm">
                        <p v-if="html" v-html="message" />
                        <p v-else>{{ message }}</p>
                    </div>
                </div>

                <div
                    v-if="showClose || props.duration <= 0"
                    class="-mr-1 flex cursor-pointer opacity-50 transition-opacity duration-300 hover:opacity-100"
                    @click="close"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                        stroke="currentColor" class="h-5 w-5"
                    >
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </div>

                <div
                    v-if="showProgress"
                    class="absolute bottom-0 left-0 h-1 bg-brand-sushi"
                    :style="{
                        width: `${progressWidth}%`
                    }"
                />
            </div>
        </div>
    </transition>
</template>
