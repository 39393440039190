import Vue from 'vue';
import { get, orderBy, uniqBy, findIndex } from 'lodash';
import {
    backupEntityList, backupExport,
    backupExportList,
    backupInfo,
    backupInstance,
    backupInstanceList,
    backupPricing,
    backupSchedule,
} from '@/components/backup/api';
import useWebSockets from '@/composables/useWebSockets';
// import { STATUS, TYPE } from '@/components/payments/components/service-plan/constants';

const {
    on:    onWebSocket,
    off:   offWebSocket,
    clear: clearWebSocket,
} = useWebSockets();

const getBaseState = () => ({
    data:      {},
    instances: [],
    entities:  {},
    exports:   [],
    schedule:  {},
    pricing:   {},
});

export default {
    namespaced: true,
    state:      getBaseState(),
    getters:    {
        getId(state) {
            return get(state.data, 'id');
        },
        getUserDiscount(state) {
            return Number.parseFloat(get(state.data, 'createdBy.discount', '0'));
        },
        getUserBalance(state) {
            return get(state.data, 'createdBy.balance');
        },
        getInstances(state) {
            return orderBy(state.instances, 'createdAt', 'desc');
        },
        getEntities(state) {
            return get(state.entities, 'list', []);
        },
        getEntitiesActualAt(state) {
            return get(state.entities, 'actualAt');
        },
        getExports(state) {
            return orderBy(state.exports, 'createdAt', 'desc');
        },
        getSummary(state) {
            return get(state.data, 'summary');
        },
        getPlatform(state) {
            return get(state.data, 'source.platform');
        },
        getPlan(state) {
            return get(state.data, 'plan');
        },
        isAuthorized(state) {
            return get(state.data, 'source.isAuthorized', false);
        },
        isLocked(state) {
            return get(state.data, 'isLocked', true);
        },
        activeTier(state) {
            return state.pricing.tier?.items.find(({ active }) => active);
        },
        pricingPlan(state) {
            return state.pricing.plan;
        },
    },
    mutations:  {
        SET_DATA(state, payload) {
            state.data = { ...state.data, ...payload };
        },
        SET_CRM_IS_AUTHORIZED(state, data) {
            state.data.source.isAuthorized = data.isAuthorized;
        },
        SET_PLAN(state, plan) {
            state.data.plan = plan;
        },
        SET_CREATED_BY(state, createdBy) {
            state.data.createdBy = createdBy;
        },
        SET_SCHEDULE(state, schedule) {
            state.schedule = schedule;
        },
        SET_PRICING(state, data) {
            Vue.set(state, 'pricing', data);
        },
        CHANGE_TIER(state, tierType) {
            state.pricing.tier.items.forEach((item) => {
                Vue.set(item, 'active', item.type === tierType);
            });
        },
        SET_INSTANCES(state, instances) {
            state.instances = instances;
        },
        SET_INSTANCE(state, { index, instance }) {
            Vue.set(state.instances, index, instance);
        },
        ADD_INSTANCE(state, instance) {
            state.instances.push(instance);
        },
        SET_EXPORTS(state, exports) {
            state.exports = exports;
        },
        SET_EXPORT(state, { index, exportInstance }) {
            Vue.set(state.exports, index, exportInstance);
        },
        ADD_EXPORT(state, exportInstance) {
            state.exports.push(exportInstance);
        },
        SET_ENTITIES(state, entities) {
            state.entities = entities;
        },
        RESET_STATE(state) {
            Object.assign(state, getBaseState());
        },
    },
    actions:    {
        async SET_SOCKET_DATA({ dispatch }, data) {
            await dispatch('SET_DATA', data);
            await dispatch('ON_UPDATE_BACKUP', data.id);
            await dispatch('ON_UPDATE_CRM', data.source.id);
            await dispatch('ON_UPDATE_SCHEDULE', data.id);
            await dispatch('ON_UPDATE_PLAN', data.id);
            await dispatch('ON_UPDATE_INSTANCES', data.id);
            await dispatch('ON_UPDATE_ENTITIES', data.id);
            await dispatch('ON_UPDATE_EXPORTS', data.id);
        },
        SET_DATA({ commit, dispatch }, data) {
            commit('SET_DATA', data);

            if (data.instance) {
                dispatch('ADD_SOCKET_INSTANCE', data.instance);
            }

            if (data.export) {
                dispatch('ADD_SOCKET_EXPORT', data.export);
            }

            if (data.createdBy) {
                dispatch('ON_UPDATE_CREATED_BY', data);
            }
        },
        SET_CRM_IS_AUTHORIZED({ commit }, data) {
            commit('SET_CRM_IS_AUTHORIZED', data);
        },
        SET_SCHEDULE({ commit }, data) {
            commit('SET_SCHEDULE', data);
        },
        SET_PRICING({ commit, dispatch }, data) {
            commit('SET_PRICING', data);

            let activeTier = data.tier.items.find(({ increment }) => increment.min <= data.plan.increment && data.plan.increment <= increment.max);

            activeTier ||= data.tier.items.find(({ type }) => type === data.plan.cycle);
            activeTier ||= data.tier.items[0];

            dispatch('CHANGE_TIER', activeTier.type);
        },
        CHANGE_TIER({ commit }, tierType) {
            commit('CHANGE_TIER', tierType);
        },
        ADD_SOCKET_INSTANCE({ dispatch }, data) {
            dispatch('ADD_INSTANCE', data);
            dispatch('ON_UPDATE_INSTANCE', data.id);
        },
        ADD_SOCKET_EXPORT({ dispatch }, data) {
            dispatch('ADD_EXPORT', data);
            dispatch('ON_UPDATE_EXPORT', data.id);
        },
        ADD_INSTANCE({ commit, state }, instance) {
            const index = findIndex(state.instances, { id: instance.id });

            if (index === -1) {
                commit('ADD_INSTANCE', instance);
            } else {
                commit('SET_INSTANCE', {
                    index,
                    instance,
                });
            }
        },
        ADD_EXPORT({ commit, state }, exportInstance) {
            const index = findIndex(state.exports, { id: exportInstance.id });

            if (index === -1) {
                commit('ADD_EXPORT', exportInstance);
            } else {
                commit('SET_EXPORT', {
                    index,
                    exportInstance,
                });
            }
        },
        SET_INSTANCES({ dispatch, commit, state }, instances) {
            instances.forEach((instance) => {
                if (instance.isRunning === true) {
                    dispatch('ON_UPDATE_INSTANCE', instance.id);
                }
            });

            commit('SET_INSTANCES', uniqBy([
                ...state.instances,
                ...instances,
            ], 'id'));
        },
        SET_ENTITIES({ commit }, entities) {
            commit('SET_ENTITIES', entities);
        },
        SET_EXPORTS({ dispatch, commit, state }, exports) {
            exports.forEach((exportInstance) => {
                if (exportInstance.isRunning === true) {
                    dispatch('ON_UPDATE_EXPORT', exportInstance.id);
                }
            });

            commit('SET_EXPORTS', uniqBy([
                ...state.exports,
                ...exports,
            ], 'id'));
        },
        ON_UPDATE_BACKUP({ dispatch }, backupId) {
            onWebSocket(`backup-${backupId}-update`, (message) => {
                const partialBackupData = JSON.parse(message);

                dispatch('SET_DATA', partialBackupData);
                dispatch('UPDATE_DATA', backupId);
            }, 5000);
        },
        ON_UPDATE_CRM({ dispatch }, crmId) {
            onWebSocket(`crm-${crmId}-state`, (message) => {
                dispatch('SET_CRM_IS_AUTHORIZED', JSON.parse(message));
            });
        },
        ON_UPDATE_SCHEDULE({ dispatch }, backupId) {
            onWebSocket(`backup-${backupId}-schedule-update`, () => {
                dispatch('UPDATE_SCHEDULE', backupId);
            });
        },
        ON_UPDATE_PLAN({ dispatch }, backupId) {
            onWebSocket(`backup-${backupId}-service-plan-update`, () => {
                dispatch('UPDATE_DATA', backupId);
                dispatch('UPDATE_PRICING', backupId);
            });
        },
        ON_UPDATE_INSTANCES({ dispatch }, backupId) {
            onWebSocket(`backup-${backupId}-instance-list-update`, () => {
                dispatch('UPDATE_INSTANCES', backupId);
            });
        },
        ON_UPDATE_ENTITIES({ dispatch }, backupId) {
            onWebSocket(`backup-${backupId}-entity-list-update`, () => {
                dispatch('UPDATE_ENTITIES', backupId);
            });
        },
        ON_UPDATE_EXPORTS({ dispatch }, backupId) {
            onWebSocket(`backup-${backupId}-export-list-update`, () => {
                dispatch('UPDATE_EXPORTS', backupId);
            });
        },
        ON_UPDATE_INSTANCE({ dispatch }, instanceId) {
            onWebSocket(`backup-instance-${instanceId}-update`, () => {
                dispatch('UPDATE_INSTANCE', instanceId);
            }, 5000);
        },
        ON_UPDATE_EXPORT({ dispatch }, exportId) {
            onWebSocket(`backup-export-${exportId}-update`, () => {
                dispatch('UPDATE_EXPORT', exportId);
            }, 5000);
        },
        ON_UPDATE_CREATED_BY({ commit }, data) {
            onWebSocket(`user-data-${data.createdBy.id}`, (message) => {
                const createdBy = JSON.parse(message);

                commit('SET_CREATED_BY', createdBy);
            });
        },
        async UPDATE_DATA({ dispatch }, backupId) {
            await dispatch('SET_DATA', await backupInfo(backupId));
        },
        async UPDATE_SCHEDULE({ dispatch }, backupId) {
            await dispatch('SET_SCHEDULE', await backupSchedule(backupId));
        },
        async UPDATE_PRICING({ dispatch }, backupId) {
            await dispatch('SET_PRICING', await backupPricing(backupId));
        },
        async UPDATE_INSTANCES({ dispatch }, backupId) {
            await dispatch('SET_INSTANCES', await backupInstanceList(backupId));
        },
        async UPDATE_ENTITIES({ dispatch }, backupId) {
            await dispatch('SET_ENTITIES', await backupEntityList(backupId));
        },
        async UPDATE_EXPORTS({ dispatch }, backupId) {
            await dispatch('SET_EXPORTS', await backupExportList(backupId));
        },
        async UPDATE_INSTANCE({ dispatch, getters }, instanceId) {
            const instance = await backupInstance(getters.getId, instanceId);

            if (instance.isRunning === false) {
                offWebSocket(`backup-instance-${instanceId}-update`, () => {
                    dispatch('UPDATE_INSTANCE', instanceId);
                });
            }

            await dispatch('ADD_INSTANCE', instance);
        },
        async UPDATE_EXPORT({ dispatch, getters }, exportId) {
            const exportInstance = await backupExport(getters.getId, exportId);

            if (exportInstance.isRunning === false) {
                offWebSocket(`backup-export-${exportInstance}-update`, () => {
                    dispatch('UPDATE_EXPORT', exportId);
                });
            }

            await dispatch('ADD_EXPORT', exportInstance);
        },
        RESET_STATE({ commit }) {
            clearWebSocket();

            commit('RESET_STATE');
        },
    },
};
