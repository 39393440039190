<script>
export default {
    name: 'ServicePlanFeatureLifespan',
};
</script>

<script setup>
import { computed, toRef } from 'vue';
import i18n from '@/plugins/i18n';
import { FIELD, VALUE_UNIT } from '@/components/payments/components/service-plan/components/features/constants';
import useFeature from '@/components/payments/components/service-plan/components/features/composables/use-feature';
import ServicePlanFeatureElementsValue from '@/components/payments/components/service-plan/components/features/components/elements/value';

const props = defineProps({
    feature: {
        type:     Object,
        required: true,
    },
});

const { value, unit } = useFeature(toRef(props, 'feature'));

const formatted = computed(() => ({
    [VALUE_UNIT.DAY]:   i18n.tc('payment.servicePlan.feature.lifespan.day', value.value, { value: value.value }),
    [VALUE_UNIT.WEEK]:  i18n.tc('payment.servicePlan.feature.lifespan.week', value.value, { value: value.value }),
    [VALUE_UNIT.MONTH]: i18n.tc('payment.servicePlan.feature.lifespan.month', value.value, { value: value.value }),
}[unit.value]));
</script>

<template>
    <service-plan-feature-elements-value
        :name="feature[FIELD.NAME]"
        :value="formatted"
    />
</template>
