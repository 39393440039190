import localStorage from '@/storage';

export default {
    namespaced: true,
    state:      {
        collapsed: false,
    },
    mutations:  {
        SET_COLLAPSED(state, value) {
            state.collapsed = value;
        },
    },
    actions:    {
        TOGGLE({ dispatch, state }) {
            dispatch('SET_STATE', !state.collapsed);
        },
        INIT({ dispatch }) {
            dispatch('SET_STATE', localStorage.get('app-sidebar-collapsed', false));
        },
        SET_STATE({ commit }, value) {
            commit('SET_COLLAPSED', value);
            localStorage.set('app-sidebar-collapsed', value);
        },
    },
};
