<script>
export default {
    name: 'BasePaginationSizer',
};
</script>

<script setup>
import { ref, computed } from 'vue';
import { uniq } from 'lodash';
import i18n from '@/plugins/i18n';
import { SIZE, TYPE } from '@/components/base-components/components/pagination';

const props = defineProps({
    value:    {
        type: Number,
    },
    count:    {
        type:     Number,
        required: true,
    },
    list:     {
        type:    Array,
        default: () => [10, 15, 25, 50],
    },
    type:     {
        type:      String,
        default:   TYPE.DEFAULT,
        validator: value => Object.values(TYPE).includes(value),
    },
    size:     {
        type:      String,
        default:   SIZE.SMALL,
        validator: value => Object.values(SIZE).includes(value),
    },
    disabled: {
        type: Boolean,
    },
});

const emit = defineEmits({
    input: null,
});

const initialPage = ref(props.value);

const options = computed(() => uniq([...props.list, initialPage.value])
    .filter(item => item && item <= props.count)
    .sort((first, second) => first - second)
    .map(item => ({
        name:  item,
        label: i18n.t('pagination per page', { count: item }),
    })));

const model = computed({
    get: () => props.value,
    set: value => emit('input', value),
});
</script>

<template>
    <base-select
        v-if="options.length > 1"
        v-model="model"
        :options="options"
        :type="type"
        :size="size"
        :disabled="disabled"
        no-sorting
        use-first
    />
</template>
