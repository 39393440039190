<template>
    <hiw-page
        crm="SugarCRM"
        crm-type="SugarCRM"
        medium="sugarcrm-plugin"
        campaign="SugarCrmMarketplace"
        source-crm="CapsuleCRM"
        :steps="steps"
    />
</template>

<script>
import HiwPage from '@/components/hiw-page/hiw-page';

export default {
    name:       'SugarPage',
    components: { HiwPage },
    computed:   {
        steps() {
            return [
                {
                    text: this.$t('plugin create your account or login'),
                    url:  'img/landings/sugar/register-data2crm.png',
                    alt:  this.$t('plugin sign up image alt'),
                },
                {
                    text: this.$t('plugin select your current crm'),
                    url:  'img/landings/sugar/capsule-source-sugarcrm-plugin.png',
                    alt:  this.$t('plugin authorize image alt'),
                },
                {
                    text: this.$t('plugin choose your future crm'),
                    url:  'img/landings/sugar/sugarcrm-target.png',
                    alt:  this.$t('plugin choose future crm image alt'),
                },
                {
                    text: this.$t('plugin select entities and check mapping'),
                    url:  'img/landings/sugar/capsule-sugarcrm-demo-sugarcrm.png',
                    alt:  this.$t('plugin check automated mapping image alt'),
                },
                {
                    text: this.$t('plugin start free demo migration'),
                    url:  'img/landings/sugar/demo-process-sugarcrm-plugin.png',
                    alt:  this.$t('plugin start free demo image alt'),
                },
                {
                    text: this.$t('plugin check demo results and start full migration'),
                    url:  'img/landings/sugar/start-full-sugarcrm-plugin.png',
                    alt:  this.$t('plugin launch full migration image alt'),
                },
            ];
        },
    },
};
</script>
