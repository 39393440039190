<template>
    <base-input
        v-model="currentValue"
        native-type="textarea"
        :size="size"
        :rows="rows"
        :placeholder="placeholder"
        :disabled="disabled"
    />
</template>

<script>
import { debounce } from 'lodash';

export default {
    name:  'BaseInputArrayAsString',
    props: {
        value:       {
            type: Array,
        },
        size:        {
            type: String,
        },
        placeholder: {
            type: String,
        },
        disabled:    {
            type: Boolean,
        },
        rows:        {
            type:    Number,
            default: 10,
        },
    },
    data() {
        return {
            currentValue: (this.value ?? []).join('\n'),

            handleInput: debounce(this.input, 400),
        };
    },
    watch:   {
        currentValue() {
            this.handleInput();
        },
    },
    methods: {
        input() {
            this.$emit('input', this.currentValue.split(/[\n,]/).filter(Boolean));
        },
    },
};
</script>
