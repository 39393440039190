<script>
export default {
    name: 'BaseCheckbox',
};
</script>

<script setup>
import { computed } from 'vue';
import { isArray, isObject } from '@/components/base-components/utils/types';
import { TYPE } from '@/components/base-components/components/checkbox';

const props = defineProps({
    value:         {
        type: Boolean,
    },
    label:         {
        type: [String, Array, Object],
    },
    type:          {
        type:      String,
        default:   TYPE.PRIMARY,
        validator: value => Object.values(TYPE).includes(value),
    },
    indeterminate: {
        type: Boolean,
    },
    disabled:      {
        type: Boolean,
    },
});

const emit = defineEmits({
    input:  null,
    change: null,
});

function click() {
    if (props.disabled) return;

    emit('input', !props.value);
    emit('change', !props.value);
}

const activeValue = computed(() => props.value || props.indeterminate);

const actualLabel = computed(() => {
    if (isObject(props.label)) return props.label[props.value ?? false];
    if (isArray(props.label)) return props.label[props.value ? 1 : 0];

    return props.label;
});

const typeClass = computed(() => {
    if (!activeValue.value) return 'bg-white';

    return {
        [TYPE.PRIMARY]:       'bg-primary-600 border-primary-600',
        [TYPE.SECONDARY]:     'bg-secondary-950 border-secondary-950',
        [TYPE.PRIMARY_OLD]:   'bg-science-blue-500 border-science-blue-500',
        [TYPE.SECONDARY_OLD]: 'bg-science-blue-700 border-science-blue-700',
        [TYPE.SUCCESS]:       'bg-emerald-600 border-emerald-600',
        [TYPE.WARNING]:       'bg-orange-500 border-orange-500',
        [TYPE.DANGER]:        'bg-red-600 border-red-600',
        [TYPE.INFO]:          'bg-gray-500 border-gray-500',
    }[props.type];
});

const textTypeClass = computed(() => {
    if (!activeValue.value) return 'text-gray-500';

    return {
        [TYPE.PRIMARY]:       'text-primary-600',
        [TYPE.SECONDARY]:     'text-secondary-950',
        [TYPE.PRIMARY_OLD]:   'text-science-blue-700',
        [TYPE.SECONDARY_OLD]: 'text-science-blue-900',
        [TYPE.SUCCESS]:       'text-emerald-600',
        [TYPE.WARNING]:       'text-orange-500',
        [TYPE.DANGER]:        'text-red-600',
        [TYPE.INFO]:          'text-gray-500',
    }[props.type];
});
</script>

<template>
    <div
        class="relative inline-flex select-none items-center gap-x-2 text-sm font-medium leading-none group"
        :class="[
            disabled ? 'cursor-not-allowed' : 'cursor-pointer',
            {
                'opacity-50': disabled,
            },
            textTypeClass,
        ]"
        @click="click"
    >
        <div
            class="inline-flex h-5 w-5 shrink-0 select-none appearance-none items-center justify-center rounded border p-0 align-middle text-white transition"
            :class="[
                typeClass,
                {
                    'group-hover:border-gray-400': !activeValue && !disabled
                }
            ]"
        >
            <svg
                v-if="indeterminate"
                class="h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
            >
                <path stroke-linecap="round" stroke-linejoin="round" d="M18 12H6" />
            </svg>
            <svg
                v-else
                class="h-4 transition-all"
                :class="[
                    value ? 'w-4' : 'w-0'
                ]"
                xmlns="http://www.w3.org/2000/svg"
                fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2"
            >
                <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
            </svg>
        </div>

        <template v-if="$slots.default">
            <slot>{{ label }}</slot>
        </template>
        <span
            v-else-if="label"
            v-text="actualLabel"
            class="whitespace-nowrap"
        />
    </div>
</template>
