<script>
export default {
    name: 'ServicePlanFeatureAvailable',
};
</script>

<script setup>
import { computed, toRef } from 'vue';
import i18n from '@/plugins/i18n';
import { FIELD } from '@/components/payments/components/service-plan/components/features/constants';
import useFeature from '@/components/payments/components/service-plan/components/features/composables/use-feature';
import ServicePlanFeatureElementsValue from '@/components/payments/components/service-plan/components/features/components/elements/value';

const props = defineProps({
    feature: {
        type:     Object,
        required: true,
    },
});

const { value } = useFeature(toRef(props, 'feature'));

const formatted = computed(() => {
    if (value.value === 'owner') return i18n.t('payment.servicePlan.feature.available.owner');

    return i18n.t('payment.servicePlan.feature.available.all');
});
</script>

<template>
    <service-plan-feature-elements-value
        :name="feature[FIELD.NAME]"
        :value="formatted"
    />
</template>
