<template>
    <div class="grid w-full font-bold font-avenir" :class="containerClass">
        <div
            v-for="(step, index) in steps"
            :key="index"
            class="flex items-center justify-center"
            :class="{ 'flex-col': isVertical }"
        >
            <div :class="leftDividerClass(index)" />
            <div
                class="px-2 py-1 text-xs leading-none tracking-wide text-center border-2 rounded-full"
                :class="stepClass(step)"
                @click="clickStep(step)"
            >
                {{ step.title }}
            </div>
            <div :class="rightDividerClass(index)" />
        </div>
    </div>
</template>

<script>

export default {
    name:     'BaseSteps',
    props:    {
        steps:     {
            type:     Array,
            required: true,
        },
        direction: {
            type:    String,
            default: 'horizontal',
            validator(direction) {
                return ['vertical', 'horizontal'].indexOf(direction) !== -1;
            },
        },
        clickable: Boolean,
    },
    computed: {
        isVertical() {
            return this.direction === 'vertical';
        },
        containerClass() {
            if (this.isVertical) {
                return 'grid-cols-1';
            }

            const cols = {
                2: 'grid-cols-2',
                3: 'grid-cols-3',
                4: 'grid-cols-4',
                5: 'grid-cols-5',
                6: 'grid-cols-6',
            }[this.steps.length];

            return [cols];
        },
    },
    methods:  {
        stepClass(step) {
            return {
                'hover:opacity-75 cursor-pointer':                          this.isDone(step) && this.clickable,
                'border-secondary-950 bg-secondary-950 text-white': this.isDone(step),
                'border-secondary-950 bg-white text-secondary-950': this.isCurrent(step),
                'text-gray-400':                                            this.isWait(step),
            };
        },
        showLeftDivider(index) {
            return index !== 0;
        },
        showRightDivider(index) {
            return index !== this.steps.length - 1;
        },
        leftDividerClass(index) {
            return this.showLeftDivider(index) ? this.dividerClass(index, true) : 'flex-1';
        },
        rightDividerClass(index) {
            return this.showRightDivider(index) ? this.dividerClass(index) : 'flex-1';
        },
        dividerClass(index, withPrevious = false) {
            let active = this.isDone(this.steps[index]);

            if (active === false && withPrevious === true) {
                active = this.isDone(this.steps[index - 1]);
            }

            return [
                'flex-1 h-2 border md:h-auto',
                active ? 'border-secondary-950' : 'text-gray-400',
            ];
        },
        isDone(step) {
            return step.status === 'done';
        },
        isCurrent(step) {
            return step.status === 'current';
        },
        isWait(step) {
            return step.status === 'wait';
        },
        isStepDivider(step) {
            return step.divider === true;
        },
        clickStep(step) {
            if (this.clickable && this.isDone(step)) {
                this.$emit('stepClick', step);
            }
        },
    },
};
</script>
