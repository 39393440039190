import Vue from 'vue';
import Vuex from 'vuex';
import * as eventConstants from '@/assets/util/event-constants';
import { UP } from '@/assets/util/maintenance';
import aclAccess from '@/store/modules/acl-access';
import backup from '@/store/modules/backup';
import breadcrumbs from '@/store/modules/breadcrumbs';
import crms from '@/store/modules/crms';
import customFields from '@/store/modules/custom-fields';
import events from '@/store/modules/events';
import loader from '@/store/modules/loader';
import manager from '@/store/modules/manager';
import platform from '@/store/modules/platform';
import sidebar from '@/store/modules/sidebar';
import user from '@/store/modules/user';
import wizard from '@/store/modules/wizard';

Vue.use(Vuex);

const store = new Vuex.Store({
    state:     {
        version:                {
            actual: true,
        },
        maintenance:            {
            mode: UP,
        },
        eventConstants,
        showErrorNotifications: true,
        cypressData:            undefined,
    },
    modules:   {
        aclAccess,
        backup,
        breadcrumbs,
        crms,
        customFields,
        events,
        loader,
        manager,
        platform,
        sidebar,
        user,
        wizard,
    },
    mutations: {
        disableErrorNotifications(state) {
            state.showErrorNotifications = false;
        },
        enableErrorNotifications(state) {
            state.showErrorNotifications = true;
        },
        setCypressData(state, data) {
            state.cypressData = data;
        },
        SET_HEADER_TITLE(state, title) {
            state.headerTitle = title;
        },
        SET_VERSION_ACTUAL(state, actual) {
            state.version.actual = actual;
        },
        SET_MAINTENANCE(state, mode) {
            state.maintenance.mode = mode;
        },
    },
    actions:   {
        SET_VERSION_ACTUAL({ commit }, actual) {
            commit('SET_VERSION_ACTUAL', actual);
        },
        SET_MAINTENANCE({ commit }, mode) {
            commit('SET_MAINTENANCE', mode);
        },
        setCypressData(context, data) {
            context.commit('setCypressData', data);
        },
    },
});

export default store;
export const useStore = () => store;
