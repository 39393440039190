import { watch } from 'vue';
import { toValue } from '@vueuse/core';

export const DELAY_TIMEOUT = 600;
export const INTERVAL_TIMEOUT = 100;

export function useRepeat(handler, disabled, { delay = DELAY_TIMEOUT, interval = INTERVAL_TIMEOUT } = {}) {
    let delayId;
    let intervalId;

    function end() {
        if (delayId) {
            clearTimeout(delayId);
            delayId = undefined;
        }

        if (intervalId) {
            clearInterval(intervalId);
            intervalId = undefined;
        }
    }

    function start() {
        end();

        if (toValue(disabled) || !handler) return;

        handler();

        delayId = setTimeout(() => {
            intervalId = setInterval(() => {
                handler();
            }, interval);
        }, delay);
    }

    watch(() => toValue(disabled), () => {
        end();
    });

    return {
        start,
        end,
    };
}
