<template>
    <div class="relative flex items-center gap-x-2 overflow-hidden">
        <div v-if="showArrows || !!$slots.prefix" class="flex items-center mb-1.5 gap-x-2">
            <slot name="prefix" />
            <base-tabs-select v-if="quickNavigationLeft && showArrows" :tabs="tabs" />
            <base-button
                v-if="showArrows"
                class="h-7 px-2"
                link
                :icon="{ name: 'chevron-left' }"
                :disabled="disabledLeft"
                @click="scrollToLeft"
            />
        </div>
        <div
            class="relative flex flex-1 items-center gap-x-3 overflow-hidden"
            key="baseTabsNavigationWrapper"
            ref="baseTabsNavigationWrapper"
        >
            <div
                v-for="tab in tabs"
                :key="tab.name"
                :id="tab.name"
                ref="baseTabsNavigationItem"
                class="px-3 py-0.5 text-base font-semibold mb-1.5 text-center whitespace-nowrap rounded-md transition-all duration-75 ease-in-out select-none font-avenir"
                :class="[
                    tab.active ? 'text-science-blue-900' : 'text-gray-600',
                    {
                        'flex-1': stretch,
                        'cursor-not-allowed opacity-50': tab.disabled || disabled,
                        'cursor-pointer hover:text-science-blue-900 hover:bg-gray-100': !tab.active && !tab.disabled && !disabled
                    }
                ]"
                @click="click(tab)"
            >
                <slot :tab="tab">
                    {{ tab.label || tab.index }}
                </slot>
            </div>
            <div
                class="absolute bottom-0 h-0.5 bg-science-blue-900 z-10"
                :style="activeIndicatorStyle"
            />
        </div>
        <div v-if="showArrows || !!$slots.suffix" class="flex items-center mb-1.5 gap-x-2">
            <base-button
                v-if="showArrows"
                class="h-7 px-2"
                link
                :icon="{ name: 'chevron-right' }"
                :disabled="disabledRight"
                @click="scrollToRight"
            />
            <base-tabs-select v-if="quickNavigationRight && showArrows" :tabs="tabs" />
            <slot name="suffix" />
        </div>
        <div class="absolute bottom-0 w-full h-0.5 bg-gray-200" />
    </div>
</template>

<script>
import { find } from 'lodash';
import BaseTabsSelect from '@/components/base-components/components/tabs/base-tabs-select';
import resizeElementListenerMixin from '@/assets/mixins/resize-element-listener';
import animate from '@/assets/util/animate';

export default {
    name:       'BaseTabsNavigation',
    components: {
        BaseTabsSelect,
    },
    mixins:     [
        resizeElementListenerMixin,
    ],
    props:      {
        tabs:     {
            type:     Array,
            required: true,
        },
        stretch:  Boolean,
        disabled: Boolean,

        quickNavigationLeft:  Boolean,
        quickNavigationRight: Boolean,
    },
    inject:     ['baseTabs'],
    data() {
        return {
            showArrows:    false,
            disabledLeft:  false,
            disabledRight: false,

            activeOffset: 0,
            activeWidth:  0,

            resizeElementRef: 'baseTabsNavigationWrapper',
        };
    },
    computed: {
        active() {
            return find(this.tabs, item => item.active);
        },
        activeIndicatorStyle() {
            return {
                width:     `${this.activeWidth}px`,
                transform: `translateX(${this.activeOffset}px)`,
            };
        },
    },
    watch:    {
        active() {
            this.calculateActiveStyle();
        },
    },
    methods:  {
        calculateActiveStyle(animation = true) {
            if (!this.active) return;

            this.findActiveElement(($el) => {
                this.calculateElementStyle($el, animation);
            });
        },
        findActiveElement(callback) {
            const interval = setInterval(() => {
                const $el = find(this.$refs.baseTabsNavigationItem, refItem => refItem.id.toString() === this.active.name.toString());

                if ($el === undefined) return;

                clearInterval(interval);

                callback($el);
            }, 20);
        },
        async calculateElementStyle($el, animation = true) {
            const { clientWidth, offsetLeft } = $el;

            const wrapper = this.$refs.baseTabsNavigationWrapper;

            const offsetWidth = clientWidth + offsetLeft;
            const scrollViewport = wrapper.offsetWidth + wrapper.scrollLeft;

            let scrollTo;

            if (offsetWidth > scrollViewport) {
                scrollTo = wrapper.scrollLeft + offsetWidth - scrollViewport;
            } else if (offsetLeft < wrapper.scrollLeft) {
                scrollTo = offsetLeft;
            }

            await this.$nextTick();

            if (animation) return this.animateTo(scrollTo, clientWidth, offsetLeft);

            this.moveTo(scrollTo, clientWidth, offsetLeft);
        },
        click(tab) {
            if (tab.disabled || this.disabled || this.active?.name === tab.name) return;

            this.baseTabs.changeTab(tab);
        },
        checkDisabled() {
            const { scrollWidth, offsetWidth, scrollLeft } = this.$refs.baseTabsNavigationWrapper;

            this.disabledLeft = scrollLeft === 0;
            this.disabledRight = (scrollWidth - scrollLeft) <= offsetWidth;
        },
        resizeElementDebounceMethod() {
            const { offsetWidth, scrollWidth } = this.$refs.baseTabsNavigationWrapper;

            this.showArrows = scrollWidth > offsetWidth;

            if (this.showArrows) {
                this.checkDisabled();
            }
        },
        animate(start, end, callback, finish) {
            const change = end - start;

            animate(
                (progress) => {
                    callback(parseInt(progress * change + start, 10));
                },
                () => {
                    callback(end);
                    finish?.();
                },
                250,
            );
        },
        animateScroll(end) {
            this.animate(this.$refs.baseTabsNavigationWrapper.scrollLeft, end, (value) => {
                this.$refs.baseTabsNavigationWrapper.scrollLeft = value;
            }, () => {
                this.checkDisabled();
            });
        },
        animateTo(scrollTo, clientWidth, offsetLeft) {
            if (scrollTo !== undefined) {
                this.animateScroll(scrollTo);
            }

            this.animate(this.activeWidth, clientWidth, (value) => {
                this.activeWidth = value;
            });
            this.animate(this.activeOffset, offsetLeft, (value) => {
                this.activeOffset = value;
            });
        },
        moveTo(scrollTo, clientWidth, offsetLeft) {
            if (scrollTo !== undefined) {
                this.$refs.baseTabsNavigationWrapper.scrollLeft = scrollTo;
            }

            this.activeWidth = clientWidth;
            this.activeOffset = offsetLeft;
        },
        scrollToLeft() {
            if (this.disabledLeft) return;

            const { offsetWidth, scrollLeft } = this.$refs.baseTabsNavigationWrapper;
            const end = Math.max(scrollLeft - offsetWidth, 0);

            this.animateScroll(end);
        },
        scrollToRight() {
            if (this.disabledRight) return;

            const { scrollWidth, offsetWidth, scrollLeft } = this.$refs.baseTabsNavigationWrapper;
            const distance = scrollWidth - Math.max(scrollLeft, offsetWidth);
            const end = scrollLeft + Math.min(distance, offsetWidth);

            this.animateScroll(end);
        },
    },
    async mounted() {
        await this.$nextTick();

        this.calculateActiveStyle(false);
    },
};
</script>
