<script>
export default {
    name: 'BaseDivider',
};
</script>

<script setup>
defineProps({
    text: {
        type: String,
    },
});
</script>

<template>
    <div class="relative w-full h-4">
        <div class="flex absolute inset-0 items-center">
            <div class="w-full border-t border-gray-200" />
        </div>
        <div v-if="text" class="flex relative justify-center text-sm leading-none">
            <span class="px-2 text-gray-500 bg-white">{{ text }}</span>
        </div>
    </div>
</template>
