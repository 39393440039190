<script>
export default {
    name: 'BaseDropdownItem',
};
</script>

<script setup>
import { getCurrentInstance, inject } from 'vue';

const props = defineProps({
    command:  {
        type: String,
    },
    disabled: {
        type: Boolean,
    },
    divided:  {
        type: Boolean,
    },
});

const emit = defineEmits({
    click: null,
});

const { itemCommand } = inject('base-dropdown');

const instance = getCurrentInstance();

const onClick = () => {
    if (props.disabled) return;

    const params = [props.command, instance];

    itemCommand(...params);
    emit('click', ...params);
};
</script>

<template>
    <li
        :class="{
            'cursor-not-allowed text-gray-400/50': disabled,
            'cursor-pointer hover:bg-gray-100': !disabled,
            'mt-1 border-t': divided,
        }"
        @click="onClick"
    >
        <slot name="content">
            <div class="select-none px-5 py-2">
                <slot />
            </div>
        </slot>
    </li>
</template>
