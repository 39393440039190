export const SIZE = Object.freeze({
    LARGE:  'large',
    MEDIUM: 'medium',
    SMALL:  'small',
});

export const TYPE = Object.freeze({
    DEFAULT:       'default',
    PRIMARY:       'primary',
    SECONDARY:     'secondary',
    PRIMARY_OLD:   'primary-old',
    SECONDARY_OLD: 'secondary-old',
    SUCCESS:       'success',
    WARNING:       'warning',
    DANGER:        'danger',
    INFO:          'info',
});

export const CONTROLS_POSITION = Object.freeze({
    DEFAULT: 'default',
    RIGHT:   'right',
    OUTSIDE: 'outside',
});
