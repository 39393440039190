<template>
    <div class="relative" :style="{ marginRight: `${marginRight}px` }">
        <slot />

        <div
            v-show="show"
            class="absolute top-0 right-0 z-10 translate-x-1/2 -translate-y-1/2"
            key="baseBadgeContainer"
            ref="baseBadgeContainer"
            :class="wrapperClass"
        >
            <slot name="content">
                <div
                    v-text="content"
                    class="inline-flex items-center justify-center whitespace-nowrap rounded-full text-xs text-white"
                    :class="[
                        {
                            [TYPE.PRIMARY]: 'bg-primary-600',
                            [TYPE.SECONDARY]: 'bg-secondary-950',
                            [TYPE.PRIMARY_OLD]: 'bg-science-blue-500',
                            [TYPE.SECONDARY_OLD]: 'bg-science-blue-800',
                            [TYPE.SUCCESS]: 'bg-emerald-600',
                            [TYPE.WARNING]: 'bg-amber-500',
                            [TYPE.DANGER]: 'bg-red-500',
                            [TYPE.INFO]: 'bg-gray-500',
                        }[type],
                        {
                            'w-2.5 h-2.5': isDot,
                            'px-1.5 h-4': !isDot
                        }
                    ]"
                />
            </slot>
        </div>
    </div>
</template>

<script>
import { isNumber } from 'lodash';
import { TYPE } from '@/components/base-components/components/badge';
import resizeElementListenerMixin from '@/assets/mixins/resize-element-listener';

export default {
    name:   'BaseBadge',
    mixins: [
        resizeElementListenerMixin,
    ],
    props:  {
        value:         {
            type: [String, Number],
        },
        max:           {
            type: Number,
        },
        isDot:         {
            type: Boolean,
        },
        hidden:        {
            type: Boolean,
        },
        type:          {
            type:      String,
            default:   TYPE.PRIMARY,
            validator: value => Object.values(TYPE).includes(value),
        },
        dynamicOffset: {
            type: Boolean,
        },
        wrapperClass:  {
            type: String,
        },
    },
    data() {
        return {
            marginRight: 0,

            resizeElementRef:          this.dynamicOffset ? 'baseBadgeContainer' : null,
            resizeElementDebounceTime: 0,
        };
    },
    computed: {
        TYPE() {
            return TYPE;
        },
        content() {
            if (this.isDot) return;

            if (isNumber(this.value) && isNumber(this.max)) {
                return this.max < this.value ? `${this.max}+` : this.value;
            }

            return this.value;
        },
        show() {
            if (this.hidden) return false;

            return this.content || this.content === 0 || this.isDot;
        },
    },
    methods:  {
        resizeElementDebounceMethod(entry) {
            const { offsetWidth } = entry.target;

            this.marginRight = Math.round(offsetWidth / 2);
        },
    },
};
</script>
