export default function convertToValidDropDownList(list, selected) {
    const convertedList = [];
    list.forEach((item) => {
        convertedList.push(
            {
                label:    item,
                name:     item,
                selected: item === selected,
            },
        );
    });

    return convertedList;
}
