import featureBackupEntityMixin from '@/components/backup/mixins/features/feature-backup-entity';

export default {
    mixins:  [
        featureBackupEntityMixin,
    ],
    methods: {
        getSelectedEntities(entities) {
            return entities.filter(entity => entity.isSelected && this.backupEntityAllow(entity.crmEntity.name));
        },
        getAvailableEntities(entities) {
            return entities.filter(entity => entity.isAvailable && this.backupEntityAllow(entity.crmEntity.name));
        },
        getTotalRecords(entities, limit) {
            return entities
                .map(entity => entity.count)
                .reduce((accumulator, currentValue) => {
                    let value = currentValue;

                    if (limit) {
                        value = value > limit ? limit : value;
                    }

                    return accumulator + value;
                }, 0);
        },
        getSelectedTotalRecords(entities, limit) {
            return this.getTotalRecords(this.getSelectedEntities(entities), limit);
        },
    },
};
