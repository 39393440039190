<template>
    <router-link :to="{ name: item.name }">
        <div
            class="shrink-0 px-3 py-2 text-sm font-semibold leading-5 rounded-md transition duration-150 ease-in-out cursor-pointer font-avenir hover:text-white hover:bg-gray-700 focus:text-white focus:bg-gray-700 focus:outline-none"
            :class="isActive ? 'text-white bg-gray-900' : 'text-gray-300'"
        >
            {{ item.label }}
        </div>
    </router-link>
</template>

<script>
export default {
    name:     'ManagerHeaderNavigationItem',
    props:    {
        item: {
            type:     Object,
            required: true,
        },
    },
    computed: {
        isActive() {
            return this.item.name === this.$route.name;
        },
    },
};
</script>
