<template>
    <base-button
        id="profile-button"
        size="small"
        text
        stretch
        :data-test="dataTest()"
        :text-transform="false"
        @click="go"
    >
        <template #prefix>
            <base-icon v-bind="icon" />
        </template>

        <div class="ml-1 flex flex-col items-start text-sm">
            <div v-if="name" class="text-gray-400">{{ name }}</div>
            <div v-if="userEmail">{{ userEmail }}</div>
        </div>
    </base-button>
</template>

<script>
import { mapGetters } from 'vuex';
import { USER } from '@/store/modules/acl-access/constants';

export default {
    name:     'NavigationSettings',
    computed: {
        ...mapGetters({
            isGranted:         'aclAccess/isGranted',
            userEmail:         'user/getEmail',
            userName:          'user/getName',
            userRole:          'user/getRole',
            userGroup:         'user/getGroup',
            getPartnerRequest: 'user/getPartnerRequest',
        }),
        showGuestName() {
            return this.isGranted(USER.SHOW_GUEST_NAME);
        },
        name() {
            return this.showGuestName ? this.$t('header user guest') : (this.userName ?? null);
        },
        icon() {
            return {
                visitor:  {
                    name: 'user-secret',
                    size: '2x',
                },
                client:   {
                    name: 'user',
                    size: '2x',
                },
                partner:  {
                    name: 'user-tie',
                    size: '2x',
                },
                employee: {
                    name: 'user-astronaut',
                    size: '2x',
                },
            }[this.userGroup ?? 'visitor'];
        },
    },
    methods:  {
        go() {
            this.$emit('navigate');

            this.$router.push({ name: 'userSettings' });
        },
    },
};
</script>
