import millify from 'millify';

/**
 * Format bytes based on millify
 * https://www.npmjs.com/package/millify
 *
 * @param {Number} value
 * @param {Object} options
 *
 * @returns {String}
 */
const formatBytes = (value, options = {
    units:     ['B', 'KB', 'MB', 'GB', 'TB'],
    space:     true,
    precision: 2,
    base:      1024,
}) => millify(value, options);

export default formatBytes;
