<template>
    <base-modal
        size="small"
        :value="value"
        :title="$t('file uploader title')"
        :close-on-click-overlay="false"
        @close="hidePopup"
    >
        <div v-if="loaded" class="flex flex-col gap-y-3 text-sm overflow-hidden">
            <div class="grid grid-cols-2 gap-x-3">
                <div
                    v-for="(item, type) in types"
                    class="flex cursor-pointer select-none flex-col items-center justify-center gap-y-2 rounded-md border border-gray-200 p-3 hover:border-gray-300 hover:bg-gray-50"
                    :key="type"
                    @click="selectCloud(item, type)"
                >
                    <img class="w-10" :src="item.img" alt="">
                    <span>{{ item.label }}</span>
                </div>
            </div>
            <div class="flex flex-col gap-y-2">
                <el-upload
                    ref="upload"
                    class="flex flex-col text-gray-500"
                    action="/file-upload"
                    :data="{
                        fields: true,
                    }"
                    :file-list="fileList"
                    :before-upload="beforeUpload"
                    :on-change="onChange"
                    :on-success="onSuccess"
                    :on-error="onError"
                    :on-remove="onRemove"
                    :data-test="dataTest('dragger')"
                    :multiple="false"
                    drag
                >
                    <div class="flex h-full flex-col items-center justify-center gap-y-2">
                        <base-icon class="text-gray-300" name="cloud-upload-alt" size="3x" />
                        <div>{{ $t('file uploader drop file') }}</div>
                    </div>
                    <template #tip>
                        <div class="mt-2">{{ tooltip }}</div>
                    </template>
                </el-upload>
            </div>
            <base-alert
                v-if="errorMessage"
                type="danger"
                icon
            >
                <span v-html="errorMessage" />
            </base-alert>
        </div>

        <template #footer>
            <div class="flex w-full items-center justify-between">
                <div class="flex items-center gap-x-2">
                    <base-tooltip
                        placement="top"
                        :content="$t('file id field tooltip')"
                    >
                        <base-icon
                            class="text-[#cc5b23]"
                            name="question-circle"
                            prefix="far"
                        />
                    </base-tooltip>
                    <span>{{ 'ID Field:' }}</span>
                    <base-select
                        v-model="fileFieldId"
                        class="w-56"
                        size="medium"
                        placeholder="Select or Enter `id` field"
                        no-data-text="There are no fields, please enter the field manually."
                        :options="fileFields"
                        :disabled="fileLoading"
                        creatable
                    >
                        <template #suffix>
                            <base-icon
                                v-if="fileLoading"
                                class="animate-spin"
                                name="spinner"
                            />
                        </template>
                    </base-select>
                </div>

                <base-button
                    class="justify-self-end"
                    label="Apply"
                    type="primary"
                    size="small"
                    :disabled="!filePath"
                    @click="apply"
                />
            </div>
        </template>
    </base-modal>
</template>

<script>
import qs from 'qs';
import mimeTypes from 'mime-types';
import { mapActions } from 'vuex';
import notify from '@/components/base-components/components/notification/notify';
import GoogleDriveLogo from '@/assets/img/google-drive-logo.png';
import DropboxLogo from '@/assets/img/dropbox-logo.png';

export default {
    name:  'FileUploader',
    props: {
        value:           {
            type:     Boolean,
            required: true,
        },
        platform:        {
            type:    String,
            default: 'CSV',
        },
        maxSize:         {
            type:    Number,
            default: 1024 * 1024 * 1024, // 1Gb
        },
        selectedFile:    {
            type: String,
        },
        fields:          {
            type:    Array,
            default: () => ([]),
        },
        selectedFieldId: {
            type: String,
        },
    },
    data() {
        return {
            loaded: false,

            platformTypeMimeTypes: {},

            errorMessage: undefined,

            fileList: [],

            fileLoading: false,
            fileFields:  [],
            filePath:    undefined,
            fileFieldId: undefined,
        };
    },
    created() {
        this.$http.get(
            '/platform-type-mime-types',
        ).then((data) => {
            this.platformTypeMimeTypes = data;
            this.loaded = true;
        }).catch(({ message }) => {
            notify.error({
                title:    this.$t('tmp An error occurred'),
                message,
                duration: 7500,
            });
        });
    },
    mounted() {
        this.fileFields = this.fields;

        if (this.selectedFieldId) {
            this.fileFieldId = this.selectedFieldId;
        }

        if (this.selectedFile) {
            this.filePath = this.selectedFile;
            this.fileList.push({
                name: this.getFileName(this.selectedFile),
            });
        }
    },
    computed: {
        formattedMaxSize() {
            return this.$filters.formatBytes(this.maxSize);
        },
        tooltip() {
            return this.$t('file uploader tooltip', {
                types: this.allowedTypes.join('/'),
                size:  this.formattedMaxSize,
            });
        },
        typeMimeTypes() {
            return this.platformTypeMimeTypes[this.platform];
        },
        allowedTypes() {
            return Object.keys(this.typeMimeTypes);
        },
        allowedMimeTypes() {
            return Object.values(this.typeMimeTypes);
        },
        types() {
            return {
                google:  {
                    label:    'Google',
                    img:      GoogleDriveLogo,
                    callback: this.onGoogle,
                },
                dropbox: {
                    label:    'Drop Box',
                    img:      DropboxLogo,
                    callback: this.onDropBox,
                },
            };
        },
        typeErrorMessage() {
            return this.$t('file uploader error', {
                file: this.allowedTypes.map(type => `*.${type}`).join(', '),
            });
        },
    },
    methods:  {
        ...mapActions({
            showLoader: 'loader/SHOW',
            hideLoader: 'loader/HIDE',
        }),
        setMessage(message) {
            this.errorMessage = message;
        },
        hideMessage() {
            this.setMessage(undefined);
        },
        showLoading() {
            this.showLoader({ dot: true, showMessage: false });
        },
        beforeUpload(file) {
            const errorMessage = this.validate(file, true);

            if (errorMessage !== null) {
                this.setMessage(errorMessage);
                return false;
            }

            this.fileLoading = true;

            this.hideMessage();
        },
        onSuccess(response) {
            this.fileFields = response?.fields ?? [];
            this.filePath = response?.path;
            this.fileLoading = false;
        },
        onError() {
            this.errorMessage = this.$t('something has gone wrong');
            this.fileLoading = false;
        },
        onRemove(file, fileList) {
            this.fileList = fileList;
            this.fileFields = [];
            this.filePath = undefined;
            this.fileFieldId = undefined;
        },
        onChange(file, fileList) {
            if (fileList.length < 2) {
                this.fileList = fileList;
                return;
            }

            this.fileList = [];
            this.fileList.push(file);
        },
        apply() {
            this.$emit('upload', {
                path:    this.filePath,
                fields:  this.fileFields,
                fieldId: this.fileFieldId,
            });

            notify.success({
                title:   this.$t('request success notification'),
                message: this.$t('file uploader added'),
            });

            this.hidePopup();
        },
        upload(path) {
            this.$emit('upload', path);

            notify.success({
                title:   this.$t('request success notification'),
                message: this.$t('file uploader added'),
            });
        },
        hidePopup() {
            this.hideMessage();
            this.hideLoader();

            this.$refs.upload.clearFiles();

            this.fileList = [];
            this.fileFields = [];
            this.filePath = undefined;
            this.fileFieldId = undefined;

            this.$emit('input', false);
        },
        selectCloud(item, type) {
            this.showLoading();

            item.callback(type, this.onResolveCloud, this.onRejectCloud);
        },
        onResolveCloud(result, type) {
            if (!result.filePath) {
                this.uploadCloud(result, type);
            } else {
                this.filePath = result.filePath;
                this.hidePopup(true);
            }
        },
        onRejectCloud(error) {
            this.hideLoader();
            this.errorMessage = error;
        },
        onGoogle(type, resolve, reject) {
            this.$googleFilePicker.open(
                (file, authToken) => {
                    const error = this.validate(
                        {
                            name: file.title,
                            size: file.fileSize,
                            type: file.fileExtension,
                        },
                        true,
                    );

                    if (error !== null) {
                        reject(error);
                    } else {
                        const params = {
                            fileId:   file.id,
                            fileName: file.title,
                            authToken,
                        };

                        resolve(params, type);
                    }
                },
                reject,
                reject,
            );
        },
        onDropBox(type, resolve, reject) {
            window.Dropbox.choose({
                linkType:    'direct',
                multiselect: false,
                extensions:  this.allowedTypes.map(extension => `.${extension}`),
                success(file) {
                    resolve({ filePath: file[0].link }, type);
                },
                cancel() {
                    reject();
                },
            });
        },
        uploadCloud(params, type) {
            this.showLoader();
            this.fileLoading = true;

            this.$http.post(
                '/file-upload',
                qs.stringify(Object.assign(params, { isCloud: true, cloudType: type, fields: true })),
            ).then((response) => {
                this.hideLoader();
                this.onSuccess(response);
            }).catch(({ message }) => {
                this.hideLoader();
                this.errorMessage = message;
                this.fileLoading = false;
            });
        },
        validate(file) {
            if (file.size > this.maxSize) {
                return this.sizeErrorMessage(file.name);
            }

            if (file.size === 0) {
                return this.sizeErrorMessage(file.name, true);
            }

            if (!this.allowedMimeTypes.includes(this.getFileMimeType(file))) {
                return this.typeErrorMessage;
            }

            return null;
        },
        sizeErrorMessage(name, empty = false) {
            if (empty === true) {
                return this.$t('file is empty', { file: name });
            }

            return this.$t('file size must be less than', { file: name, size: this.formattedMaxSize });
        },
        getFileMimeType(file) {
            return mimeTypes.lookup(file.name) || file.type || '';
        },
        getFileName(path) {
            return path.split('\\').pop().split('/').pop();
        },
    },
};
</script>

<!--Google file picker-->
<style lang="postcss">
.picker-dialog-bg {
    z-index: 200000 !important;
}

.picker-dialog {
    z-index: 200001 !important;
}
</style>
