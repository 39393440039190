export const SIZE = Object.freeze({
    LARGE:  'large',
    MEDIUM: 'medium',
    SMALL:  'small',
});

export const DEFAULT_PALETTE = Object.freeze([
    '#336699',
    '#4080BF',
    '#4D4D99',
    '#ECC442',
    '#D4571B',
    '#33998F',
    '#94a3b8',
    '#a8a29e',
    '#f87171',
    '#fb923c',
    '#fbbf24',
    '#facc15',
    '#a3e635',
    '#4ade80',
    '#34d399',
    '#2dd4bf',
    '#22d3ee',
    '#38bdf8',
    '#60a5fa',
    '#818cf8',
    '#a78bfa',
    '#c084fc',
    '#e879f9',
    '#f472b6',
    '#fb7185',
    '#679bc9',
    '#0C86FE',
]);
