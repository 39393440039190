<template>
    <div class="flex overflow-visible relative flex-col justify-center space-y-1 group">
        <component
            :is="url ? 'a' : 'div'"
            class="flex absolute z-10 justify-center items-center self-center px-2 py-1 space-x-2 max-w-full text-gray-500 bg-white bg-opacity-80 rounded-md shadow-md opacity-0 transition-opacity duration-300 ease-in-out group-hover:opacity-100"
            :class="{
                'hover:bg-opacity-100 hover:text-gray-600': !!url,
                'cursor-not-allowed': !url,
            }"
            :href="url"
            target="_blank"
            @click.prevent="openLink(url)"
        >
            <span class="text-center truncate">{{ name }}</span>
            <base-icon
                v-if="url"
                name="external-link-square-alt"
                size="sm"
            />
        </component>
        <crm-logo
            class="filter duration-300 ease-in-out group-hover:blur-sm"
            :crm="crm"
            :name="name"
            :size="size"
            :auto-size="autoSize"
            :show-title="false"
        />
    </div>
</template>

<script>
import CrmLogo from '@/components/crm-logo';

export default {
    name:       'CrmLogoLink',
    components: { CrmLogo },
    props:      {
        crm:      {
            type:    String,
            default: 'default',
        },
        name:     String,
        size:     String,
        autoSize: Boolean,
        url:      String,
    },
    methods:    {
        openLink(link) {
            if (!link) return;

            window.open(link, '_blank');
        },
    },
};
</script>
