<template>
    <div
        class="overflow-hidden w-full bg-gray-100 rounded-full"
        :class="height"
        role="progressbar"
        :aria-valuenow="percentage"
        aria-valuemin="0"
        aria-valuemax="100"
    >
        <div
            class="flex overflow-hidden justify-end items-center h-full rounded-full duration-700 ease-in-out transition-size"
            :class="{
                running: 'animate-candy-move bg-candy-green',
                completed: 'bg-green-400',
                failed: 'bg-candy-red',
                stopped: 'bg-candy-gray',
                unknown: 'animate-candy-move bg-candy-gray',
            }[status]"
            :style="barStyle"
        >
            <span
                v-if="showText"
                ref="text"
                class="bg-black bg-opacity-10 flex h-full items-center px-1.5 rounded-full text-white text-xs"
            >{{ percent }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name:     'BaseProgress',
    props:    {
        percentage: {
            type:      Number,
            default:   0,
            required:  true,
            validator: value => value >= 0 && value <= 100,
        },
        status:     {
            type:      String,
            default:   'unknown',
            validator: status => ['running', 'completed', 'failed', 'stopped', 'unknown'].includes(status),
        },
        height:     {
            type:    String,
            default: 'h-6',
        },
        showText:   {
            type:    Boolean,
            default: true,
        },
    },
    computed: {
        percent() {
            return `${this.percentage}%`;
        },
        barStyle() {
            return {
                width: this.percent,
            };
        },
    },
};
</script>
