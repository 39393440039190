<script>
export default {
    name: 'BaseJsonEditor',
};
</script>

<script setup>
import { nextTick, onMounted, ref, watch } from 'vue';
import { isString } from '@/components/base-components/utils/types';
import BaseCodeEditor from '@/components/base-components/components/code-editor/base-code-editor';
import JsonEditorPanel from '@/components/base-components/components/code-editor/json/base-json-editor-panel';

const props = defineProps({
    value: {
        type: [String, Array, Object],
    },
});

const emit = defineEmits({
    input:    null,
    validate: null,
});

function stringify(value) {
    if (isString(value)) return value;

    return value ? JSON.stringify(value) : '';
}

const code = ref(stringify(props.value));
const error = ref();

watch(code, () => {
    try {
        error.value = undefined;

        emit('input', JSON.parse(code.value));
    } catch {
        error.value = 'Your JSON is not valid';
    }
}, {
    immediate: true,
});

function validate(markers) {
    emit('validate', !markers.length, markers);
}

const editorPanel = ref(null);

function formatValue(value) {
    try {
        code.value = editorPanel.value.format(value);
    } catch {
        code.value = value;
    }
}

watch(() => stringify(props.value), async (value) => {
    await nextTick();

    if (editorPanel.value.compat(code.value) === value) return;

    formatValue(value);
});

onMounted(() => {
    if (!code.value) return;

    formatValue(code.value);
});
</script>

<template>
    <div class="flex flex-col gap-y-1 overflow-hidden">
        <json-editor-panel
            ref="editorPanel"
            :json="code"
            :disabled="!!error"
            @format="v => code = v"
            @compat="v => code = v"
        />
        <base-code-editor
            v-model="code"
            class="flex-1 overflow-hidden rounded-md border"
            :attrs="{
                language: 'json',
            }"
            @validate="validate"
        />
        <span
            v-if="error"
            v-text="error"
            class="text-xs text-red-500"
        />
    </div>
</template>
