import BaseApi from '@/api/base-api';
import Axios from '@/api/provider/axios';

class Api extends BaseApi {
    constructor(provider = undefined) {
        super();
        this.provider = provider ?? new Axios();
    }
}

export default Api;

export const instance = new Api();
