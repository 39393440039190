<template>
    <div class="bg-gray-800">
        <div class="flex justify-between items-center px-8 py-2 mx-auto max-w-8xl">
            <manager-header-title :environment="environment" />
            <manager-header-navigation :list="menuList" />
            <div class="flex items-center">
                <button
                    class="shrink-0 px-3 py-2 mx-1 my-1 text-sm font-semibold leading-5 text-gray-300 rounded-md transition duration-150 ease-in-out cursor-pointer font-avenir hover:text-white hover:bg-gray-700 focus:text-white focus:bg-gray-700 focus:outline-none"
                    @click="logout"
                >
                    Logout
                    <base-icon class="ml-2" name="sign-out-alt" prefix="fas" />
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import ManagerHeaderNavigation from '@/components/manager/header/navigation';
import ManagerHeaderTitle from '@/components/manager/header/title';

export default {
    name:       'ManagerHeader',
    components: { ManagerHeaderNavigation, ManagerHeaderTitle },
    props:      {
        environment: String,
    },
    data() {
        return {
            menu: [
                {
                    label: 'Migration',
                    items: [
                        {
                            path:  '/manager/migration',
                            label: 'Migration',
                            name:  'managerMigration',
                        },
                        {
                            path:  '/manager/test',
                            label: 'Test Records',
                            name:  'managerTest',
                        },
                        {
                            path:  '/manager/mapping/presets',
                            label: 'Mapping Presets',
                            name:  'managerMappingPresets',
                        },
                        {
                            path:  '/mapping/compare',
                            label: 'Mapping Compare',
                            name:  'mappingCompare',
                        },
                        {
                            path:  '/manager/matching',
                            label: 'Matching',
                            name:  'managerMatching',
                        },
                    ],
                },
                {
                    path:  '/manager/backup',
                    label: 'Backup',
                    name:  'managerBackup',
                },
                {
                    path:  '/manager/estimate',
                    label: 'Estimate',
                    name:  'managerEstimate',
                },
                {
                    path:  '/manager/replicator',
                    label: 'SchemaSync',
                    name:  'managerReplicator',
                },
                {
                    path:  '/manager/calendah',
                    label: 'Calendah',
                    name:  'managerCalendah',
                },
                {
                    path:  '/manager/crm',
                    label: 'Crm',
                    name:  'managerCrm',
                },
                {
                    label: 'User',
                    items: [
                        {
                            path:  '/manager/user',
                            label: 'User',
                            name:  'managerUser',
                        },
                        {
                            path:  '/manager/survey-answer',
                            label: 'Survey Answers',
                            name:  'managerSurveyAnswer',
                        },
                        {
                            path:  '/manager/acl-access',
                            label: 'ACL Access',
                            name:  'managerAclAccess',
                        },
                        {
                            path:  '/manager/acl-role',
                            label: 'ACL Role',
                            name:  'managerAclRole',
                        },
                        {
                            path:  '/manager/acl-roles-access',
                            label: 'ACL Role Access',
                            name:  'managerAclRolesAccess',
                        },
                    ],
                },
                {
                    label: 'Payments',
                    items: [
                        {
                            path:  '/manager/service-plan',
                            label: 'Service Plan',
                            name:  'managerServicePlan',
                        },
                        {
                            path:  '/manager/service-payment',
                            label: 'Service Payment',
                            name:  'managerServicePayment',
                        },
                        {
                            path:  '/manager/payment',
                            label: 'Payment',
                            name:  'managerPayment',
                        },
                        {
                            path:  '/manager/payment-ipn',
                            label: 'Payment IPN',
                            name:  'managerPaymentIPN',
                        },
                        {
                            path:  '/manager/coupon',
                            label: 'Coupon',
                            name:  'managerCoupon',
                        },
                        {
                            path:  '/manager/migration-payment',
                            label: 'Migration Payment',
                            name:  'managerMigrationPayment',
                        },
                        {
                            path:  '/manager/balance',
                            label: 'Balance History',
                            name:  'managerBalance',
                        },
                    ],
                },
                {
                    label: 'Platform',
                    items: [
                        {
                            path:  '/manager/platform/index',
                            label: 'Compare',
                            name:  'managerPlatformCompare',
                        },
                        {
                            path:  '/manager/platform/methods',
                            label: 'Platforms',
                            name:  'managerPlatformMethods',
                        },
                        {
                            path:  '/manager/prices',
                            label: 'Prices',
                            name:  'managerPrices',
                        },
                    ],
                },
                {
                    label: 'API',
                    items: [
                        {
                            path:  '/manager/api-data-cache',
                            label: 'Data Cache',
                            name:  'managerApiDataCache',
                        },
                        {
                            path:  '/manager/api-log',
                            label: 'Log',
                            name:  'managerApiLog',
                        },
                    ],
                },
                {
                    label: 'Report',
                    items: [
                        {
                            path:  '/manager/jira',
                            label: 'Engineer statistics',
                            name:  'managerJira',
                        },
                        {
                            path:  '/manager/report/migration',
                            label: 'Migration',
                            name:  'managerReportMigration',
                        },
                        {
                            path:  '/manager/report/sales',
                            label: 'Sales',
                            name:  'managerReportSales',
                        },
                    ],
                },
                {
                    label: 'System',
                    items: [
                        {
                            path:  '/manager/email',
                            label: 'Emails',
                            name:  'managerEmail',
                        },
                        {
                            path:  '/manager/event',
                            label: 'Events',
                            name:  'managerEvent',
                        },
                        {
                            path:  '/manager/process-termination',
                            label: 'Process Termination',
                            name:  'managerProcessTermination',
                        },
                        {
                            path:  '/manager/process-instances',
                            label: 'Process Instances',
                            name:  'managerProcessInstances',
                        },
                        {
                            path:  '/manager/log',
                            label: 'Logs',
                            name:  'managerLog',
                        },
                    ],
                },
            ],
        };
    },
    computed: {
        menuList() {
            return this.menu.filter(item => item.show ?? true);
        },
    },
    methods:  {
        ...mapActions({
            showLoader: 'loader/SHOW',
            hideLoader: 'loader/HIDE',
            aclAccess:  'aclAccess/GET_ACCESSES',
        }),
        isActive(item) {
            if (item.link) {
                return window.location.href.match(`.*${item.link}$`) !== null;
            }

            return item.items
                .map(child => `.*${child.link}$`)
                .some(pattern => window.location.href.match(pattern) !== null);
        },
        async logout() {
            this.showLoader();

            await this.$http.post('/user/logout', {});
            await this.aclAccess('guest');
            await this.$router.push({ name: 'userLogin' });

            this.hideLoader();
        },
    },
};
</script>
