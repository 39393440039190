<template>
    <div v-if="!disabled || name" class="max-w-full h-7">
        <base-input
            v-if="edit && !disabled"
            v-model="newName"
            size="small"
            clearable
            :maxlength="50"
            :placeholder="$t('add name')"
            @clear="newName = null"
            @blur="edit = false"
            @change="save"
        />
        <div
            v-else
            class="inline-flex gap-x-1 items-center px-2 w-full h-full rounded transition-colors duration-150"
            :class="{
                'cursor-pointer hover:bg-gray-100': !disabled,
            }"
            @click.prevent.stop="click"
        >
            <div class="inline-block truncate">
                <span v-if="name" :title="name.length > 10 ? name : undefined">
                    {{ name }}
                </span>
                <span v-else class="transition-colors duration-150 text-gray-400 group-hover:text-gray-500">
                    {{ $t('add name') }}
                </span>
            </div>
            <base-icon
                v-if="!disabled"
                class="opacity-0 transition-opacity duration-150 text-gray-500 group-hover:opacity-100"
                name="pencil-alt"
                size="sm"
            />
        </div>
    </div>
</template>

<script>

export default {
    name:  'ItemName',
    props: {
        name:     String,
        disabled: Boolean,
    },
    data() {
        return {
            newName: this.name,
            edit:    false,
        };
    },
    watch:   {
        name() {
            this.newName = this.name;
        },
    },
    methods: {
        async click() {
            if (this.disabled) return;

            this.edit = true;
        },
        save() {
            this.edit = false;
            this.$emit('rename', this.newName);
        },
    },
};
</script>
