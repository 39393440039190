<script>
export default {
    name: 'BaseBreadcrumb',
};
</script>

<script setup>
import { computed } from 'vue';
import BaseBreadcrumbItem from '@/components/base-components/components/breadcrumb/base-breadcrumb-item';
import BaseBreadcrumbDivider from '@/components/base-components/components/breadcrumb/base-breadcrumb-divider';

const props = defineProps({
    items:   {
        type: Array,
    },
    divider: {
        type:    [String, Object],
        default: '/',
    },
});

const breadcrumbs = computed(() => (props.items || [])
    .map(item => ({
        ...item,
        label: typeof item.label === 'function' ? item.label() : item.label,
    }))
    .filter(({ label, component }) => !!label || !!component));
</script>

<template>
    <div class="inline-flex items-center truncate">
        <template v-for="(item, index) in breadcrumbs">
            <base-breadcrumb-item
                :key="index"
                :label="item.label"
                :component="item.component"
                :attrs="item.attrs"
            />
            <base-breadcrumb-divider
                v-if="index < (breadcrumbs.length - 1)"
                :key="`${index}-divider`"
                :divider="divider"
            />
        </template>
    </div>
</template>
