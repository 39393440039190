import Vue from 'vue';
import qs from 'qs';
import { findIndex } from 'lodash';
import { mappingCustomFieldsList } from '@/modules/mapping/api/api';
import callIfFunction from '@/assets/util/call-if-function';
import useWebSockets from '@/composables/useWebSockets';

const {
    on:  onWebSocket,
    off: offWebSocket,
} = useWebSockets();

export default {
    namespaced: true,
    state:      {
        fields: {},
    },
    mutations:  {
        SET_FIELDS(state, { entityId, fields }) {
            Vue.set(state.fields, entityId, fields);
        },
        SET_FIELD(state, { entityId, index, field }) {
            Vue.set(state.fields[entityId], index, field);
        },
        REMOVE_FIELDS(state) {
            Vue.set(state, 'fields', {});
        },
    },
    actions:    {
        SUBSCRIBE_FIELD(context, { entityId, field, onSuccess }) {
            const successChannel = `custom-field-create-${field.id}`;

            onWebSocket(successChannel, (message) => {
                const customField = JSON.parse(message);

                offWebSocket(successChannel);
                context.commit(
                    'SET_FIELD',
                    {
                        entityId,
                        field: customField,
                        index: findIndex(context.state.fields[entityId], item => item.id === customField.id),
                    },
                );

                callIfFunction(onSuccess, customField);
            });
        },
        SUBSCRIBE_FIELDS(context, { entityId, actionCallback, fieldStatus }) {
            const fields = context.state.fields[entityId];

            fields.forEach((field) => {
                if (field.status === fieldStatus) {
                    context.dispatch(
                        'SUBSCRIBE_FIELD',
                        {
                            entityId,
                            field,
                            onSuccess: actionCallback,
                        },
                    );
                }
            });
        },
        GET_FIELDS_LIST(context, { requestData, onSuccess, onFailure }) {
            return mappingCustomFieldsList(
                requestData,
            ).then((resolveData) => {
                callIfFunction(onSuccess, resolveData);

                return Promise.resolve(resolveData);
            }).catch((rejectReason) => {
                callIfFunction(onFailure, rejectReason.data);

                return Promise.reject(rejectReason);
            });
        },
        REQUEST(context, { action, data }) {
            return Vue.prototype.$http.post(
                `/mapping/${action}`,
                qs.stringify(data),
            );
        },
    },
};
