<script>
export default {
    name: 'BaseSelectOption',
};
</script>

<script setup>
import { computed, inject } from 'vue';
import { TYPE } from '@/components/base-components/components/select';

const props = defineProps({
    value:    {
        type:     [String, Number, Boolean],
        required: true,
    },
    label:    {
        type:     [String, Number],
        required: true,
    },
    type:     {
        type:      String,
        default:   TYPE.DEFAULT,
        validator: value => Object.values(TYPE).includes(value),
    },
    created:  {
        type: Boolean,
    },
    disabled: {
        type: Boolean,
    },
});

const {
    multiple, multipleLimit, value, change, hoverOption, setHoverOption,
} = inject('base-select');

const isSelected = computed(() => {
    if (multiple.value) return value.value.includes(props.value);

    return value.value === props.value;
});

const isDisabled = computed(() => {
    if (props.disabled) return true;
    if (isSelected.value || !multiple.value || !multipleLimit.value) return false;

    return value.value.length >= multipleLimit.value;
});

if (!multiple.value && isSelected.value) {
    setHoverOption(props.value);
}

const typeDisabledClass = computed(() => {
    const hoverClass = {
        [TYPE.DEFAULT]:       'hover:bg-science-blue-800/5',
        [TYPE.PRIMARY]:       'hover:bg-primary-600/5',
        [TYPE.SECONDARY]:     'hover:bg-secondary-950/5',
        [TYPE.PRIMARY_OLD]:   'hover:bg-science-blue-700/5',
        [TYPE.SECONDARY_OLD]: 'hover:bg-science-blue-800/5',
        [TYPE.SUCCESS]:       'hover:bg-emerald-700/5',
        [TYPE.WARNING]:       'hover:bg-orange-600/5',
        [TYPE.DANGER]:        'hover:bg-red-700/5',
        [TYPE.INFO]:          'hover:bg-gray-500/5',
    }[props.type];

    return {
        [hoverClass]:       !isDisabled.value,
        'text-gray-400/80': isDisabled.value,
    };
});

const typeSelectedClass = computed(() => {
    if (!isSelected.value) return;

    return [
        'font-semibold',
        {
            [TYPE.DEFAULT]:       'text-science-blue-800',
            [TYPE.PRIMARY]:       'text-primary-600',
            [TYPE.SECONDARY]:     'text-secondary-950',
            [TYPE.PRIMARY_OLD]:   'text-science-blue-700',
            [TYPE.SECONDARY_OLD]: 'text-science-blue-800',
            [TYPE.SUCCESS]:       'text-emerald-700',
            [TYPE.WARNING]:       'text-orange-600',
            [TYPE.DANGER]:        'text-red-700',
            [TYPE.INFO]:          'text-gray-500',
        }[props.type],
    ];
});

const typeHoverClass = computed(() => {
    if (props.value !== hoverOption.value) return;

    return {
        [TYPE.DEFAULT]:       'bg-science-blue-700/5',
        [TYPE.PRIMARY]:       'bg-primary-600/5',
        [TYPE.SECONDARY]:     'bg-secondary-950/5',
        [TYPE.PRIMARY_OLD]:   'bg-science-blue-700/5',
        [TYPE.SECONDARY_OLD]: 'bg-science-blue-800/5',
        [TYPE.SUCCESS]:       'bg-emerald-700/5',
        [TYPE.WARNING]:       'bg-orange-600/5',
        [TYPE.DANGER]:        'bg-red-700/5',
        [TYPE.INFO]:          'bg-gray-500/5',
    }[props.type];
});

const click = () => {
    if (isDisabled.value) return;

    change(props.value, isSelected.value);
};

const mouseOver = () => {
    if (isDisabled.value || hoverOption.value === null) return;

    setHoverOption(null);
};
</script>

<template>
    <div
        :class="[
            isDisabled ? 'cursor-not-allowed' : 'cursor-pointer',
            typeDisabledClass,
            typeSelectedClass,
            typeHoverClass
        ]"
        @click="click"
        @mouseover="mouseOver"
    >
        <slot>
            <div class="flex select-none flex-wrap items-center justify-between gap-x-3 px-5 text-sm py-1.5">
                <slot name="prefix" />

                <span class="h-5 flex-1">
                    {{ label }}
                </span>

                <slot name="suffix" />

                <base-icon
                    v-if="multiple && isSelected && created"
                    class="text-2xs"
                    name="pencil-alt"
                />

                <base-icon
                    v-if="multiple && isSelected"
                    class="text-2xs"
                    name="check"
                />
            </div>
        </slot>
    </div>
</template>
