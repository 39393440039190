<script>
export default {
    name: 'BasePaginationSummary',
};
</script>

<script setup>
import pluralize from 'pluralize';
import { computed } from 'vue';
import { SIZE, TYPE } from '@/components/base-components/components/pagination';

const props = defineProps({
    page:     {
        type:     Number,
        required: true,
    },
    pageSize: {
        type:     Number,
        required: true,
    },
    total:    {
        type:     Number,
        required: true,
    },
    units:    {
        type: String,
    },
    size:     {
        type:      String,
        default:   SIZE.SMALL,
        validator: value => Object.values(SIZE).includes(value),
    },
    type:     {
        type:      String,
        default:   TYPE.DEFAULT,
        validator: value => Object.values(TYPE).includes(value),
    },
});

const to = computed(() => Math.min(props.page * props.pageSize, props.total));
const from = computed(() => {
    if (props.total === 0) return props.total;

    return (props.page * props.pageSize) - props.pageSize + 1;
});

const typeClass = computed(() => ({
    [TYPE.DEFAULT]:       'text-gray-500',
    [TYPE.PRIMARY]:       'text-primary-600',
    [TYPE.SECONDARY]:     'text-secondary-950',
    [TYPE.PRIMARY_OLD]:   'text-science-blue-500',
    [TYPE.SECONDARY_OLD]: 'text-science-blue-800',
    [TYPE.SUCCESS]:       'text-emerald-600',
    [TYPE.WARNING]:       'text-amber-500',
    [TYPE.DANGER]:        'text-red-600',
    [TYPE.INFO]:          'text-gray-500',
}[props.type]));

const displayUnits = computed(() => {
    if (!props.units) return;

    return pluralize(props.units, props.total);
});
</script>

<template>
    <div
        :class="[
            {
                [SIZE.LARGE]: 'text-base',
                [SIZE.MEDIUM]: 'text-sm',
                [SIZE.SMALL]: 'text-sm',
            }[size],
        ]"
    >
        Showing <strong :class="typeClass">{{ from }}-{{ to }}</strong> of <strong :class="typeClass">{{ total }}</strong> {{ displayUnits }}
    </div>
</template>
