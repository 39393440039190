import { getSerializerByType, getSerializerByValue, guessSerializer, TYPES } from '@/storage/serializer';

class Storage {
    constructor(storage = window.localStorage) {
        this._isSupported = true;
        this._storage = storage;

        try {
            const test = 'storage-test';

            this._storage.setItem(test, test);
            this._storage.removeItem(test);
        } catch {
            this._isSupported = false;

            // eslint-disable-next-line no-console
            console.error('Local storage is not supported!');
        }
    }

    get(key, defaults = null, type = TYPES.ANY) {
        if (!this._isSupported) return null;

        const item = this._storage.getItem(key);

        if (!item) return defaults;

        const { value, expiry } = Storage._parseItem(item);

        if (expiry) {
            const now = (new Date()).getTime();

            if (expiry <= now) {
                this.remove(key);

                return defaults;
            }
        }

        return Storage._unserialize(value, defaults, type);
    }

    set(key, value, ttlMs = 0) {
        if (!this._isSupported) return null;

        const serialized = Storage._serialize(value);

        if (!ttlMs || ttlMs <= 0) {
            this._storage.setItem(key, serialized);

            return this;
        }

        this._storage.setItem(key, JSON.stringify({
            value:  serialized,
            expiry: (new Date()).getTime() + ttlMs,
        }));

        return this;
    }

    remove(key) {
        if (!this._isSupported) return null;

        return this._storage.removeItem(key);
    }

    static _unserialize(value, defaults = null, type = TYPES.ANY) {
        const actualType = defaults !== null ? guessSerializer(defaults) : type;

        const serializer = getSerializerByType(actualType);

        if (serializer.is(value)) return value; // TODO: Temporary issue to process non-serialized values

        return serializer.get(value) ?? defaults;
    }

    static _serialize(value) {
        return getSerializerByValue(value).set(value);
    }

    static _parseItem(item) {
        try {
            const { value, expiry } = JSON.parse(item);

            if (value === undefined && expiry === undefined) return { value: item };

            return { value, expiry };
        } catch {
            return { value: item };
        }
    }
}

export default Storage;
