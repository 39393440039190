import { script } from '@/assets/util/append-file';

export default {
    install(Vue, { config }) {
        if (!config.scripts?.dropbox?.apiKey) return;

        script('https://www.dropbox.com/static/api/2/dropins.js', true, {
            id:             'dropboxjs',
            'data-app-key': config.scripts.dropbox.apiKey,
        });
    },
};
