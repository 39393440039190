import { computed, onBeforeUnmount, ref, watch } from 'vue';

let count = 0;

const stack = ref([]);
const current = computed(() => (stack.value.length ? stack.value[stack.value.length - 1] : undefined));

function add(id) {
    stack.value.push(id);
}

function remove(id) {
    const index = stack.value.findIndex(i => i === id);

    if (index === -1) return;

    stack.value.splice(index, 1);
}

export function useStack(display, zIndexBase = 20200) {
    // eslint-disable-next-line no-plusplus
    const id = ++count;

    const isActive = computed(() => current.value === id);
    const stackIndex = computed(() => stack.value.findIndex(i => i === id));

    const zIndex = computed(() => zIndexBase + Math.max(stackIndex.value, 0));

    watch(display, () => {
        display.value ? add(id) : remove(id);
    });

    onBeforeUnmount(() => {
        remove(id);

        // eslint-disable-next-line no-plusplus
        count--;
    });

    return {
        isActive,
        stackIndex,
        zIndex,
    };
}
