<template>
    <font-awesome-icon
        :class="[
            {
                'cursor-pointer': clickable,
            },
            {
                success: 'text-success',
                info: 'text-primary-600',
                warning: 'text-warning',
                error: 'text-error',
                disabled: 'text-gray-400',
            }[type],
        ]"
        :size="fixedWidth ? null : size"
        :icon="[prefix, name]"
        :fixed-width="fixedWidth"
        @click="click"
    />
</template>

<script>
export default {
    name:    'BaseIcon',
    props:   {
        name:       {
            type:     String,
            required: true,
        },
        prefix:     {
            type:    String,
            default: 'fas',
            validator(prefix) {
                return [
                    'fas',
                    'far',
                    'fab',
                    'fac',
                ].indexOf(prefix) !== -1;
            },
        },
        size:       {
            type:    String,
            default: 'lg',
        },
        type:       String,
        clickable:  Boolean,
        fixedWidth: Boolean,
    },
    methods: {
        click(event) {
            if (this.clickable) {
                this.$emit('click', event);
            }
        },
    },
};
</script>
