const prefix = 'fac';
const iconName = 'percentage-circle';
const width = 512;
const height = 512;
const ligatures = [];
const unicode = null;
const svgPathData = 'M248 496C384.967 496 496 384.967 496 248C496 111.033 384.967 0 248 0C111.033 0 0 111.033 0 248C0 384.967 111.033 496 248 496ZM195.136 138.861C210.95 154.675 210.95 180.323 195.136 196.137C179.322 211.958 153.681 211.958 137.86 196.137C122.047 180.323 122.047 154.675 137.86 138.861C153.674 123.046 179.322 123.046 195.136 138.861ZM299.859 300.863C315.673 285.049 341.321 285.049 357.135 300.863C372.955 316.683 372.955 342.325 357.135 358.139C341.321 373.954 315.673 373.954 299.859 358.139C284.045 342.325 284.045 316.677 299.859 300.863ZM341.593 140.095L355.907 154.409C363.817 162.319 363.817 175.14 355.907 183.044L182.043 356.912C174.139 364.822 161.319 364.822 153.409 356.912L139.094 342.597C131.184 334.693 131.184 321.872 139.094 313.962L312.958 140.095C320.862 132.184 333.683 132.184 341.593 140.095Z';

export const definition = {
    prefix,
    iconName,
    icon: [
        width,
        height,
        ligatures,
        unicode,
        svgPathData,
    ],
};

export { definition as faPercentageCircle };
