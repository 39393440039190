<script>
export default {
    name: 'CrmEntityCacheSlowTooltip',
};
</script>

<script setup>
defineProps({
    crm:  {
        type:     String,
        required: true,
    },
    size: {
        type:    String,
        default: 'lg',
    },
});
</script>

<template>
    <base-tooltip>
        <base-icon
            name="stopwatch"
            prefix="fas"
            type="warning"
            :size="size"
        />
        <template #content>
            <div class="text-justify" v-html="$t('crm.entity.cacheSlow', { crm })" />
        </template>
    </base-tooltip>
</template>
