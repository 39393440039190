<template>
    <base-info
        size="small"
        :info="text"
    >
        <span class="text-sm">
            <span v-html="text" />
            <span v-if="faqLink" v-html="moreInfo" />
            <span
                v-if="videoLink"
                class="ml-1"
            >
                <base-icon
                    name="youtube"
                    prefix="fab"
                    :clickable="true"
                    @click="showVideo = true"
                />
                <base-video
                    v-model="showVideo"
                    :link="videoLink"
                />
            </span>
        </span>
    </base-info>
</template>

<script>

export default {
    name:  'AuthorizationFieldInfo',
    props: {
        text:      {
            type:     String,
            required: true,
        },
        faqLink:   {
            type: String,
        },
        videoLink: {
            type: String,
        },
    },
    data() {
        return {
            showVideo: false,
        };
    },
    computed: {
        moreInfo() {
            const params = {
                link: this.faqLink,
            };

            return this.videoLink ? this.$t('wizard faq more info video', params) : this.$t('wizard faq more info', params);
        },
    },
};
</script>
