import animate from '@/assets/util/animate';

const animateChat = (getStyle, duration) => {
    const chatElement = document.getElementById('hubspot-messages-iframe-container');

    if (!chatElement) return;

    animate((progress) => {
        Object.assign(chatElement.style, getStyle(progress));
    }, null, duration, { x1: 0.4, y1: 0, x2: 0.2, y2: 1 });
};

const moveLeft = (el, progress) => ({
    marginRight: `${el.clientWidth * progress}px`,
});

const moveRight = (el, progress) => ({
    marginRight: `${el.clientWidth - (el.clientWidth * progress)}px`,
});

export const enter = (el, getStyle = moveLeft, duration = 500) => {
    animateChat(progress => getStyle(el, progress), duration);
};
export const leave = (el, getStyle = moveRight, duration = 500) => {
    animateChat(progress => getStyle(el, progress), duration);
};
