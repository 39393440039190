<script>
export default {
    name: 'BaseNotification',
};
</script>

<script setup>
import { computed, onMounted, ref } from 'vue';
import animate from '@/assets/util/animate';
import { useStack } from '@/components/base-components/composables/stack';
import { getHorizontalProperty, getVerticalProperty, POSITION, TYPE } from '@/components/base-components/components/notification';

const props = defineProps({
    id:        {
        type: String,
    },
    title:     {
        type: String,
    },
    message:   {
        type: String,
    },
    duration:  {
        type:    Number,
        default: 7500,
    },
    icon:      {
        type: Object,
    },
    type:      {
        type:       String,
        validation: type => Object.values(TYPE).includes(type),
    },
    position:  {
        type:       String,
        default:    POSITION.TOP_RIGHT,
        validation: position => Object.values(POSITION).includes(position),
    },
    offset:    {
        type:    Number,
        default: 0,
    },
    html:      {
        type: Boolean,
    },
    progress:  {
        type: Boolean,
    },
    onClose:   {
        type:     Function,
        required: true,
    },
    onDestroy: {
        type:     Function,
        required: true,
    },
});

const visible = ref(false);

function close() {
    visible.value = false;
}

const { zIndex } = useStack(visible, 99999999);

const timeoutID = ref(null);
const progressWidth = ref(0);
let animationFunction;

function startTimer() {
    if (props.duration <= 0) return;

    timeoutID.value = setTimeout(() => {
        if (visible.value) close();
    }, props.duration);

    if (!props.progress) return;

    animationFunction = animate((value) => {
        progressWidth.value = value * 100;
    }, null, props.duration, { x1: 0, y1: 0, x2: 1, y2: 1 });
}

function clearTimer() {
    if (!timeoutID.value) return;

    animationFunction?.();
    progressWidth.value = 0;
    clearTimeout(timeoutID.value);
    timeoutID.value = null;
}

onMounted(() => {
    startTimer();
    visible.value = true;
});

const notificationIcon = computed(() => {
    if (props.icon) return props.icon;
    if (!props.type) return;

    return {
        [TYPE.ERROR]:   {
            name:  'triangle-exclamation',
            class: 'text-red-600',
        },
        [TYPE.INFO]:    {
            name:  'info-circle',
            class: 'text-gray-400',
        },
        [TYPE.SUCCESS]: {
            name:  'check-circle',
            class: 'text-emerald-500',
        },
        [TYPE.WARNING]: {
            name:  'exclamation-circle',
            class: 'text-amber-500',
        },
    }[props.type];
});

const verticalProperty = computed(() => getVerticalProperty(props.position));
const horizontalProperty = computed(() => getHorizontalProperty(props.position));

const enterClass = computed(() => (horizontalProperty.value === 'left' ? '-translate-x-full' : 'translate-x-full'));

const showProgress = computed(() => props.progress && !!timeoutID.value);

defineExpose({
    close,
});
</script>

<template>
    <transition
        enter-active-class="transition duration-300 ease-in-out"
        leave-active-class="transition duration-300 ease-in-out"
        leave-to-class="opacity-0"
        :enter-class="enterClass"
        @before-leave="onClose"
        @after-leave="onDestroy"
    >
        <div
            v-show="visible"
            class="fixed flex w-80 items-start overflow-hidden rounded-md border border-gray-100 bg-white shadow-md transition-all"
            :class="[
                {
                    [POSITION.TOP_RIGHT]: 'right-4',
                    [POSITION.BOTTOM_RIGHT]: 'right-4',
                    [POSITION.TOP_LEFT]: 'left-4',
                    [POSITION.BOTTOM_LEFT]: 'left-4',
                }[position]
            ]"
            :style="{
                'z-index': zIndex,
                [verticalProperty]: `${offset}px`
            }"
            @mouseenter="clearTimer"
            @mouseleave="startTimer"
        >
            <base-icon
                v-if="notificationIcon"
                v-bind="notificationIcon"
                class="mt-3 ml-3 text-2xl"
            />

            <div class="mr-4 ml-3 flex flex-col gap-y-1 py-3">
                <div class="font-semibold" v-text="title" />
                <div class="text-sm">
                    <p v-if="html" v-html="message" />
                    <p v-else>{{ message }}</p>
                </div>
            </div>

            <div
                class="absolute top-1 right-1 flex cursor-pointer p-1 text-gray-500 opacity-50 transition-opacity duration-300 hover:opacity-100"
                @click="close"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="h-5 w-5"
                >
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </div>

            <div
                v-if="showProgress"
                class="absolute bottom-0 left-0 h-1 bg-brand-sushi"
                :style="{
                    width: `${progressWidth}%`
                }"
            />
        </div>
    </transition>
</template>
