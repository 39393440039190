<template>
    <div class="flex flex-wrap">
        <base-radio
            v-for="option in options"
            :key="option.value"
            :name="name"
            :checked="option.value === value"
            :value="option.value"
            :label="option.label"
            :disabled="disabled || !!option.disabled"
            :type="option.type"
            :class="itemClass"
            class="p-3"
            @change="change(option)"
        >
            <slot :option="option" />
        </base-radio>
    </div>
</template>

<script>
export default {
    name:    'BaseRadioGroup',
    props:   {
        value:     {
            type: [String, Number, Boolean],
        },
        options:   {
            type: Array,
        },
        name:      {
            type: String,
        },
        disabled:  {
            type: Boolean,
        },
        itemClass: {
            type: String,
        },
    },
    methods: {
        change(option) {
            if (this.disabled) return;

            this.$emit('input', option.value);
            this.$emit('change', option.value, option);
        },
    },
};
</script>
