/**
 * Format currency based on Intl.NumberFormat
 * @link https://developer.mozilla.org/ru/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat
 *
 * @param {Number} value
 * @param {Number} precision
 * @param {String} currency
 * @param {String} locale
 */
const formatCurrency = (
    value,
    precision = 2,
    currency = 'USD',
    locale = 'en-EN',
) => {
    if (value === null || value === undefined) return value;

    return new Intl.NumberFormat(locale, { style: 'currency', minimumFractionDigits: precision, currency }).format(value);
};

export default formatCurrency;
