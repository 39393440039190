export default ({ router }, moduleName, moduleRouter) => {
    if (!moduleRouter) return;

    moduleRouter.forEach((route) => {
        if (route.parent) {
            router.addRoute(route.parent, { ...route });
            return;
        }

        router.addRoute(route);
    });
};
