import { isArray, round } from 'lodash';

/**
 * @param {Number} value
 * @param {Object} discount
 * @param {Number} precision
 */
const calculateDiscount = (value, discount, precision) => {
    if (!discount.value) {
        return 0;
    }

    switch (discount.type) {
        case 'percentage':
            return round((value * discount.value) / 100, precision);
        case 'amount':
            return discount.value;
        default:
            return 0;
    }
};

/**
 * @param {Number} value
 * @param {Array|Object} discount
 * @param {Number} precision
 * @param {Boolean} full
 *
 * @returns {Number|Object}
 */
const formatDiscount = (
    value,
    discount,
    precision = 0,
    full = false,
) => {
    const items = isArray(discount) ? discount : [discount];
    let resultValue = value;

    items.map((item) => {
        const amount = calculateDiscount(resultValue, item, precision);

        item.amount = amount;

        resultValue = Math.max(0, round(resultValue - amount));

        return item;
    });

    if (!full) {
        return resultValue;
    }

    return {
        value: resultValue,
        items: discount,
    };
};

export default formatDiscount;
