<script>
export default {
    name: 'ServicePlanFeatureRun',
};
</script>

<script setup>
import { computed, toRef } from 'vue';
import i18n from '@/plugins/i18n';
import formatNumber from '@/plugins/filters/format-number';
import { FIELD, RUN_SCOPE } from '@/components/payments/components/service-plan/components/features/constants';
import useFeature from '@/components/payments/components/service-plan/components/features/composables/use-feature';
import ServicePlanFeatureElementsValue from '@/components/payments/components/service-plan/components/features/components/elements/value';

const props = defineProps({
    feature: {
        type:     Object,
        required: true,
    },
});

const { value, scope, used } = useFeature(toRef(props, 'feature'));

const formatted = computed(() => {
    if (scope.value === RUN_SCOPE.DEMO) return i18n.t('payment.servicePlan.feature.run.demo');
    if (!used.value) return i18n.tc('payment.servicePlan.feature.run.count', value.value, { count: formatNumber(value.value, true) });

    const available = value.value - used.value;

    return i18n.tc('payment.servicePlan.feature.run.available', available, {
        available: formatNumber(available, true),
        count:     formatNumber(value.value, true),
    });
});
</script>

<template>
    <service-plan-feature-elements-value
        :name="feature[FIELD.NAME]"
        :value="formatted"
    />
</template>
