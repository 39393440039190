import formatDate from '@/plugins/filters/format-date';

/**
 * Format Time base on Intl.DateTimeFormat
 *
 * @link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat
 *
 * @param {String} value
 * @param {String} locale
 *
 * @returns {String|null}
 */
const formatTime = (value, locale = undefined) => {
    if (!value) {
        return null;
    }

    return formatDate(`2020-01-01T${value}`, {
        hour:   'numeric',
        minute: 'numeric',
    }, locale);
};

export default formatTime;
