<template>
    <div class="relative mt-4">
        <label
            class="relative mb-0 block font-bold text-science-blue-900"
            @click="labelClick"
        >
            <span
                v-if="label"
                class="mb-1 flex"
            >
                <span class="mr-1 flex items-center pr-1 pl-4 text-sm">
                    <span class="flex flex-wrap items-center">
                        <span class="mr-1" v-html="label" />
                        <span v-if="showNotRequiredIdentifier && !required" class="mr-1">
                            ({{ $t('optional') }})
                        </span>
                    </span>
                    <authorization-field-info
                        v-if="hintText"
                        :text="hintText"
                        :faq-link="hintFaqLink"
                        :video-link="hintVideo"
                    />
                </span>
                <span
                    v-if="showRequired && required"
                    class="text-red-500"
                >*</span>
            </span>
            <base-tooltip
                v-if="description"
                placement="top"
                :content="description"
            >
                <base-icon
                    class="mx-1 text-secondary-web-light-blue"
                    name="question-circle"
                    prefix="far"
                />
            </base-tooltip>
            <base-alert
                v-if="notice"
                class="font-normal mb-2"
                icon
                :content="notice"
            />
            <slot name="field" />
            <span
                v-if="note"
                class="block pt-1"
            >
                <span
                    class="text-xs text-science-blue-900"
                    :class="{ 'break-words block h-4 overflow-hidden': breakNote }"
                    :data-test="dataTest('note')"
                >
                    {{ decodeURIComponent(note) }}
                </span>
            </span>
        </label>
        <fieldError
            v-if="showError && !!errorMessage"
            :error-text="errorMessage"
        />
    </div>
</template>

<script>
import AuthorizationFieldInfo from '@/components/authorization-field-info/authorization-field-info';
import fieldError from '@/components/field-error/field-error';

export default {
    name:       'AuthorizationFieldWrapper',
    components: {
        AuthorizationFieldInfo,
        fieldError,
    },
    props:      {
        label:                     {
            type:    [String, Boolean],
            default: false,
        },
        description:               {
            type:    [String, Boolean],
            default: false,
        },
        notice:                    {
            type: String,
        },
        required:                  {
            type:    Boolean,
            default: false,
        },
        note:                      {
            type:    [String, Boolean],
            default: false,
        },
        prevent:                   {
            type:    Boolean,
            default: false,
        },
        showError:                 {
            type:    Boolean,
            default: false,
        },
        errorMessage:              {
            type:    String,
            default: '',
        },
        breakNote:                 {
            type:    Boolean,
            default: false,
        },
        showRequired:              {
            type:    Boolean,
            default: false,
        },
        showHint:                  {
            type:    Boolean,
            default: false,
        },
        hintText:                  {
            type:    String,
            default: '',
        },
        hintFaqLink:               {
            type: String,
        },
        hintVideo:                 {
            type: [String],
        },
        showMoreButton:            {
            type:    Boolean,
            default: false,
        },
        showNotRequiredIdentifier: {
            type:    Boolean,
            default: false,
        },
    },
    data() {
        return {
            showHintText: this.showHint,
        };
    },
    methods: {
        labelClick(e) {
            if (this.prevent) {
                e.preventDefault();
            }
        },
        moreClick() {
            this.showHintText = !this.showHintText;
            return this.showHintText;
        },
    },
};
</script>
