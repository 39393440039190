export function exists(tag, src) {
    return !!document.querySelectorAll(`${tag}[src="${src}"]`).length;
}

/**
 * Append built script to head
 *
 * @param tag
 * @param attrs
 * @param onload
 */
export function append(tag, attrs = {}, onload = undefined) {
    if (exists(tag, attrs.src)) return onload?.();

    const newScript = document.createElement(tag);

    Object.entries(attrs).forEach(([name, value]) => {
        newScript.setAttribute(name.toString(), value.toString());
    });

    newScript.onload = () => {
        onload?.();
    };

    document.getElementsByTagName('head')[0].appendChild(newScript);
}

export function script(src, async = true, attrs = {}, onload = undefined) {
    append('script', { src, async, ...attrs }, onload);
}

export function style(href, attrs = {}, onload = undefined) {
    append('link', {
        href,
        rel:  'stylesheet',
        type: 'text/css',
        ...attrs,
    }, onload);
}
