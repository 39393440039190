<template>
    <div v-if="sectionCondition" class="flex w-full flex-col">
        <router-link
            class="flex flex-1 rounded-md font-semibold uppercase tracking-wide hover:bg-black/30 hover:text-white focus:text-white focus:outline-none"
            :class="linkClass"
            :to="{ name: sectionRoute }"
            @click.native="navigateSection"
        >
            <div
                class="flex w-full items-center px-4 py-2 space-x-4"
                :title="collapsed ? sectionLabel : undefined"
            >
                <div class="flex h-6 w-6 shrink-0 items-center justify-center">
                    <base-icon :name="sectionIcon.name" :prefix="sectionIcon.prefix" />
                </div>
                <span class="overflow-hidden whitespace-nowrap leading-none">
                    {{ sectionLabel }}
                </span>
            </div>
        </router-link>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'SidebarSection',
    data() {
        return {
            sectionName:  undefined,
            sectionRoute: undefined,
            sectionLabel: undefined,
            sectionIcon:  {
                name:   undefined,
                prefix: undefined,
            },
        };
    },
    computed: {
        ...mapState('sidebar', {
            collapsed: 'collapsed',
        }),
        sectionCondition() {
            return true;
        },
        sectionActive() {
            return this.$route.matched.some(route => route.meta.section === this.sectionName);
        },
        linkClass() {
            return {
                'text-white bg-black/40 shadow-inner shadow-black/30': this.sectionActive,
                'text-gray-300':                                       !this.sectionActive,
            };
        },
    },
    methods:  {
        navigateSection() {
            this.$emit('navigate');
        },
    },
};
</script>
