<script>
export default {
    name: 'BaseRadio',
};
</script>

<script setup>
import { computed } from 'vue';
import { TYPE } from '@/components/base-components/components/radio/index';

const props = defineProps({
    checked:  {
        type: Boolean,
    },
    value:    {
        type:    [String, Number, Boolean],
        default: '',
    },
    label:    {
        type:    [String, Number, Boolean],
        default: '',
    },
    type:     {
        type:      String,
        default:   TYPE.PRIMARY,
        validator: value => Object.values(TYPE).includes(value),
    },
    name:     {
        type:    String,
        default: '',
    },
    disabled: {
        type: Boolean,
    },
});

const emit = defineEmits({
    change: null,
});

function click() {
    if (props.disabled) return;

    emit('change', props.value);
}

const typeClass = computed(() => ({
    [TYPE.PRIMARY]:       'checked:bg-primary-600 checked:border-primary-600',
    [TYPE.SECONDARY]:     'checked:bg-secondary-950 checked:border-secondary-950',
    [TYPE.PRIMARY_OLD]:   'checked:bg-science-blue-500 checked:border-science-blue-500',
    [TYPE.SECONDARY_OLD]: 'checked:bg-science-blue-700 checked:border-science-blue-700',
    [TYPE.SUCCESS]:       'checked:bg-emerald-600 checked:border-emerald-600',
    [TYPE.WARNING]:       'checked:bg-orange-500 checked:border-orange-500',
    [TYPE.DANGER]:        'checked:bg-red-600 checked:border-red-600',
    [TYPE.INFO]:          'checked:bg-gray-500 checked:border-gray-500',
}[props.type]));

const textTypeClass = computed(() => {
    if (!props.checked) return 'text-gray-500';

    return {
        [TYPE.PRIMARY]:       'text-primary-600',
        [TYPE.SECONDARY]:     'text-secondary-600',
        [TYPE.PRIMARY_OLD]:   'text-science-blue-700',
        [TYPE.SECONDARY_OLD]: 'text-science-blue-900',
        [TYPE.SUCCESS]:       'text-emerald-600',
        [TYPE.WARNING]:       'text-orange-500',
        [TYPE.DANGER]:        'text-red-600',
        [TYPE.INFO]:          'text-gray-500',
    }[props.type];
});
</script>

<template>
    <label
        class="inline-flex select-none items-center gap-x-2 text-sm font-medium leading-none text-gray-500 group"
        :class="[
            disabled ? 'cursor-not-allowed' : 'cursor-pointer',
            {
                'opacity-50': disabled,
            },
            textTypeClass,
        ]"
        @click.stop.prevent="click"
    >
        <input
            class="pointer-events-none m-0 grid h-5 w-5 appearance-none place-content-center rounded-full border bg-transparent bg-white transition-colors before:content-[' '] before:h-2 before:w-2 before:scale-0 before:rounded-full before:bg-white before:transition-transform checked:before:scale-100"
            :class="[
                typeClass,
                {
                    'group-hover:border-gray-400': !checked && !disabled
                }
            ]"
            type="radio"
            :name="name"
            :checked="checked"
            :disabled="disabled"
        >
        <span class="block">
            <slot>{{ label }}</slot>
        </span>
    </label>
</template>
