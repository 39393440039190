const prefix = 'fac';
const iconName = 'brush';
const width = 32;
const height = 32;
const ligatures = [];
const unicode = null;
const svgPathData = 'M10.157 27.283l1.98-1.3-.048-.211c-1.254 0-2.508.017-3.761-.01a2.2 2.2 0 0 1-1.033-.233c-2.177-1.285-4.333-2.6-6.719-4.047a26.386 26.386 0 0 0 15.821-7.6l15.291 8.61a30.171 30.171 0 0 1-6.861 11.701 1.143 1.143 0 0 1-1.542.227c-1.476-.739-2.989-1.4-4.513-2.111l4.4-5.58-.193-.2c-.972.477-1.937.968-2.918 1.425-.5.233-1.021.418-1.54.6-4.047 1.439-4.775 1.33-8.364-1.271zM30.703 12.505c3.262 2.448 3.377 2.857 1.862 6.79l-14.068-7.949c1.89-2.487 3.036-4.31 7.124-1.607l4.125-5.788c.642-.9 1.28-1.8 1.923-2.706.808-1.132 1.724-1.453 2.625-.927s1.079 1.461.517 2.747a7842.23 7842.23 0 0 1-3.713 8.482c-.141.322-.271.646-.395.958zM1.938 13.062a2.859 2.859 0 0 1 2.847-2.872 2.889 2.889 0 0 1 2.867 2.963 2.836 2.836 0 0 1-2.909 2.791 2.801 2.801 0 0 1-2.805-2.882zM1.927 5.877a1.463 1.463 0 0 1 1.41 1.444 1.449 1.449 0 0 1-2.473.951 1.413 1.413 0 0 1-.4-1.012 1.422 1.422 0 0 1 1.463-1.383z';

export const definition = {
    prefix,
    iconName,
    icon: [
        width,
        height,
        ligatures,
        unicode,
        svgPathData,
    ],
};

export { definition as faBrush };
