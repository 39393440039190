window.getCookie = function(name) {
    var matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
};

window.setCookie = function(name, value, options) {
    options = options || {};

    var expires = options.expires;
    var d = null;

    if (typeof expires === "number" && expires) {
        d = new Date();
        d.setTime(d.getTime() + expires * 1000);
        expires = options.expires = d;
    }

    if (!expires) {
        d = new Date();
        d.setTime(d.getTime() + 60 * 60 * 24 * 1000 * 1000);
        expires = options.expires = d;
    }

    if (expires && expires.toUTCString) {
        options.expires = expires.toUTCString();
    }

    value = encodeURIComponent(value);

    var updatedCookie = name + "=" + value;

    if (!options.hasOwnProperty('domain')) {
        options.domain = window.config.cookieDomain;
    }

    for (var propName in options) {
        if (!options.hasOwnProperty(propName)) {
            continue;
        }

        updatedCookie += "; " + propName;
        var propValue = options[propName];
        if (propValue !== true) {
            updatedCookie += "=" + propValue;
        }
    }

    document.cookie = updatedCookie + ";secure";
};

window.deleteCookie = function(name) {
    window.setCookie(name, "", {
        expires: -1
    });
};

window.showDebug = function() {
    return window.wizard ? window.wizard.getUserRole() === 'admin' : true;
};

window.debug = function(message, options) {
    if (!message) {
        return;
    }

    var info = null;

    if (Array.isArray(message)) {
        console.log(message);

        return;
    }

    if (message.hasOwnProperty("stack")) {
        info = message.stack.split('\n')[1].match(/at\s+([^\s]+)\s+\((.+):(\d+:\d+)/);
    }

    if (window.showDebug() !== true) {
        return;
    }

    if (!options) {
        console.log(message);

        return;
    }

    if (options.hasOwnProperty("warning")) {
        if (typeof message === 'object') {
            console.log('%c WARNING', 'color: #EF6C00;');
            console.dir(message);

            return;
        }

        console.log('%c' + message, 'color: #EF6C00;');

        return;
    }

    if (options.hasOwnProperty("error") && info) {
        if (typeof Bugsnag !== 'undefined') {
            Bugsnag.notifyException(message);
        }

        console.log('%c' + message + (info ? '\n at ' + info[2] + ':' + info[3] + '\n function: ' + info[1] : ''), 'color: #f44336;');
    }
};
