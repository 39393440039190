export const PLACEMENTS = {
    TOP:    'top',
    BOTTOM: 'bottom',
    RIGHT:  'right',
    LEFT:   'left',

    TOP_START:    'top-start',
    BOTTOM_START: 'bottom-start',
    RIGHT_START:  'right-start',
    LEFT_START:   'left-start',

    TOP_END:    'top-end',
    BOTTOM_END: 'bottom-end',
    RIGHT_END:  'right-end',
    LEFT_END:   'left-end',
};

export const OPPOSITE_PLACEMENTS = {
    [PLACEMENTS.TOP]:    PLACEMENTS.BOTTOM,
    [PLACEMENTS.BOTTOM]: PLACEMENTS.TOP,
    [PLACEMENTS.RIGHT]:  PLACEMENTS.LEFT,
    [PLACEMENTS.LEFT]:   PLACEMENTS.RIGHT,
};

export function isValid(placement) {
    return Object.values(PLACEMENTS).includes(placement);
}
