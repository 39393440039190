<template>
    <div class="flex min-h-full w-full flex-1 flex-col items-center py-6">
        <div class="mx-auto flex w-full max-w-7xl flex-col items-center px-4 sm:px-6 md:px-8 lg:space-y-10">
            <div class="hidden text-center text-3xl font-bold lg:mt-10 lg:block">
                {{ title }}
            </div>
            <div class="grid grid-cols-1 gap-8 lg:grid-cols-3">
                <block-container
                    v-for="card in cards"
                    :key="card.title"
                    content-class="flex flex-col justify-between space-y-3"
                >
                    <div class="space-y-2">
                        <div class="text-lg font-black">
                            {{ card.title }}
                        </div>
                        <div>
                            {{ card.message }}
                        </div>
                    </div>
                    <base-button
                        class="self-start"
                        type="primary"
                        round
                        @click="goToUrl(card.url)"
                    >
                        {{ $t('read more') }}
                    </base-button>
                </block-container>
            </div>
        </div>
    </div>
</template>

<script>
import BlockContainer from '@/components/block-container';

export default {
    name:       'HelpPage',
    components: { BlockContainer },
    data() {
        return {
            title: this.$t('header breadcrumb help'),
            cards: [
                {
                    title:   this.$t('help guided migration'),
                    message: this.$t('help guided migration message'),
                    url:     this.$filters.serviceLink('guided-migration'),
                },
                {
                    title:   this.$t('help hiw'),
                    message: this.$t('help hiw message'),
                    url:     this.$filters.serviceLink('how-it-works'),
                },
                {
                    title:   this.$t('help knowledge'),
                    message: this.$t('help knowledge message'),
                    url:     this.$filters.serviceLink('support'),
                },
            ],
        };
    },
    methods: {
        goToUrl(url) {
            window.open(url, '_blank');
        },
    },
};
</script>
