export default function hideLoader(permanent = false) {
    const loader = document.getElementById('loader');

    loader.classList.add('opacity-0');

    return new Promise((resolve) => {
        setTimeout(() => {
            if (loader) {
                loader.parentElement.removeChild(loader);
            }
        }, permanent ? 0 : 1500);
        resolve(true);
    });
}
