<script>
export default {
    name: 'BaseJsonForm',
};
</script>

<script setup>
import useVuelidate from '@vuelidate/core';
import { helpers } from '@vuelidate/validators';
import { computed, del, nextTick, provide, ref, watch } from 'vue';
import {
    baseJsonFormDisabledKey,
    baseJsonFormLinksKey,
    baseJsonFormPropertiesKey,
    baseJsonFormValidationScopeKey,
    getValidationRules,
} from '@/components/base-components/components/json-form/index';
import BaseJsonFormGroup from '@/components/base-components/components/json-form/base-json-form-group';
import BaseJsonFormItem from '@/components/base-components/components/json-form/base-json-form-item';

const props = defineProps({
    value:             {
        type: [Array, Object],
    },
    schema:            {
        type:     Object,
        required: true,
    },
    disabled:          {
        type: Boolean,
    },
    wrapClass:         {
        type:    String,
        default: 'flex flex-col gap-y-3',
    },
    itemClass:         {
        type: String,
    },
    links:             {
        type: Object,
    },
    errors:            {
        type:    Object,
        default: () => ({}),
    },
    noValidation:      {
        type: Boolean,
    },
    noGroupValidation: {
        type: Boolean,
    },
    noTitles:          {
        type: Boolean,
    },
});

const emit = defineEmits({
    input:    null,
    validate: null,
});

provide(baseJsonFormDisabledKey, computed(() => props.disabled));
provide(baseJsonFormLinksKey, computed(() => props.links));

const properties = computed(() => props.schema?.properties ?? {});
provide(baseJsonFormPropertiesKey, properties);

const required = computed(() => props.schema?.required ?? []);

const model = ref(props.value ?? {});

watch(() => props.value, () => {
    model.value = props.value;
});

function isGroup(propertyData) {
    return propertyData.type === 'array' && !propertyData.items.enum;
}

function isGroupProperty(property) {
    return isGroup(properties.value[property]);
}

function buildValidationRules(propertiesSchema = {}, requiredList = []) {
    const validations = Object.entries(propertiesSchema).map(([property, propertySchema]) => {
        const validators = getValidationRules(property, propertySchema, requiredList.includes(property));

        if (isGroup(propertySchema) && propertySchema.items.type !== 'object' && !props.noGroupValidation) {
            const nestedValidators = buildValidationRules(propertySchema.items.properties, propertySchema.items.required);

            if (Object.values(nestedValidators).length) {
                validators.$each = helpers.forEach(nestedValidators);
            }
        }

        return [property, validators];
    });

    return Object.fromEntries(validations);
}

const v$ = useVuelidate(
    computed(() => {
        if (props.disabled || props.noValidation) return { model: {} };

        return {
            model: buildValidationRules(properties.value, required.value),
        };
    }),
    { model },
    { $scope: baseJsonFormValidationScopeKey },
);

const invalid = computed(() => v$.value.$invalid);

watch(() => invalid.value, async (invalid) => {
    await nextTick();

    emit('validate', !invalid);
}, { immediate: true });

function itemInput(property, value) {
    if (props.errors[property] && !isGroupProperty(property)) {
        del(props.errors, property);
    }

    if (props.links && Object.keys(props.links).length) {
        Object.entries(props.links).forEach(([field, { on, callback, condition }]) => {
            const onFields = Array.isArray(on) ? on : [on];

            if (!properties.value[field] || !onFields.includes(property)) return;
            if (!(condition?.(properties.value[property], properties.value[field]) ?? true)) return;

            if (props.errors[field]) {
                del(props.errors, field);
            }

            callback?.(value, model.value);
        });
    }

    emit('input', model.value, v$.value.$invalid);
}

defineExpose({
    invalid,
});
</script>

<template>
    <div :class="wrapClass">
        <p
            v-if="schema.description"
            class="text-sm font-semibold"
            v-text="schema.description"
        />
        <template v-for="(propertyData, property) in properties">
            <base-json-form-group
                v-if="isGroup(propertyData)"
                v-model="model[property]"
                :key="property"
                :name="property"
                :data="propertyData"
                :schema="propertyData.items"
                :links="links"
                :errors="errors[property]"
                :validations="v$.model[property]"
                :required="disabled ? false : required.includes(property)"
                @input="(itemValue) => itemInput(property, itemValue)"
            />
            <base-json-form-item
                v-else
                v-model="model[property]"
                :key="property"
                :class="itemClass"
                :name="property"
                :data="propertyData"
                :errors="errors[property]"
                :validations="v$.model[property]"
                :required="disabled ? false : required.includes(property)"
                :show-title="!noTitles"
                @input="(itemValue) => itemInput(property, itemValue)"
            />
        </template>
    </div>
</template>
