<template>
    <base-breadcrumb
        class="gap-x-4 text-lg font-semibold text-gray-500"
        :divider="{ name: 'chevron-right', prefix: 'fas', class: 'w-4 h-4' }"
        :items="breadcrumbs"
    />
</template>

<script>
import { mapState } from 'vuex';

export default {
    name:     'HeaderBreadcrumbs',
    computed: {
        ...mapState('breadcrumbs', {
            breadcrumbs: 'items',
        }),
    },
};
</script>
