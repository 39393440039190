/**
 * @param seconds
 *
 * @returns {string}
 */
const secondsToTime = (seconds) => {
    const format = value => Math.round(value).toString().padStart(2, '0');

    const hoursPart = format(seconds / 3600);
    const minutesPart = format((seconds / 60) % 60);
    const secondsPart = format(seconds % 60);

    return `${hoursPart}:${minutesPart}:${secondsPart}`;
};

export default secondsToTime;
