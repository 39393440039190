import { toValue } from '@vueuse/core';
import { TYPES } from '@/storage/serializer';
import Storage from '@/storage/storage';

export { TYPES } from '@/storage/serializer';

const localStorage = new Storage();

export const useStorage = (key, storage = null) => {
    const storageInstance = storage ? new Storage(storage) : localStorage;

    return {
        get(defaults = null, type = TYPES.ANY) {
            return storageInstance.get(toValue(key), defaults, type);
        },
        set(value, ttlMs = 0) {
            return storageInstance.set(toValue(key), value, ttlMs);
        },
        remove() {
            return storageInstance.remove(toValue(key));
        },
    };
};

export default localStorage;
