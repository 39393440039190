import Vue from 'vue';
import BaseAlert from '@/components/base-components/components/base-alert';
import BaseBadge from '@/components/base-components/components/badge/base-badge';
import BaseBanner from '@/components/base-components/components/base-banner';
import BaseBreadcrumb from '@/components/base-components/components/breadcrumb/base-breadcrumb';
import BaseBreadcrumbItem from '@/components/base-components/components/breadcrumb/base-breadcrumb-item';
import BaseButton from '@/components/base-components/components/button/base-button';
import BaseButtonGroup from '@/components/base-components/components/button/base-button-group';
import BaseChart from '@/components/base-components/components/base-chart';
import BaseCheckbox from '@/components/base-components/components/checkbox/base-checkbox';
import BaseColorPalette from '@/components/base-components/components/color/base-color-palette';
import BaseColorPaletteItem from '@/components/base-components/components/color/base-color-palette-item';
import BaseCodeEditor from '@/components/base-components/components/code-editor/base-code-editor';
import BaseCollapse from '@/components/base-components/components/collapse/base-collapse';
import BaseCollapseItem from '@/components/base-components/components/collapse/base-collapse-item';
import BaseDivider from '@/components/base-components/components/base-divider';
import BaseDraggable from '@/components/base-components/components/base-draggable';
import BaseDrawer from '@/components/base-components/components/base-drawer';
import BaseDropdown from '@/components/base-components/components/dropdown/base-dropdown';
import BaseDropdownItem from '@/components/base-components/components/dropdown/base-dropdown-item';
import BaseIcon from '@/components/base-components/components/base-icon';
import BaseInfo from '@/components/base-components/components/base-info';
import BaseInput from '@/components/base-components/components/input/base-input';
import BaseInputArrayAsString from '@/components/base-components/components/input/base-input-array-as-string';
import BaseInputNumber from '@/components/base-components/components/input/base-input-number';
import BaseJsonEditor from '@/components/base-components/components/code-editor/json/base-json-editor';
import BaseJsonForm from '@/components/base-components/components/json-form/base-json-form';
import BasePinCode from '@/components/base-components/components/pin-code/base-pin-code';
import BaseMessage from '@/components/base-components/components/message/base-message';
import BaseModal from '@/components/base-components/components/base-modal';
import BaseNotification from '@/components/base-components/components/notification/base-notification';
import BaseNumberAnimate from '@/components/base-components/components/base-number-animate';
import BaseOverlay from '@/components/base-components/components/base-overlay';
import BasePagination from '@/components/base-components/components/pagination/base-pagination';
import BasePopover from '@/components/base-components/components/base-popover';
import BaseProgress from '@/components/base-components/components/base-progress';
import BaseRadio from '@/components/base-components/components/radio/base-radio';
import BaseRadioGroup from '@/components/base-components/components/radio/base-radio-group';
import BaseRate from '@/components/base-components/components/base-rate';
import BaseScroll from '@/components/base-components/components/scroll/base-scroll';
import BaseSelect from '@/components/base-components/components/select/base-select';
import BaseSelectOption from '@/components/base-components/components/select/base-select-option';
import BaseSpinner from '@/components/base-components/components/base-spinner';
import BaseSteps from '@/components/base-components/components/base-steps';
import BaseSwitcher from '@/components/base-components/components/base-switcher';
import BaseTab from '@/components/base-components/components/tabs/base-tab';
import BaseTabs from '@/components/base-components/components/tabs/base-tabs';
import BaseToggle from '@/components/base-components/components/toggle/base-toggle';
import BaseTag from '@/components/base-components/components/tag/base-tag';
import BaseTooltip from '@/components/base-components/components/tooltip/base-tooltip';
import BaseVideo from '@/components/base-components/components/base-video';

const components = {
    BaseAlert,
    BaseBadge,
    BaseBanner,
    BaseBreadcrumb,
    BaseBreadcrumbItem,
    BaseButton,
    BaseButtonGroup,
    BaseChart,
    BaseCheckbox,
    BaseColorPalette,
    BaseColorPaletteItem,
    BaseCodeEditor,
    BaseCollapse,
    BaseCollapseItem,
    BaseDivider,
    BaseDraggable,
    BaseDrawer,
    BaseDropdown,
    BaseDropdownItem,
    BaseIcon,
    BaseInfo,
    BaseInput,
    BaseInputArrayAsString,
    BaseInputNumber,
    BaseJsonEditor,
    BaseJsonForm,
    BasePinCode,
    BaseMessage,
    BaseModal,
    BaseNotification,
    BaseNumberAnimate,
    BaseOverlay,
    BasePagination,
    BasePopover,
    BaseProgress,
    BaseRadio,
    BaseRadioGroup,
    BaseRate,
    BaseSelect,
    BaseSelectOption,
    BaseScroll,
    BaseSpinner,
    BaseSteps,
    BaseSwitcher,
    BaseTab,
    BaseTabs,
    BaseTag,
    BaseToggle,
    BaseTooltip,
    BaseVideo,
};

Object.keys(components).forEach(name => Vue.component(name, components[name]));
