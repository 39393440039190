export default {
    namespaced: true,
    state:      {
        items: [],
    },
    mutations:  {
        SET_ITEMS(state, items) {
            state.items = items;
        },
    },
    actions:    {
        SET({ commit }, items) {
            commit('SET_ITEMS', items.filter(item => !!item.label || !!item.component));
        },
    },
};
