import { get, trim } from 'lodash';
import showMessage from '@/components/base-components/components/message/message';

function addClass(el, cls) {
    const list = el.className.split(' ');

    if (list.indexOf(cls) === -1) {
        el.className = el.className ? `${el.className} ${cls}` : cls;
    }
}

function removeClass(el, cls) {
    el.className = trim(el.className.replace(cls, ' '));
}

function addListeners(Vue, el) {
    el.activateCtrlKeyHandler = (event) => {
        if (event.ctrlKey || event.metaKey) {
            addClass(el, 'underline');
        } else {
            removeClass(el, 'underline');
        }
    };
    el.copyToClipboardHandler = (event) => {
        if (!event.ctrlKey && !event.metaKey) return;

        Vue.prototype.$copyToClipboard(
            el.copyDirectiveBinding.value,
            !get(el.copyDirectiveBinding.modifiers, 'noMessage', false),
        );
        event.stopPropagation();
        event.preventDefault();
    };

    el.addEventListener('click', el.copyToClipboardHandler);
    window.addEventListener('keydown', el.activateCtrlKeyHandler);
    window.addEventListener('keyup', el.activateCtrlKeyHandler);
    window.addEventListener('blur', el.activateCtrlKeyHandler);
}

function removeListeners(el) {
    el.removeEventListener('click', el.copyToClipboardHandler);
    window.removeEventListener('keydown', el.activateCtrlKeyHandler);
    window.removeEventListener('keyup', el.activateCtrlKeyHandler);
    window.addEventListener('blur', el.activateCtrlKeyHandler);
}

function toastMessage(show, method, message) {
    if (!show || !showMessage[method]) return;

    showMessage[method]({
        html:   true,
        message,
    });
}

export function copyToClipboard(value, toast = true) {
    if (!navigator.clipboard) {
        toastMessage(toast, 'error', 'Copy to clipboard is not working in your browser!');
        return;
    }

    const formatted = value.length > 30 ? `${value.slice(0, 30)}...` : value;

    navigator.clipboard.writeText(value).then(() => {
        toastMessage(toast, 'success', `<b>${formatted}</b> copied to clipboard`);
    }).catch(() => {
        toastMessage(toast, 'warning', `Can't copy <b>${formatted}</b> to clipboard!`);
    });
}

const CopyToClipboard = {
    install(Vue) {
        Vue.prototype.$copyToClipboard = copyToClipboard;

        Vue.directive('copy', {
            bind(el, binding) {
                el.copyDirectiveBinding = binding;

                addListeners(Vue, el);
            },
            update(el, binding) {
                el.copyDirectiveBinding = binding;
            },
            unbind(el) {
                removeListeners(el);
            },
        });
    },
};

export default CopyToClipboard;
