import { has } from 'lodash';

export default {
    namespaced: true,
    state:      {
        data: {},
    },
    getters:    {
        getPlatform(state) {
            return (direction, type) => state.data[direction].list[type];
        },
    },
    mutations:  {
        SET_PLATFORM_DATA(state, payload) {
            if (has(state.data, payload.prefix)) {
                state.data[payload.prefix] = { ...state.data[payload.prefix], ...payload.data };
            } else {
                state.data[payload.prefix] = payload.data;
            }
        },
        CLEAR(state, prefix) {
            state.data[prefix] = {};
        },
    },
};
