<template>
    <base-modal
        size="medium"
        :value="showPopup"
        :close-on-click-overlay="false"
        :show-close="!loading"
        @close="closePopup"
    >
        <template #header>
            <div class="w-full text-center text-2xl font-black">
                {{ info.title }}
            </div>
        </template>

        <div class="flex w-full flex-col items-center justify-center gap-y-3 text-sm" v-loader="loading">
            <base-alert icon>
                <span v-html="info.message" />
            </base-alert>

            <div class="flex w-full flex-col items-center gap-y-4">
                <div class="grid w-full grid-cols-2 gap-x-2 gap-y-2 px-4 py-2 md:gap-x-6">
                    <div
                        v-for="entity in selectedEntities"
                        :key="entity.id"
                        class="flex items-center justify-between space-x-3"
                    >
                        <div class="flex gap-2 truncate">
                            <div class="truncate font-semibold text-science-blue-900">
                                {{ entity.crmEntity.label }}
                            </div>
                            <crm-entity-cache-slow-tooltip v-if="entity.crmEntity.isCacheSlow" :crm="platform.name" />
                        </div>
                        <div class="shrink-0">
                            {{ entityCount(entity.count) | formatNumber }}
                        </div>
                    </div>
                </div>

                <div class="flex items-center self-start px-2 text-base font-semibold space-x-2">
                    <span>{{ $t('backup create popup records') }}:</span>
                    <span class="text-science-blue-900">{{ total | formatNumber }}</span>
                </div>
            </div>

            <div class="flex w-full flex-col gap-y-5">
                <base-alert
                    v-if="hasAlert"
                    icon
                >
                    <span class="text-left" v-html="showedAlert" />
                </base-alert>

                <div class="flex items-center justify-end gap-x-5">
                    <base-switcher
                        v-model="type"
                        :options="typeOptions"
                    >
                        <template #option="{ option }">
                            <backup-pricing-plan-requirement
                                v-if="option.disabled"
                                @click="closePopup"
                            >
                                <span
                                    v-text="option.label"
                                    class="text-sm text-center"
                                />
                            </backup-pricing-plan-requirement>
                        </template>
                    </base-switcher>

                    <base-button
                        type="primary"
                        round
                        :disabled="createDisabled"
                        @click="createInstance"
                    >
                        {{ $t('backup create popup start button') }}
                    </base-button>
                </div>
            </div>
        </div>
    </base-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { get, isEmpty } from 'lodash';
import notify from '@/components/base-components/components/notification/notify';
import { backupInstanceCreated } from '@/assets/util/event-constants';
import { backupEntityList, backupInstanceCreate } from '@/components/backup/api';
import { FEATURE, RUN_SCOPE } from '@/components/payments/components/service-plan/components/features/constants';
import backupEntities from '@/components/backup/mixins/backup-entities';
import featureBackupRecordMixin from '@/components/backup/mixins/features/feature-backup-record';
import CrmEntityCacheSlowTooltip from '@/components/crm/entity/components/cache-slow-tooltip';
import BackupPricingPlanRequirement from '@/components/backup/components/pricing/plan/requirement';
import { getFeatureScope, getFeatureUsed, getFeatureValue } from '@/components/payments/components/service-plan/components/features/utils';

export default {
    name:       'CreatePopup',
    components: {
        BackupPricingPlanRequirement,
        CrmEntityCacheSlowTooltip,
    },
    mixins:     [
        backupEntities,
        featureBackupRecordMixin,
    ],
    props:      {
        show: {
            type:    Boolean,
            default: false,
        },
    },
    data() {
        return {
            showPopup: this.show,
            loading:   false,
            type:      RUN_SCOPE.DEMO,
        };
    },
    computed: {
        ...mapGetters({
            platform:              'backup/getPlatform',
            isActiveAuthorization: 'backup/isAuthorized',
            isLocked:              'backup/isLocked',
            entities:              'backup/getEntities',
            backupPlan:            'backup/getPlan',
        }),
        typeOptions() {
            return [
                {
                    value: RUN_SCOPE.DEMO,
                    label: 'Sample',
                },
                {
                    value:    RUN_SCOPE.FULL,
                    label:    'Full',
                    disabled: this.fullDisabled,
                },
            ];
        },
        info() {
            return {
                [RUN_SCOPE.DEMO]: {
                    title:   this.$t('backup create demo popup title'),
                    message: this.$t('backup create demo popup message'),
                },
                [RUN_SCOPE.FULL]: {
                    title:   this.$t('backup create full popup title'),
                    message: this.$t('backup create full popup message'),
                },
            }[this.type];
        },
        isDemo() {
            return this.type === RUN_SCOPE.DEMO;
        },
        hasAlert() {
            return this.showedAlert && this.backupRecordLimit > 0;
        },
        showedAlert() {
            return this.demoAlert || this.recordLimitAlert || this.recordLimitEntityAlert;
        },
        demoAlert() {
            if (!this.isDemo) return undefined;

            return this.$t('backup create popup demo alert', { records: this.recordLimitEntity });
        },
        recordLimitEntity() {
            if (this.isDemo) return 100;

            return this.backupRecordLimitEntity;
        },
        formattedRecordLimit() {
            return this.$filters.formatNumber(this.backupRecordLimit, true);
        },
        recordLimitAlert() {
            if (this.isRecordLimitExceeded(this.entities) === false) return undefined;

            return this.$t('backup create popup record limit alert', { records: this.formattedRecordLimit });
        },
        recordLimitEntityAlert() {
            if (this.isRecordLimitEntityExceeded(this.entities) === false) return undefined;

            return this.$t('backup create popup record limit entity alert', { records: this.formattedRecordLimit });
        },
        selectedEntities() {
            return this.getSelectedEntities(this.entities);
        },
        total() {
            return this.getSelectedTotalRecords(this.entities, this.recordLimitEntity);
        },
        createDisabled() {
            return this.isLocked || !this.isActiveAuthorization || !this.selectedEntities.length || this.loading;
        },
        runFeature() {
            return this.backupPlan.options.backup.features[FEATURE.BACKUP_RUN];
        },
        runScope() {
            return getFeatureScope(this.runFeature);
        },
        runValue() {
            return getFeatureValue(this.runFeature);
        },
        runUsed() {
            return getFeatureUsed(this.runFeature) ?? 0;
        },
        availableRuns() {
            return (this.runValue - this.runUsed) > 0;
        },
        fullDisabled() {
            return this.runScope === RUN_SCOPE.DEMO || !this.availableRuns;
        },
    },
    watch:    {
        async show() {
            this.showPopup = this.show;

            if (this.show !== true || !isEmpty(this.entities)) return;

            this.loading = true;

            try {
                await this.setEntities(await backupEntityList(this.$route.params.backupId));
            } catch ({ error, message }) {
                notify.error({
                    title:    this.$t('request error notification'),
                    message,
                    duration: 7500,
                });

                throw error;
            } finally {
                this.loading = false;
            }
        },
    },
    methods:  {
        ...mapActions({
            addSocketInstance: 'backup/ADD_SOCKET_INSTANCE',
            setEntities:       'backup/SET_ENTITIES',
        }),
        closePopup() {
            this.showPopup = false;
            this.$emit('closePopup');
        },
        entityCount(count) {
            if (!this.recordLimitEntity || !this.isDemo) {
                return count;
            }

            return count > this.recordLimitEntity ? this.recordLimitEntity : count;
        },
        async createInstance() {
            this.loading = true;

            try {
                const instance = await backupInstanceCreate(this.$route.params.backupId, this.type);

                await this.addSocketInstance(instance);

                window.eventBus.$emit(backupInstanceCreated);

                this.$emit('created');
                this.closePopup();
            } catch ({ error, message, response }) {
                notify.error({
                    title:    this.$t('request error notification'),
                    message,
                    duration: 7500,
                });

                if (get(response, 'data.detail.code') === 400020010) {
                    this.closePopup();
                    await this.$router.push({ name: 'backupConnect', params: { backupId: this.$route.params.backupId } });
                }

                throw error;
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
