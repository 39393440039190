import qs from 'qs';

export const hashToObject = hash => qs.parse(hash.startsWith('#') ? hash.slice(1) : hash);

export const objectToHash = obj => `#${qs.stringify(obj)}`;

export const getHashParam = (hash, key) => hashToObject(hash)[key] ?? undefined;

export const setHashParam = (hash, key, value) => objectToHash({ ...hashToObject(hash), ...{ [key]: value } });

export const removeHashParam = (hash, key) => {
    const { [key]: _, ...obj } = hashToObject(hash);

    return objectToHash(obj);
};

export const getWindowHashParam = key => getHashParam(window.location.hash, key);

export const setWindowHashParam = (key, value) => {
    window.location.hash = setHashParam(window.location.hash, key, value);
};

export const removeWindowHashParam = (key) => {
    window.location.hash = removeHashParam(window.location.hash, key);
};

export const setUrlHashParam = (url, key, value) => {
    const objectUrl = new URL(url, window.location.origin);

    objectUrl.hash = setHashParam(objectUrl.hash, key, value);

    return objectUrl.toString().replace(window.location.origin, '');
};
