<template>
    <div
        class="flex flex-col"
        :class="{ 'divide-y divide-gray-200': divide }"
    >
        <slot />
    </div>
</template>

<script>
export default {
    name:  'BaseCollapse',
    props: {
        value:     {
            type: [Array, String, Number],
            default() {
                return [];
            },
        },
        accordion: Boolean,
        divide:    Boolean,
    },
    data() {
        return {
            activeNames: [].concat(this.value),
        };
    },
    provide() {
        return {
            collapse: this,
        };
    },
    watch:   {
        value(value) {
            this.activeNames = [].concat(value);
        },
    },
    methods: {
        setActiveNames(value) {
            this.activeNames = [].concat(value);

            const item = this.accordion ? this.activeNames[0] : this.activeNames;

            this.$emit('input', item);
            this.$emit('change', item);
        },
        changeActive(name) {
            if (this.accordion) {
                this.setActiveNames(this.activeNames[0] === name ? [] : name);
                return;
            }

            const activeNamesCopy = this.activeNames.slice(0);
            const index = activeNamesCopy.indexOf(name);

            if (index > -1) {
                activeNamesCopy.splice(index, 1);
            } else {
                activeNamesCopy.push(name);
            }

            this.setActiveNames(activeNamesCopy);
        },
    },
};
</script>
