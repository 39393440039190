import { get } from 'lodash';
import Vue from 'vue';

export const OPTIONS = {
    SHOW:           'show',
    SHOW_MESSAGE:   'showMessage',
    MESSAGE_DOTS:   'messageDots',
    MESSAGE:        'message',
    SOCKET_MESSAGE: 'socketMessage',
    DOT:            'dot',
    ON_SHOW:        'onShow',
    ON_HIDE:        'onHide',
};

const loaderCallbacks = {
    [OPTIONS.ON_SHOW]: undefined,
    [OPTIONS.ON_HIDE]: undefined,
};

const getPromise = (name, callback) => new Promise((resolve) => {
    loaderCallbacks[name] = resolve;

    callback?.();
});

export default {
    namespaced: true,
    state:      {
        show:          false,
        showMessage:   true,
        messageDots:   true,
        message:       '',
        socketMessage: undefined,
        dot:           false,
        onShow:        () => {
            loaderCallbacks[OPTIONS.ON_SHOW]?.();
        },
        onHide:        () => {
            loaderCallbacks[OPTIONS.ON_HIDE]?.();
        },
    },
    mutations:  {
        SET(state, { key, value }) {
            Vue.set(state, key, value);
        },
    },
    actions:    {
        SHOW({ dispatch }, options = {}) {
            dispatch('SET_OPTIONS', options);
            dispatch('SET_OPTION', [OPTIONS.SHOW, true]);
        },
        HIDE({ dispatch }) {
            dispatch('SET_OPTION', [OPTIONS.SHOW, false]);
            dispatch('SET_OPTIONS', {});
        },
        SHOW_SYNC({ dispatch }, options = {}) {
            return getPromise(OPTIONS.ON_SHOW, () => {
                dispatch('SHOW', options);
            });
        },
        HIDE_SYNC({ dispatch }) {
            return getPromise(OPTIONS.ON_HIDE, () => {
                dispatch('HIDE');
            });
        },
        SET_OPTIONS({ dispatch }, options) {
            dispatch('SET_OPTION', [OPTIONS.SOCKET_MESSAGE, get(options, OPTIONS.SOCKET_MESSAGE)]);
            dispatch('SET_OPTION', [OPTIONS.SHOW_MESSAGE, get(options, OPTIONS.SHOW_MESSAGE, true)]);
            dispatch('SET_OPTION', [OPTIONS.MESSAGE, get(options, OPTIONS.MESSAGE, '')]);
            dispatch('SET_OPTION', [OPTIONS.MESSAGE_DOTS, get(options, OPTIONS.MESSAGE_DOTS, true)]);
            dispatch('SET_OPTION', [OPTIONS.DOT, get(options, OPTIONS.DOT, false)]);
        },
        SET_OPTION({ commit, state }, [key, value]) {
            if (Object.values(OPTIONS).includes(key) && state[key] !== value) {
                commit('SET', { key, value });
            }
        },
    },
};
