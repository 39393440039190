<template>
    <base-select
        v-model="tab"
        class="shrink-0 w-48"
        use-first
        :options="tabs"
        @change="click"
    />
</template>

<script>
import { find } from 'lodash';

export default {
    name:   'BaseTabsSelect',
    props:  {
        tabs: {
            type:     Array,
            required: true,
        },
    },
    inject: ['baseTabs'],
    data() {
        return {
            tab: undefined,
        };
    },
    computed: {
        active() {
            return find(this.tabs, item => item.active);
        },
    },
    watch:    {
        active() {
            this.setActiveTab();
        },
    },
    methods:  {
        click(tabName) {
            this.baseTabs.changeTab(find(this.tabs, item => item.name === tabName));
        },
        setActiveTab() {
            if (!this.active) return;

            this.tab = this.active.name;
        },
    },
    created() {
        this.setActiveTab();
    },
};
</script>
