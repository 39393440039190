const prefix = 'fac';
const iconName = 'add-note';
const width = 94;
const height = 84;
const ligatures = [];
const unicode = null;
const svgPathData = 'M20.419,10.371l7.2-7h29c3.9,0,7.101,3,7.101,7H57.32h-15h-15v19h0.1h-0.9H8.32v45h34.1h15h6.4c-0.201,4-3.301,7-7.101,7  H9.62c-4,0-7.3-2.9-7.3-6.9V29.071L20.419,10.371L20.419,10.371z M63.87,70.021c15.2,0,27.601-12.399,27.601-27.699  c0-15.3-12.4-27.6-27.601-27.6s-27.7,12.4-27.7,27.6C36.17,57.621,48.57,70.021,63.87,70.021z M48.471,38.421h11v-11h8v11h10v7h-10  v11h-8v-11H49.27l-0.799,0.1V38.421z';

export const definition = {
    prefix,
    iconName,
    icon: [
        width,
        height,
        ligatures,
        unicode,
        svgPathData,
    ],
};

export { definition as faAddNote };
