<script>
export default {
    name: 'AddFunds',
};
</script>

<script setup>
import { computed, ref } from 'vue';
import useVuelidate from '@vuelidate/core';
import { minValue, required } from '@vuelidate/validators';

const props = defineProps({
    show: {
        type: Boolean,
    },
});

const emit = defineEmits({
    input: null,
    close: null,
});

const showPopup = computed({
    get: () => props.show,
    set: value => emit('input', value),
});

function handleCloseDialog() {
    showPopup.value = false;
    emit('close');
}

const price = ref(120);

const v$ = useVuelidate(computed(() => ({
    price: {
        required,
        minValue: minValue(5),
    },
})), { price });

const isDisabled = computed(() => v$.value.price?.$error);
</script>

<template>
    <base-modal
        v-model="showPopup"
        size="fit"
        :data-test="dataTest('popup')"
        :title="$t('user add funds title')"
        :close-on-click-overlay="false"
        :close-on-press-escape="false"
        @close="handleCloseDialog"
    >
        <form
            class="flex flex-col overflow-hidden space-y-3 sm:space-y-0 sm:space-x-3 sm:flex-row"
            action="/payment/add"
        >
            <base-input
                v-model="v$.price.$model"
                input-class="text-lg font-avenir"
                name="price"
                size="large"
                :placeholder="$t('user add funds')"
            >
                <template #prefix>
                    <span class="text-lg text-gray-400 font-avenir">$</span>
                </template>
            </base-input>
            <base-button
                type="primary"
                round
                native-type="submit"
                :disabled="isDisabled"
                :data-test="dataTest('popup-submit')"
            >
                {{ $t('user add funds') }}
            </base-button>
        </form>
    </base-modal>
</template>
