<script>
export default {
    name: 'BasePinCode',
};
</script>

<script setup>
import { computed, ref, watchEffect, set, onMounted, watch } from 'vue';
import { SIZE, TYPE } from '@/components/base-components/components/pin-code';

const props = defineProps({
    value:     {
        type: [String, Number],
    },
    length:    {
        type:      Number,
        default:   4,
        validator: value => value >= 1,
    },
    divider:   {
        type:      [Number, Array],
        validator: (value) => {
            if (!Array.isArray(value)) return value > 0;

            return value.every(i => Number.isInteger(i) && i > 0);
        },
    },
    type:      {
        type:      String,
        default:   TYPE.DEFAULT,
        validator: value => Object.values(TYPE).includes(value),
    },
    size:      {
        type:      String,
        default:   SIZE.MEDIUM,
        validator: value => Object.values(SIZE).includes(value),
    },
    autofocus: {
        type: Boolean,
    },
    secure:    {
        type: Boolean,
    },
});

const emit = defineEmits({
    input: null,
});

const cells = ref([]);

const pinCode = computed(() => cells.value.map(({ value }) => value).join(''));

watchEffect(() => {
    emit('input', pinCode.value);
});

function showDivider(index) {
    if (!Array.isArray(props.divider)) return index === props.divider;

    return props.divider.includes(index);
}

const input = ref(null);

const focusedCell = ref(0);

function focusCell(key) {
    input.value?.[key]?.focus();
    input.value?.[key]?.select();
}

function focusPreviousCell() {
    if ((focusedCell.value - 1) < 0) return;

    focusCell(focusedCell.value - 1);
}

function focusNextCell() {
    if ((focusedCell.value + 1) >= props.length) return;

    focusCell(focusedCell.value + 1);
}

function onInput(key, value) {
    cells.value[key].value = value;
}

function onKeyDown(event) {
    return {
        37: focusPreviousCell,
        39: focusNextCell,
    }[event.keyCode?.toString()]?.();
}

function onDelete(cellKey) {
    if (cells.value[cellKey]?.value.length) return;

    focusPreviousCell();
}

const baseCode = computed(() => props.value?.toString().split(''));

function parseCells() {
    for (let key = 0; key < props.length; key++) {
        const value = baseCode.value?.[key] ?? '';

        set(cells.value, key, {
            key,
            value,
        });
    }

    focusCell(baseCode.value?.length ?? 0);
}

watch(() => props.value, () => {
    parseCells();
}, { immediate: true });

onMounted(() => {
    if (!props.autofocus) return;

    focusCell(0);
});
</script>

<template>
    <div class="inline-flex items-center gap-x-2">
        <template
            v-for="cell in cells"
        >
            <div
                v-if="showDivider(cell.key)"
                :key="`divider-${cell.key}`"
                class="border-t-2 w-2"
            />

            <base-input
                v-model.trim="cell.value"
                ref="input"
                :size="size"
                :type="type"
                :key="cell.key"
                :maxlength="1"
                :native-size="1"
                :native-type="secure ? 'password' : 'text'"
                autocomplete="new-password"
                center
                @input="v => onInput(cell.key, v)"
                @focus="focusedCell = cell.key"
                @keydown.delete="onDelete(cell.key)"
                @keydown="onKeyDown"
            />
        </template>
    </div>
</template>
