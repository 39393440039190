export const SIZE = Object.freeze({
    SMALL:  'small',
    MEDIUM: 'medium',
    LARGE:  'large',
});

export const TYPE = Object.freeze({
    DEFAULT:       'default',
    PRIMARY:       'primary',
    SECONDARY:     'secondary',
    PRIMARY_OLD:   'primary-old',
    SECONDARY_OLD: 'secondary-old',
    SUCCESS:       'success',
    WARNING:       'warning',
    DANGER:        'danger',
    INFO:          'info',
});
