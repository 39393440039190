<template>
    <div
        class="flex flex-col w-full"
        :class="wrapperClass"
    >
        <base-tabs-navigation
            v-if="tabs.length"
            :tabs="tabs"
            :stretch="stretch"
            :disabled="locked || disabled"
            :quick-navigation-left="quickNavigationLeft"
            :quick-navigation-right="quickNavigationRight"
        >
            <template #default="{ tab }">
                <slot name="navigation-tab" :tab="tab" />
            </template>
            <template #prefix>
                <slot name="navigation-prefix" />
            </template>
            <template #suffix>
                <slot name="navigation-suffix" />
            </template>
        </base-tabs-navigation>
        <slot />
    </div>
</template>

<script>
import BaseTabsNavigation from '@/components/base-components/components/tabs/base-tabs-navigation';

export default {
    name:       'BaseTabs',
    components: {
        BaseTabsNavigation,
    },
    props:      {
        value:        [String, Number],
        disabled:     Boolean,
        stretch:      Boolean,
        beforeLeave:  Function,
        wrapperClass: {
            type:    String,
            default: 'gap-y-3',
        },

        quickNavigationLeft:  Boolean,
        quickNavigationRight: Boolean,
    },
    provide() {
        return {
            baseTabs: this,
        };
    },
    data() {
        return {
            tabs:    [],
            current: this.value,
            locked:  false,
        };
    },
    watch:   {
        value(value) {
            this.setActive(value);
        },
    },
    methods: {
        getTabsInstances() {
            return this.$slots.default
                .filter(vnode => vnode.tag && vnode.componentOptions && vnode.componentOptions.Ctor.options.name === 'BaseTab' && !!vnode.componentInstance)
                .map(({ componentInstance }, index) => {
                    componentInstance.index = index;

                    return componentInstance;
                });
        },
        getTabs() {
            if (this.$slots.default) {
                const tabs = this.getTabsInstances();

                if (!this.tabsEqual(tabs)) {
                    this.tabs = tabs;
                }
            } else if (this.tabs.length !== 0) {
                this.tabs = [];
            }
        },
        tabsEqual(tabs) {
            if (tabs.length !== this.tabs.length) return false;

            const newNames = tabs.map(tab => tab.name);
            const oldNames = this.tabs.map(tab => tab.name);

            return JSON.stringify(newNames) === JSON.stringify(oldNames);
        },
        async changeTab(tab) {
            if (this.beforeLeave && this.current) {
                this.locked = true;
                const result = await this.beforeLeave(tab);
                this.locked = false;

                if (result === false) return;
            }

            this.setActive(tab.name);
            this.$emit('input', tab.name);
            this.$emit('change', tab);
        },
        setActive(name) {
            this.current = name === undefined ? undefined : name.toString();
        },
        isActive(name) {
            return this.current === name.toString();
        },
    },
    created() {
        this.setActive(this.value);
    },
    mounted() {
        this.getTabs();
    },
    updated() {
        this.getTabs();
    },
};
</script>
