import Vue from 'vue';
import { get, has } from 'lodash';
import notify from '@/components/base-components/components/notification/notify';
import useWebSockets from '@/composables/useWebSockets';
import { USER } from '@/assets/util/event-constants';

const {
    on:   onWebSocket,
    off:  offWebSocket,
    emit: emitWebSocket,
} = useWebSockets();

const onSubscribe = (channel, callback) => {
    onWebSocket(channel, (message) => {
        callback(JSON.parse(message));
    });
};

const offSubscribe = (channel, callback) => {
    offWebSocket(channel, (message) => {
        callback(JSON.parse(message));
    });
};

export default {
    namespaced: true,
    state:      {
        data:        undefined,
        currentGuid: undefined,
        currentId:   undefined,
        currentRole: undefined,
    },
    getters:    {
        isExists:                state => has(state.data, 'id'),
        getId:                   state => get(state.data, 'id'),
        getRole:                 state => get(state.data, 'aclRole.name', 'guest'),
        getGroup:                state => get(state.data, 'aclRole.group', 'visitor'),
        isTwoFactorEnabled:      state => get(state.data, 'twoFactor.enabled'),
        getGuid:                 state => get(state.data, 'guid'),
        getEmail:                state => get(state.data, 'email'),
        getName:                 state => get(state.data, 'name'),
        getBalance:              state => get(state.data, 'balance'),
        getDiscount:             state => get(state.data, 'discount'),
        getPartnerRequest:       state => get(state.data, 'partnerRequest'),
        getRegistrationSurveyId: state => get(state.data, 'registrationSurveyId'),
        isEmployee:              (state, getters) => getters.getGroup === 'employee',
    },
    mutations:  {
        SET_DATA(state, { data, merge }) {
            state.data = merge === true ? { ...(state.data || {}), ...data } : data;

            // TODO backward compatibility with
            //  * src/assets/libs/analytics.js:260
            //  * public/js/manager/scripts.js:1943
            //  * public/js/manager/user/grid.js:1139
            window.user = state.data;
        },
        SET_CURRENT_GUID(state, guid) {
            state.currentGuid = guid;
        },
        SET_CURRENT_ID(state, id) {
            state.currentId = id;
        },
        SET_CURRENT_ROLE(state, role) {
            state.currentRole = role;
        },
        SET_PARTNER_REQUEST(state, value) {
            state.data.partnerRequest = value;
        },
    },
    actions:    {
        async INIT({ dispatch }, data) {
            await dispatch('aclAccess/GET_ACCESSES', data.aclRole?.name ?? 'guest', { root: true });

            await dispatch('SET_DATA', {
                data,
                merge: false,
            });
        },
        async SET_DATA({ commit, dispatch }, { data, merge = true }) {
            commit('SET_DATA', {
                merge,
                data,
            });

            await dispatch('SUBSCRIBE_GUID');
            await dispatch('SUBSCRIBE_ID');
            await dispatch('SUBSCRIBE_ACL_ACCESS');
        },
        SUBSCRIBE_GUID({ state, getters, commit, dispatch }) {
            const guid = getters.getGuid;
            const currentGuid = state.currentGuid;

            if (currentGuid === guid) return;

            const callback = async (data) => {
                await dispatch('aclAccess/GET_ACCESSES', data.aclRole?.name ?? 'guest', { root: true });

                dispatch('SET_DATA', {
                    merge: false,
                    data,
                });

                window.emitter.emit(USER.GUID);
            };

            if (currentGuid) {
                offSubscribe(`login-${currentGuid}`, callback);
                offSubscribe(`logout-${currentGuid}`, callback);
            }

            if (guid) {
                onSubscribe(`login-${guid}`, callback);
                onSubscribe(`logout-${guid}`, callback);
            }

            commit('SET_CURRENT_GUID', guid);
        },
        SUBSCRIBE_ID({ state, getters, commit, dispatch }) {
            const id = getters.getId;
            const currentId = state.currentId;

            if (currentId === id) return;

            if (currentId) {
                offSubscribe(`user-data-${currentId}`, (data) => {
                    dispatch('SET_DATA', { data });
                });
                offSubscribe(`user-message-${currentId}`, ({ message }) => {
                    notify({
                        message,
                        duration: 15000,
                        position: 'top-left',
                    });
                });
            }

            if (id) {
                onSubscribe(`user-data-${id}`, (data) => {
                    dispatch('SET_DATA', { data });
                });
                onSubscribe(`user-message-${id}`, ({ message }) => {
                    notify({
                        message,
                        duration: 15000,
                        position: 'top-left',
                    });
                });
            }

            window.emitter.on('user-message', ({ userId, message }) => {
                emitWebSocket(`user-message-${userId}`, { message });
            });

            commit('SET_CURRENT_ID', id);
        },
        async SUBSCRIBE_ACL_ACCESS({ state, getters, commit, dispatch }) {
            const role = getters.getRole;
            const currentRole = state.currentRole;

            if (currentRole === role) return;

            if (currentRole) {
                await dispatch('aclAccess/UNSUBSCRIBE_ROLE_ACCESS', currentRole, { root: true });
            }

            await dispatch('aclAccess/SUBSCRIBE_ROLE_ACCESS', role, { root: true });

            commit('SET_CURRENT_ROLE', role);
        },
        async PARTNER_REQUEST({ commit }) {
            await Vue.prototype.$http.post('/user/partner-request', {});

            commit('SET_PARTNER_REQUEST', 'sent');
        },
    },
};
