<script>
export default {
    name: 'BaseJsonFormGroup',
};
</script>

<script setup>
import { computed, nextTick, ref, watch } from 'vue';
import BaseJsonFormItem from '@/components/base-components/components/json-form/base-json-form-item';
import BaseJsonFormItemError from '@/components/base-components/components/json-form/base-json-form-item-error';
import BaseJsonFormGroupBulk from '@/components/base-components/components/json-form/base-json-form-group-bulk';

const props = defineProps({
    value:       {
        type: Array,
    },
    name:        {
        type:     String,
        required: true,
    },
    schema:      {
        type:     Object,
        required: true,
    },
    data:        {
        type: Object,
    },
    links:       {
        type: Object,
    },
    errors:      {
        type:    [Object, Array],
        default: () => ({}),
    },
    required:    {
        type: Boolean,
    },
    validations: {
        type: Object,
    },
    itemsLimit:  {
        type:    Number,
        default: 1000,
    },
});

const emit = defineEmits({
    input:  null,
    change: null,
});

const groupWrapper = ref(null);

async function scrollDown() {
    await nextTick();

    groupWrapper.value.scrollTo({
        top:      groupWrapper.value.wrapRef.scrollHeight - groupWrapper.value.$refs.scrollRef.clientHeight,
        behavior: 'smooth',
    });
}

const model = ref(props.value ?? []);

function add(items = [undefined]) {
    model.value.push(...items);

    scrollDown();
}

function removeItem(index) {
    model.value.splice(index, 1);
}

watch(model, () => {
    emit('input', model.value);
    emit('change', model.value);
});

watch(() => props.value, () => {
    model.value = props.value ?? [];
});

const groupTypes = {
    BULK: 'bulk',
    ONE:  'one',
};

const type = ref(null);

const bulkEnabled = computed(() => props.data.type === 'array'
    && props.schema.type === 'string'
    && !props.schema.enum);

watch(bulkEnabled, () => {
    if (bulkEnabled.value) return;

    type.value = groupTypes.ONE;
});

const oneEnabled = computed(() => {
    if (!bulkEnabled.value) return true;

    return model.value.length <= props.itemsLimit;
});

watch(oneEnabled, () => {
    if (oneEnabled.value) {
        if (type.value) return;

        type.value = groupTypes.ONE;
        return;
    }

    type.value = groupTypes.BULK;
}, { immediate: true });

const groupTypesSwitcher = computed(() => [
    {
        label:    'One',
        value:    groupTypes.ONE,
        disabled: !oneEnabled.value,
        tooltip:  !oneEnabled.value ? `You are not able to edit more than ${props.itemsLimit} items one by one. Only bulk editing available in this case.` : undefined,
    },
    {
        label: 'Bulk',
        value: groupTypes.BULK,
    },
]);

const isChildObject = computed(() => props.schema.type === 'object');

const invalid = computed(() => props.validations.$invalid);

function getItemValidations(index) {
    return props.validations?.$each?.[index] ?? {};
}
</script>

<template>
    <div class="flex flex-col gap-y-1">
        <div class="inline-flex items-center gap-x-2 text-sm font-medium text-gray-500">
            <span>{{ data.title || name }}</span>
            <span v-if="model.length">({{ model.length }})</span>
        </div>
        <div
            class="ml-2 flex flex-col border-l pl-2"
            :class="{
                'border-red-500': invalid,
            }"
        >
            <base-switcher
                v-if="bulkEnabled"
                v-model="type"
                class="mb-1 self-start"
                :options="groupTypesSwitcher"
            >
                <template #option="{ option }">
                    <base-tooltip :content="option.tooltip" :disabled="!option.tooltip">
                        <span v-text="option.label" class="text-center text-sm" />
                    </base-tooltip>
                </template>
            </base-switcher>
            <base-scroll
                ref="groupWrapper"
                wrap-class="max-h-lg"
            >
                <div
                    v-if="type === groupTypes.ONE && model && model.length"
                    class="flex flex-col gap-y-1"
                    :class="{
                        'gap-y-0 divide-y divide-gray-100': isChildObject,
                    }"
                >
                    <div
                        v-for="(item, index) in model"
                        class="inline-flex items-center gap-x-2 pr-2"
                        :key="index"
                        :class="{
                            'py-2': isChildObject,
                        }"
                    >
                        <base-json-form
                            v-if="isChildObject"
                            v-model="model[index]"
                            :schema="schema"
                            :key="`${name}-${index}`"
                            :links="links"
                            :errors="errors[index]"
                            :disabled="item && item.is_updateable === false"
                            wrap-class="inline-flex flex-1 gap-x-2"
                            item-class="flex-1"
                        />
                        <base-json-form-item
                            v-else
                            v-model="model[index]"
                            class="flex-1"
                            :key="`${name}-${index}`"
                            :name="`${name}-${index}`"
                            :data="schema"
                            :validations="getItemValidations(index)"
                            :errors="errors[index]"
                            required
                        />
                        <base-button
                            size="small"
                            text
                            :icon="{ name: 'trash-alt', prefix: 'far' }"
                            :disabled="!!item && item.is_updateable === false"
                            @click="removeItem(index)"
                        />
                    </div>
                </div>
                <base-input-array-as-string
                    v-if="bulkEnabled && type === groupTypes.BULK"
                    v-model="model"
                    class="w-full"
                    size="medium"
                    :rows="10"
                    :placeholder="data.title"
                />
            </base-scroll>
            <base-json-form-item-error class="my-1" :validations="validations" />
            <div
                v-if="type === groupTypes.ONE"
                class="inline-flex items-center"
                :class="{
                    'pt-2': model.length || invalid,
                }"
            >
                <base-button
                    size="small"
                    text
                    label="Add"
                    @click="add()"
                />
                <base-json-form-group-bulk
                    v-model="model"
                    :schema="schema"
                    :links="links"
                    @input="scrollDown()"
                />
            </div>
        </div>
    </div>
</template>
