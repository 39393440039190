<script>
export default {
    name: 'BaseJsonFormGroupBulk',
};
</script>

<script setup>
import { computed, ref, watch } from 'vue';

const props = defineProps({
    value:  {
        type: Array,
    },
    schema: {
        type:     Object,
        required: true,
    },
    links:  {
        type: Object,
    },
});

const emit = defineEmits({
    input: null,
});

const bulkItem = computed(() => {
    if (props.schema.type !== 'array' && props.schema.type !== 'object') return null; // TODO strings, numbers

    const [name, values] = Object.entries(props.schema.properties)[0];

    if (values.type === 'array' || values.type === 'object') return null;

    return {
        name,
        title: values.title,
    };
});

const bulkItemLinksCallbacks = computed(() => {
    if (!bulkItem.value || !props.links) return [];

    return Object.entries(props.links)
        .filter(([property, {
            on,
            condition,
        }]) => on === bulkItem.value.name && props.schema.properties[property] && condition(props.schema.properties[on], props.schema.properties[property]))
        .map(([, { callback }]) => callback);
});

const hint = computed(() => {
    const label = bulkItem.value?.title ?? 'values';

    return `*Enter your ${label} one per line`;
});

const model = ref(undefined);

const submitDisabled = computed(() => !model.value || !model.value.trim());

const showModal = ref(false);
watch(showModal, () => {
    if (!showModal.value) {
        model.value = undefined;
        return;
    }

    model.value = props.value
        .filter(Boolean)
        .map(item => item[bulkItem.value.name])
        .join('\n');
});

function submit() {
    const items = model.value
        .split('\n')
        .filter(Boolean)
        .map((item) => {
            const result = {
                [bulkItem.value.name]: item,
            };

            bulkItemLinksCallbacks.value.forEach((callback) => {
                callback?.(item, result);
            });

            return result;
        });

    emit('input', items);

    showModal.value = false;
}
</script>

<template>
    <div>
        <base-button
            v-if="bulkItem"
            size="small"
            text
            label="Bulk"
            @click="showModal = true"
        />

        <base-modal
            v-model="showModal"
            size="mini"
            title=" "
            :close-on-click-overlay="false"
        >
            <base-input
                v-model="model"
                class="w-full"
                native-type="textarea"
                resize="none"
                :rows="16"
            />
            <template #footer>
                <div class="inline-flex w-full items-center justify-between gap-x-3">
                    <span class="text-sm italic text-gray-400">{{ hint }}</span>
                    <base-button
                        type="primary"
                        :disabled="submitDisabled"
                        label="Apply"
                        @click="submit"
                    />
                </div>
            </template>
        </base-modal>
    </div>
</template>
