<script>
export default {
    name: 'BaseDropdown',
};
</script>

<script setup>
import { provide, ref } from 'vue';
import { isValid, PLACEMENTS } from '@/components/base-components/utils/placement';

const props = defineProps({
    placement:      {
        type:      String,
        default:   PLACEMENTS.BOTTOM,
        validator: isValid,
    },
    trigger:        {
        type:      String,
        default:   'hover',
        validator: value => ['hover', 'click'].includes(value),
    },
    disabled:       {
        type: Boolean,
    },
    noClickOutside: {
        type: Boolean,
    },
    noHideOnClick:  {
        type: Boolean,
    },
    render:         {
        type:      String,
        default:   'default',
        validator: value => ['default', 'lazy', 'always'].includes(value),
    },
    openDelay:      {
        type:    Number,
        default: 0,
    },
    hideDelay:      {
        type:    Number,
        default: 50,
    },
    transition:     {
        type: Object,
        default() {
            return {
                'enter-active-class': 'transition duration-150 ease-in-out origin-top scale-y-100',
                'leave-active-class': 'transition duration-150 ease-in-out origin-top scale-y-100',
                'leave-to-class':     'opacity-0 origin-top scale-y-50',
                'enter-class':        'opacity-0 origin-top scale-y-50',
            };
        },
    },
});

const emit = defineEmits({
    command:       null,
    visibleChange: null,
});

const popover = ref(null);

const itemCommand = (command, instance) => {
    if (props.disabled) return;

    emit('command', command, instance);

    if (props.noHideOnClick) return;

    popover.value?.close();
};

provide('base-dropdown', {
    itemCommand,
});
</script>

<template>
    <base-popover
        ref="popover"
        :placement="placement"
        :trigger="trigger"
        :disabled="disabled"
        :no-click-outside="noClickOutside"
        :render="render"
        :open-delay="openDelay"
        :hide-delay="hideDelay"
        :transition="transition"
        @show="emit('visible-change', true)"
        @hide="emit('visible-change', false)"
    >
        <template #reference>
            <slot name="reference" />
        </template>

        <ul class="list-none flex flex-col py-2">
            <slot />
        </ul>
    </base-popover>
</template>
