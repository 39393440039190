<script>
export default {
    name: 'App',
};
</script>

<script setup>
import AppLayout from '@/layouts/layout';
import AppMaintenance from '@/components/app/maintenance';
import AppVersion from '@/components/app/version';
</script>

<template>
    <div id="app">
        <app-layout class="min-h-0">
            <router-view />
        </app-layout>
        <knowledge-base />
        <app-maintenance />
        <app-version />
    </div>
</template>
