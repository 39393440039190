import { debounce } from 'lodash';

export default {
    data() {
        return {
            resizeElementRef:          null,
            resizeElementObserver:     null,
            resizeElementDebounce:     null,
            resizeElementDebounceTime: 150,
        };
    },
    mounted() {
        if (!this.resizeElementRef || !this.$refs[this.resizeElementRef]) return;

        const ref = this.$refs[this.resizeElementRef];

        this.resizeElementDebounce = debounce((entries) => {
            this.resizeElementDebounceMethod(entries[0], entries);
        }, this.resizeElementDebounceTime);
        this.resizeElementObserver = new ResizeObserver(this.resizeElementDebounce);
        this.resizeElementObserver.observe(ref?.$el || ref);
    },
    beforeDestroy() {
        if (this.resizeElementRef && this.$refs[this.resizeElementRef]) {
            const ref = this.$refs[this.resizeElementRef];

            this.resizeElementObserver.unobserve(ref?.$el || ref);
        }
    },
    methods: {
        resizeElementDebounceMethod() {
        },
    },
};
