<template>
    <base-modal
        v-model="show"
        size="mini"
        append-to-body
        :close-on-click-overlay="false"
        :title="$t('form field button edit many')"
        @close="$emit('popupHide')"
    >
        <base-input
            v-model="textValue"
            class="w-full"
            size="large"
            native-type="textarea"
            :rows="7"
            :placeholder="$t('form field edit many placeholder', { field })"
        />

        <template #footer>
            <base-button
                type="primary"
                round
                :disabled="disabled"
                @click="apply"
            >
                {{ $t('apply button') }}
            </base-button>
        </template>
    </base-modal>
</template>

<script>
import {
    filter,
    isEmpty,
    join,
    map,
    split,
    trim,
} from 'lodash';

export default {
    name:       'AuthorizationMultifieldBulkPopup',
    components: {},
    props:      {
        showPopup: {
            type:    Boolean,
            default: false,
        },
        field:     {
            type: String,
        },
        value:     {
            type:    Array,
            default: () => [],
        },
        required:  {
            type:    Boolean,
            default: false,
        },
    },
    data() {
        return {
            show:      this.showPopup,
            textValue: '',
            oneEmpty:  [''],
        };
    },
    computed:   {
        disabled() {
            return this.required && isEmpty(this.parsedTextValue);
        },
        parsedTextValue() {
            return filter(map(split(this.textValue, /[\n;,]/)), trim);
        },
        values() {
            return !isEmpty(this.parsedTextValue) ? this.parsedTextValue : this.oneEmpty;
        },
    },
    watch:      {
        showPopup() {
            this.show = this.showPopup;

            if (this.show) {
                this.updateValue();
            }
        },
    },
    methods:    {
        updateValue() {
            this.textValue = join(this.value, '\n');
        },
        apply() {
            this.$emit('apply', this.values);
        },
    },
};
</script>
