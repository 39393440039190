<template>
    <div class="flex flex-col flex-1 w-full h-full">
        <h4 v-if="!isSingleAuthorization()" class="text-base sm:text-lg">
            {{ $t('choose authorization') }}
        </h4>
        <div v-if="!isSingleAuthorization()" class="flex">
            <authorizationTab
                v-for="(authorization, key) in authorizations"
                :key="key"
                :authorization="authorization"
                :active="isActive(key)"
                :prefix="prefix"
                @changeActive="changeActive"
            />
        </div>
        <div class="h-full">
            <authorizationTabContent
                v-for="(authorization, key) in authorizations"
                :data-path="dataPath"
                :key="key"
                :authorization="authorization"
                :active="isActive(key)"
                :update="forceUpdate"
                :state="state"
                :selected-c-r-m="selectedCRM"
                :is-single="isSingleAuthorization()"
                :prefix="prefix"
                :name-prefix="namePrefix"
                :show-submit="showSubmit"
                :show-authorization-description="showAuthorizationDescription"
                :show-files-credentials="showFilesCredentials"
                :is-submitted="isSubmitted"
                :predefined-data="predefinedData"
                :product="product"
                @updateCredential="updateCredentials"
                @submitTab="submitStepData"
                @onFieldFocus="onFieldFocus"
            />
        </div>
    </div>
</template>

<script>
import { has } from 'lodash';
import isAvailableAuthorization from '@/assets/util/is-available-authorization';
import authorizationTab from '@/components/authorization-tab/authorization-tab';
import authorizationTabContent from '@/components/authorization-tab-content/authorization-tab-content';
import getData from '@/components/get-data/get-data';

export default {
    name:       'AuthorizationTabs',
    extends:    getData,
    components: {
        authorizationTab,
        authorizationTabContent,
    },
    props:      {
        authorizations:               {
            type: Object,
        },
        update:                       {
            type: Boolean,
        },
        selectedCRM:                  {
            type: String,
        },
        isSupported:                  {
            type: Boolean,
        },
        showSubmit:                   {
            type:    Boolean,
            default: true,
        },
        showAuthorizationDescription: {
            type:    Boolean,
            default: true,
        },
        showFilesCredentials:         {
            type:    Boolean,
            default: true,
        },
        prefix:                       {
            type:    String,
            default: '',
            validator(value) {
                return [
                    'source',
                    'target',
                    '',
                ].indexOf(value) !== -1;
            },
        },
        namePrefix:                   {
            type: String,
        },
        isSubmitted:                  {
            type: Boolean,
        },
        predefinedData:               {
            type: [Object, Array],
        },
        state:                        {
            type:    String,
            default: 'active',
            validator(type) {
                return ['active', 'done', 'disabled'].indexOf(type) !== -1;
            },
        },
        product:                      {
            type: String,
        },
        inlineSubmit:                 {
            type: Boolean,
        },
    },
    data() {
        return {
            active:      this.authorizations ? Object.keys(this.authorizations)[0] : undefined,
            forceUpdate: this.update,
            credentials: {},
        };
    },
    computed: {
        activeAuthorization() {
            return this.active;
        },
    },
    watch:    {
        authorizations() {
            if (!this.active) {
                this.setDefaultActiveTab();
            }
            if (!has(this.authorizations, this.active)) {
                this.active = Object.keys(this.authorizations)[0];
            }
            this.$emit('changeAuthorization', this.active);
        },
    },
    mounted() {
        if (this.getData(this.prefix)) {
            this.active = this.getData(this.prefix).authorization;
        }
    },
    updated() {
        if (!this.active) {
            this.setDefaultActiveTab();
        }
    },
    methods: {
        isAvailable(authorization) {
            return isAvailableAuthorization(authorization.credential, this.prefix);
        },
        setDefaultActiveTab() {
            this.active = Object.keys(this.authorizations)[0];
        },
        changeActive(newActive) {
            this.active = newActive;
            this.$emit('changeTab');
        },
        isSingleAuthorization() {
            if (!this.authorizations) {
                return true;
            }

            return Object.keys(this.authorizations).length === 1;
        },
        isActive(key) {
            const activeNotExists = this.authorizations[this.activeAuthorization] === undefined;
            const isFirst = Object.keys(this.authorizations)[0] === key;

            return key === this.activeAuthorization || (activeNotExists && isFirst);
        },
        updateCredentials(type, credentials) {
            // TODO change this if
            if (type === 'file') {
                this.credentials.file = {
                    path: [],
                };
                credentials.forEach((credential) => {
                    this.credentials.file.path.push(
                        {
                            label:   credential.entityLabel,
                            name:    credential.entityName,
                            value:   credential.value,
                            fieldId: credential?.fieldId ?? null,
                            fields:  credential?.fields ?? [],
                        },
                    );
                });
            } else {
                this.credentials[type] = {};
                credentials.forEach((credential) => {
                    this.credentials[type][credential.name] = credential.value;
                });
            }
            this.$emit('updateCredentials', this.credentials, credentials);
        },
        submitStepData(type, success, error) {
            const sendData = {
                crmId:         this.getData(this.prefix)?.id,
                authorization: type,
                credential:    this.credentials,
                csrf:          this.getData('csrf'),
                platform:      this.selectedCRM,
                loaderOptions: {
                    message: this.$t('authorization validating credentials'),
                },
            };

            if (this.inlineSubmit) {
                return this.$emit('submit', {
                    sendData,
                    success,
                    error,
                });
            }

            window.eventBus.$emit(
                this.$store.state.eventConstants.submitStep,
                sendData,
                success,
                error,
            );
        },
        onFieldFocus(credential) {
            this.$emit('onFieldFocus', credential);
        },
    },
};
</script>
