<template>
    <hiw-page
        crm="SuiteCRM"
        crm-type="SuiteCRM"
        medium="suitecrm-plugin"
        campaign="SuiteCrmMarketplace"
        source-crm="CapsuleCRM"
        :steps="steps"
    />
</template>

<script>
import HiwPage from '@/components/hiw-page/hiw-page';

export default {
    name:       'SugarPage',
    components: { HiwPage },
    computed:   {
        steps() {
            return [
                {
                    text: this.$t('plugin create your account or login'),
                    url:  'img/landings/suite/register-data2crm.png',
                    alt:  this.$t('plugin sign up image alt'),
                },
                {
                    text: this.$t('plugin select your current crm'),
                    url:  'img/landings/suite/capsule-source-for-suiteplugin.png',
                    alt:  this.$t('plugin authorize image alt'),
                },
                {
                    text: this.$t('plugin choose your future crm'),
                    url:  'img/landings/suite/suitecrm-target.png',
                    alt:  this.$t('plugin choose future crm image alt'),
                },
                {
                    text: this.$t('plugin select entities and check mapping'),
                    url:  'img/landings/suite/mapping-start-free-demo.png',
                    alt:  this.$t('plugin check automated mapping image alt'),
                },
                {
                    text: this.$t('plugin start free demo migration'),
                    url:  'img/landings/suite/demo-process-suite.png',
                    alt:  this.$t('plugin start free demo image alt'),
                },
                {
                    text: this.$t('plugin check demo results and start full migration'),
                    url:  'img/landings/suite/start-full-suite-plugin.png',
                    alt:  this.$t('plugin launch full migration image alt'),
                },
            ];
        },
    },
};
</script>
