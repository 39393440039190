export const FIELD = Object.freeze({
    ID:           'id',
    TAGS:         'tags',
    NAME:         'name',
    LABEL:        'label',
    PRICE:        'price',
    TOTAL_PRICE:  'totalPrice',
    INCREMENT:    'increment',
    PAID:         'paid',
    TYPE:         'type',
    STATUS:       'status',
    FORMAT:       'format',
    CYCLE:        'cycle',
    CYCLE_STATUS: 'cycleStatus',
    OPTIONS:      'options',
    CREATED_BY:   'createdBy',
    STARTED_AT:   'startedAt',
    ENDED_AT:     'endedAt',
    EXPIRES_AT:   'expiresAt',
    CREATED_AT:   'createdAt',
    UPDATED_AT:   'updatedAt',
});

export const NAME = Object.freeze({
    SAMPLE:  'sample',
    STARTER: 'starter',
    BASIC:   'basic',
    PRO:     'pro',
    CUSTOM:  'custom',
});

export const STATUS = Object.freeze({
    WAIT:      'wait',
    CANCEL:    'cancel',
    ACTIVE:    'active',
    DISABLE:   'disable',
    UPGRADE:   'upgrade',
    DOWNGRADE: 'downgrade',
    EXPIRE:    'expire',
});

export const PRODUCT = Object.freeze({
    GLOBAL:            'global',
    BACKUP:            'backup',
    MIGRATION:         'migration',
    MIGRATION_LICENSE: 'migration license',
    CALENDAH:          'calendah',
});

export const GROUP_FIELD = Object.freeze({
    LABEL:    'label',
    PRODUCT:  'product',
    NAME:     'name',
    PRICE:    'price',
    FEATURES: 'features',
});

export const TIER_FIELD = Object.freeze({
    TYPE:      'type',
    DISCOUNT:  'discount',
    INCREMENT: 'increment',
});

export const TIER_TYPE = Object.freeze({
    MONTH: 'month',
    YEAR:  'year',
});

export const TYPE = Object.freeze({
    DEFAULT:  'default',
    TEMPLATE: 'template',
    CUSTOM:   'custom',
    IN_USE:   'in use',
});
