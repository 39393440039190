/**
 * Format decimals based on Intl.NumberFormat
 * @link https://developer.mozilla.org/ru/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat
 *
 * @param {Number} value
 * @param {String} locale
 * @param {Object} options
 */
const formatDecimal = (
    value,
    locale = 'en-EN',
    options = {},
) => new Intl.NumberFormat(
    locale,
    {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
        ...options,
    },
).format(value);

export default formatDecimal;
