<script>
export default {
    name: 'BasePaginationJumper',
};
</script>

<script setup>
import { computed } from 'vue';
import { SIZE, TYPE } from '@/components/base-components/components/pagination';

const props = defineProps({
    value:    {
        type: Number,
    },
    count:    {
        type:     Number,
        required: true,
    },
    pageSize: {
        type:     Number,
        required: true,
    },
    type:     {
        type:      String,
        default:   TYPE.DEFAULT,
        validator: value => Object.values(TYPE).includes(value),
    },
    size:     {
        type:      String,
        default:   SIZE.SMALL,
        validator: value => Object.values(SIZE).includes(value),
    },
    disabled: {
        type: Boolean,
    },
});

const emit = defineEmits({
    input: null,
});

const model = computed({
    get: () => props.value,
    set: value => emit('input', value),
});

const pages = computed(() => Math.max(Math.ceil(props.count / props.pageSize), 1));
const options = computed(() => Array.from({ length: pages.value }).map((_, index) => index + 1));
</script>

<template>
    <div class="flex items-center flex-nowrap gap-x-2">
        <div
            :class="[
                {
                    [SIZE.LARGE]: 'text-base',
                    [SIZE.MEDIUM]: 'text-sm',
                    [SIZE.SMALL]: 'text-sm',
                }[size],
            ]"
        >
            Go to
        </div>
        <base-select
            v-model="model"
            :options="options"
            :type="type"
            :size="size"
            :disabled="disabled"
            no-sorting
            use-first
            auto-width
        />
    </div>
</template>
