import millify from 'millify';

/**
 * Format numbers based on Intl.NumberFormat
 * @link https://developer.mozilla.org/ru/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat
 *
 * @param {Number} value
 * @param {Boolean} short
 * @param {String} locale
 */
const formatNumber = (value, short = false, locale = 'en-EN') => {
    if (short) {
        return millify(value, {
            precision: value > 10000 ? 0 : 2,
        });
    }

    return new Intl.NumberFormat(locale, { maximumFractionDigits: 0 }).format(value);
};

export default formatNumber;
