import { DATA, FIELD, FEATURE } from '@/components/payments/components/service-plan/components/features/constants';
import { FIELD as PLAN_FIELD, GROUP_FIELD } from '@/components/payments/components/service-plan/constants';
import ServicePlanFeatureAvailable from '@/components/payments/components/service-plan/components/features/components/available';
import ServicePlanFeatureEntity from '@/components/payments/components/service-plan/components/features/components/entity';
import ServicePlanFeatureLifespan from '@/components/payments/components/service-plan/components/features/components/lifespan';
import ServicePlanFeatureRecord from '@/components/payments/components/service-plan/components/features/components/record';
import ServicePlanFeatureRun from '@/components/payments/components/service-plan/components/features/components/run';
import ServicePlanFeatureSchedule from '@/components/payments/components/service-plan/components/features/components/schedule';
import ServicePlanFeatureScope from '@/components/payments/components/service-plan/components/features/components/scope';
import ServicePlanFeatureCalendahActiveCalendar
    from '@/components/payments/components/service-plan/components/features/components/product/calendah/active-calendar';
import ServicePlanFeatureCalendahAccessLevel
    from '@/components/payments/components/service-plan/components/features/components/product/calendah/access-level';

export function getFeature(plan, product, feature) {
    return plan[PLAN_FIELD.OPTIONS]?.[product]?.[GROUP_FIELD.FEATURES]?.[feature];
}

export function getVisibleFeatures(features) {
    return Object.fromEntries(Object.entries(features).filter(([, feature]) => !feature.internal));
}

export function getFeatureComponent(feature) {
    return {
        [FEATURE.BACKUP_ENTITY]:   ServicePlanFeatureEntity,
        [FEATURE.BACKUP_LIFESPAN]: ServicePlanFeatureLifespan,
        [FEATURE.BACKUP_RECORD]:   ServicePlanFeatureRecord,
        [FEATURE.BACKUP_RUN]:      ServicePlanFeatureRun,
        [FEATURE.BACKUP_SCHEDULE]: ServicePlanFeatureSchedule,

        [FEATURE.MIGRATION_RECORD]: ServicePlanFeatureRecord,
        [FEATURE.MIGRATION_RUN]:    ServicePlanFeatureRun,
        [FEATURE.MIGRATION_SCOPE]:  ServicePlanFeatureScope,

        [FEATURE.MIGRATION_LICENSE_RECORD]: ServicePlanFeatureRecord,
        [FEATURE.MIGRATION_LICENSE_RUN]:    ServicePlanFeatureRun,
        [FEATURE.MIGRATION_LICENSE_SCOPE]:  ServicePlanFeatureScope,

        [FEATURE.CALENDAH_AVAILABLE]:       ServicePlanFeatureAvailable,
        [FEATURE.CALENDAH_ACTIVE_CALENDAR]: ServicePlanFeatureCalendahActiveCalendar,
        [FEATURE.CALENDAH_ACCESS_LEVEL]:    ServicePlanFeatureCalendahAccessLevel,
    }[feature] ?? null;
}

export function getFeatureValue(feature) {
    return feature?.[FIELD.DATA]?.[DATA.VALUE];
}

export function getFeatureScope(feature) {
    return feature?.[FIELD.DATA]?.[DATA.SCOPE];
}

export function getFeatureUsed(feature) {
    return feature?.[FIELD.DATA]?.[DATA.VALUE_USED];
}

export function getFeatureExclude(feature) {
    return feature?.[FIELD.DATA]?.[DATA.EXCLUDE] ?? [];
}

export function getFeatureUnit(feature) {
    return feature?.[FIELD.DATA]?.[DATA.VALUE_UNIT];
}
