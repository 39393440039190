<script>
export default {
    name: 'BackupPricingPlanCard',
};
</script>

<script setup>
import { useRouter } from 'vue-router/composables';
import { computed, ref } from 'vue';
import { capitalize } from 'lodash';
import i18n from '@/plugins/i18n';
import { FIELD } from '@/components/payments/components/service-plan/constants';
import ServicePlanFeatures from '@/components/payments/components/service-plan/components/features/components';
import BackupPricingPlansCurrentExpires from '@/components/payments/components/service-plan/components/expires';

const props = defineProps({
    plan: {
        type:     Object,
        required: true,
    },
});

const planWarning = computed(() => props.plan.totalPrice !== null);
const title = computed(() => i18n.t('plan using', { plan: capitalize(props.plan.name) }));

const collapsed = ref(false);
const collapseButtonText = computed(() => (collapsed.value ? i18n.t('backup plan card close') : i18n.t('backup plan card open')));

const router = useRouter();

const pricingLink = computed(() => ({
    name:   'backupPricing',
    params: {
        backupId: router.currentRoute.params.backupId,
    },
}));
</script>

<template>
    <div
        class="flex flex-col overflow-hidden rounded-md font-medium"
        :class="planWarning ? 'bg-azure-600' : 'bg-amber-500'"
    >
        <div class="flex items-center px-3 py-2 text-white space-x-3">
            <base-icon
                name="info-circle"
                prefix="fas"
                size="2x"
            />
            <span class="text-sm" v-html="title" />
        </div>
        <div
            class="flex cursor-pointer select-none items-center px-3 pb-2 text-sm space-x-2 hover:text-white"
            :class="planWarning ? 'text-azure-300' : 'text-amber-300'"
            @click="collapsed = !collapsed"
        >
            <base-icon
                class="transition duration-300 ease-in-out"
                :class="collapsed ? '-rotate-180' : 'rotate-0'"
                name="chevron-down"
                prefix="fas"
                size="sm"
            />
            <span class="text-sm leading-none transition duration-300">
                {{ collapseButtonText }}
            </span>
        </div>
        <div
            v-if="collapsed"
            class="transition duration-500 ease-in-out"
            :class="planWarning ? 'bg-azure-700' : 'bg-yellow-700'"
        >
            <div class="flex flex-col gap-y-1.5 px-3 pt-2">
                <service-plan-features
                    class="flex flex-col text-sm text-white"
                    :features="plan.options.backup.features"
                >
                    <template #item-prefix>
                        <base-icon
                            class="mr-3"
                            :class="planWarning ? 'text-azure-300' : 'text-yellow-500'"
                            name="check"
                            prefix="fas"
                            size="xs"
                        />
                    </template>
                </service-plan-features>

                <div
                    v-if="plan[FIELD.EXPIRES_AT]"
                    class="flex items-center text-sm"
                >
                    <base-icon
                        class="mr-3"
                        :class="planWarning ? 'text-azure-300' : 'text-yellow-500'"
                        name="check"
                        prefix="fas"
                        size="xs"
                    />
                    <backup-pricing-plans-current-expires
                        class="text-white"
                        :format="plan[FIELD.FORMAT]"
                        :cycle-status="plan[FIELD.CYCLE_STATUS]"
                        :expires-at="plan[FIELD.EXPIRES_AT]"
                    />
                </div>
            </div>
            <div class="flex flex-col px-3 py-2 text-sm text-white">
                <span>
                    <b>
                        {{ $t('backup plan card collapse need more') }}
                    </b>
                </span>
                <router-link
                    class="text-white underline hover:text-gray-200 focus:text-gray-200 active:text-gray-200"
                    :to="pricingLink"
                >
                    {{ $t('backup plan card collapse view more') }}
                </router-link>
            </div>
        </div>
    </div>
</template>
