<script>
export default {
    name: 'BaseDraggable',
};
</script>

<script setup>
import { computed, ref, watch } from 'vue';
import draggable from 'vuedraggable';

const props = defineProps({
    value:      {
        type:    Array,
        default: () => ([]),
    },
    tag:        {
        type:    String,
        default: 'div',
    },
    itemTag:    {
        type:    String,
        default: 'div',
    },
    itemClass:  {
        type: [String, Array, Object],
    },
    keyField:   {
        type: [String, Function],
    },
    group:      {
        type: String,
    },
    animation:  {
        type:    [String, Number],
        default: 200,
    },
    ghostClass: {
        type:    String,
        default: 'opacity-0',
    },
    disabled:   {
        type: Boolean,
    },
});

const emit = defineEmits({
    input: null,
    start: null,
    end:   null,
});

const model = computed({
    get: () => props.value,
    set: value => emit('input', value),
});

const drag = ref(false);
watch(drag, () => {
    emit(drag.value ? 'start' : 'end', model.value);
});

function getKey(item, index) {
    if (!props.keyField) return index;
    if (typeof props.keyField === 'function') return props.keyField(item, index);

    return item[props.keyField] ?? index;
}
</script>

<template>
    <draggable
        v-model="model"
        :tag="tag"
        :group="group"
        :animation="animation"
        :ghost-class="ghostClass"
        :disabled="disabled || model.length <= 1"
        @start="drag = true"
        @end="drag = false"
    >
        <component
            v-for="(item, index) in model"
            :is="itemTag"
            :class="[
                itemClass,
                {
                    'cursor-move': !disabled && model.length > 1,
                }
            ]"
            :key="getKey(item, index)"
        >
            <slot :item="item" :index="index">
                {{ item }}
            </slot>
        </component>
    </draggable>
</template>
