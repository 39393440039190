<template>
    <base-modal
        size="mini"
        :value="value"
        :close-on-click-overlay="false"
        @close="close"
    >
        <crm-authorization-form
            v-if="connectData"
            class="mt-7"
            :show-files-credentials="true"
            :show-authorization-description="false"
            :show-form-title="false"
            :locked="locked"
            data-path="connection.connectData.data"
            :type="type"
            inline-submit
            @submit="submit"
        />

        <template #content v-if="!connectData">
            <div v-loader="true" class="h-36 w-full" />
        </template>
    </base-modal>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import notify from '@/components/base-components/components/notification/notify';
import CrmAuthorizationForm from '@/components/crm-authorization-form/crm-authorization-form';
import { connectionConnect } from '@/modules/connection/api';

export default {
    name:       'ConnectionPopup',
    components: { CrmAuthorizationForm },
    props:      {
        value:      {
            type:     Boolean,
            required: true,
        },
        type:       {
            type:     String,
            required: true,
        },
        connection: {
            type: Object,
        },
        title:      {
            type: String,
        },
        locked:     {
            type: Boolean,
        },
    },
    computed:   {
        ...mapState(['eventConstants']),
        ...mapState('connection', {
            connectData: state => state.connectData.data,
        }),
    },
    watch:      {
        async value() {
            if (this.value) {
                await this.loadConnectData({
                    connectionId: this.connection.id,
                    direction:    this.type,
                });

                return;
            }

            this.unloadConnectData();
        },
    },
    methods:    {
        ...mapActions('connection', {
            loadConnectData:   'LOAD_CONNECT_DATA',
            unloadConnectData: 'UNLOAD_CONNECT_DATA',
            setConnection:     'SET_CONNECTION',
        }),
        ...mapActions('loader', {
            showLoader: 'SHOW',
            hideLoader: 'HIDE',
        }),
        async submit({ sendData, error: reject }) {
            this.showLoader(sendData.loaderOptions);

            try {
                const connection = await connectionConnect(sendData);

                await this.setConnection(connection);

                this.$emit('connect', connection);

                this.close();
            } catch ({ message, data, response }) {
                notify.error({
                    title:    this.$t('request error notification'),
                    message,
                    duration: 7500,
                });

                reject?.(data, response);
            } finally {
                this.hideLoader();
            }
        },
        close() {
            this.$emit('input', false);
            this.$emit('close');
        },
    },
};
</script>
