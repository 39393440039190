<template>
    <base-button
        id="logout-button"
        size="small"
        text
        stretch
        :label="$t('tmp Logout')"
        :data-test="dataTest()"
        @click="go"
    >
        <template #prefix>
            <svg
                class="w-5 h-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
            >
                <path
                    fill-rule="evenodd"
                    d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z"
                    clip-rule="evenodd"
                />
            </svg>
        </template>
    </base-button>
</template>

<script>
import { mapActions } from 'vuex';
import { userLogout } from '@/components/user/api';

export default {
    name:    'NavigationLogout',
    methods: {
        ...mapActions({
            showLoader: 'loader/SHOW',
            hideLoader: 'loader/HIDE',
            init:       'user/INIT',
        }),
        async go() {
            this.$emit('navigate');

            await this.showLoader();

            await this.init(await userLogout());
            await this.$nextTick();
            await this.$router.push({ name: 'userLogin' });

            await this.hideLoader();
        },
    },
};
</script>
