<script>
import HelpPage from '@/pages/components/help';

export default {
    name:    'PolicyPage',
    extends: HelpPage,
    data() {
        return {
            title: this.$t('header breadcrumb policy'),
            cards: [
                {
                    title:   this.$t('policy privacy'),
                    message: this.$t('policy privacy message'),
                    url:     this.$filters.serviceLink('privacy-policy'),
                },
                {
                    title:   this.$t('policy tos'),
                    message: this.$t('policy tos message'),
                    url:     this.$filters.serviceLink('terms-of-service'),
                },
                {
                    title:   this.$t('policy gdpr'),
                    message: this.$t('policy gdpr message'),
                    url:     this.$filters.serviceLink('gdpr'),
                },
                {
                    title:   this.$t('policy security'),
                    message: this.$t('policy security message'),
                    url:     this.$filters.serviceLink('security-policy'),
                },
                {
                    title:   this.$t('policy nda'),
                    message: this.$t('policy nda message'),
                    url:     '/docs/TrujayNon-DisclosureAgreement.pdf',
                },
            ],
        };
    },
};
</script>
