<template>
    <BaseBanner
        :data-test="dataTest()"
        :show="show && actual"
        :icon="{ name: 'cookie-bite', prefix: 'fas' }"
        :text="message"
        :button-text="$t('accept cookies info button')"
        :button-link="'gdpr' | serviceLink"
        closable
        @close="accept"
    />
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'AllowCookieBanner',
    data() {
        return {
            show: false,
        };
    },
    computed: {
        ...mapState({
            actual: state => state.version.actual,
        }),
        message() {
            const link = this.$filters.serviceLink('terms-of-service');

            return this.$t('accept cookies message', {
                linkOpen:  `<a href='${link}' target='_blank'>`,
                linkClose: '</a>',
            });
        },
    },
    mounted() {
        if (!this.$dataCookies.has('allowConsentCookies')) {
            setTimeout(() => {
                this.show = true;
            }, 1500);
        }
    },
    methods: {
        accept() {
            this.setCookie('1');
            this.show = false;
        },
        setCookie(value) {
            this.$http.get(
                `/allow-consent-cookies?value=${value}`,
            );
        },
    },
};
</script>
