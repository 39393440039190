import Vue from 'vue';
import DropArea from './drop-area';

const Mask = Vue.extend(DropArea);

function addClass(el, cls) {
    const list = el.className.split('');

    if (list.indexOf(cls) === -1) {
        el.className = `${el.className} ${cls}`;
    }
}

function removeClass(el, cls) {
    el.className = el.className.replace(` ${cls} `, ' ');
}

const insertDom = (parent, el) => {
    addClass(parent, 'relative');
    parent.appendChild(el.areaMask);
    el.areaDomInserted = true;
};

const switchArea = (el, binding) => {
    if (binding.value.render) {
        Vue.nextTick(() => {
            insertDom(el, el, binding);
        });
    } else {
        removeClass(el, 'relative');
        el.areaInstance.visible = false;
    }
};

let onDragOver;

const directive = {
    bind(el, binding) {
        const mask = new Mask({
            el:   document.createElement('div'),
            data: {
                text:   binding.value.text,
                onDrop: binding.value.onDrop,
            },
        });

        el.areaInstance = mask;
        el.areaMask = mask.$el;

        onDragOver = (event) => {
            event.preventDefault();

            if (el.areaDomInserted && binding.value.applyDrop?.(event)) {
                el.areaInstance.visible = true;
            }
        };

        el.addEventListener('dragover', onDragOver, false);

        if (binding.value.render) {
            switchArea(el, binding);
        }
    },

    update(el, binding) {
        if (binding.oldValue.render !== binding.value.render) {
            switchArea(el, binding);
        }
    },

    unbind(el) {
        if (el.areaDomInserted && el.areaMask && el.areaMask.parentNode) {
            el.areaMask.parentNode.removeChild(el.areaMask);
            switchArea(el, { value: { render: false } });
        }

        if (el.areaInstance) {
            el.areaInstance.$destroy();
        }

        el.removeEventListener('dragover', onDragOver);
    },
};

export default directive;
