<script>
export default {
    name: 'BaseScroll',
};
</script>

<script setup>
import { computed, nextTick, onMounted, onUpdated, provide, reactive, ref, watch } from 'vue';
import { useEventListener, useResizeObserver } from '@vueuse/core';
import BaseScrollBar from '@/components/base-components/components/scroll/base-scroll-bar';
import { addUnit } from '@/components/base-components/utils/dom';
import { isNumber, isObject } from '@/components/base-components/utils/types';
import { GAP } from '@/components/base-components/components/scroll/utils';

const props = defineProps(
    {
        height:    {
            type:    [String, Number],
            default: '',
        },
        maxHeight: {
            type:    [String, Number],
            default: '',
        },
        native:    {
            type:    Boolean,
            default: false,
        },
        wrapStyle: {
            type:    [String, Object, Array],
            default: '',
        },
        wrapClass: {
            type:    [String, Array],
            default: '',
        },
        viewClass: {
            type:    [String, Array],
            default: '',
        },
        viewStyle: {
            type:    [String, Array, Object],
            default: '',
        },
        noresize:  {
            type: Boolean,
        },
        tag:       {
            type:    String,
            default: 'div',
        },
        always:    {
            type: Boolean,
        },
        minSize:   {
            type:    Number,
            default: 20,
        },
    },
);
const emit = defineEmits({
    scroll: null,
});

const scrollRef = ref(null);
const wrapRef = ref(null);
const resizeRef = ref(null);

const sizeWidth = ref('0');
const sizeHeight = ref('0');
const barRef = ref(null);
const ratioY = ref(1);
const ratioX = ref(1);

const style = computed(() => {
    const style = {};

    if (props.height) style.height = addUnit(props.height);
    if (props.maxHeight) style.maxHeight = addUnit(props.maxHeight);

    return [props.wrapStyle, style];
});

const moveX = ref(0);
const moveY = ref(0);

const handleScroll = () => {
    if (!wrapRef.value) return;

    const offsetHeight = wrapRef.value.offsetHeight - GAP;
    const offsetWidth = wrapRef.value.offsetWidth - GAP;

    moveY.value = ((wrapRef.value.scrollTop * 100) / offsetHeight) * ratioY.value;
    moveX.value = ((wrapRef.value.scrollLeft * 100) / offsetWidth) * ratioX.value;

    emit('scroll', {
        scrollTop:  wrapRef.value.scrollTop,
        scrollLeft: wrapRef.value.scrollLeft,
    });
};

function scrollTo(xCoord, yCoord) {
    if (isObject(xCoord)) {
        wrapRef.value?.scrollTo(xCoord);
        return;
    }

    if (isNumber(xCoord) && isNumber(yCoord)) {
        wrapRef.value?.scrollTo(xCoord, yCoord);
    }
}

const setScrollTop = (value) => {
    if (!isNumber(value) || !wrapRef.value) return;

    wrapRef.value.scrollTop = value;
};

const setScrollLeft = (value) => {
    if (!isNumber(value) || !wrapRef.value) return;

    wrapRef.value.scrollLeft = value;
};

const update = () => {
    if (!wrapRef.value) return;

    const offsetHeight = wrapRef.value.offsetHeight - GAP;
    const offsetWidth = wrapRef.value.offsetWidth - GAP;

    const originalHeight = offsetHeight ** 2 / wrapRef.value.scrollHeight;
    const originalWidth = offsetWidth ** 2 / wrapRef.value.scrollWidth;
    const height = Math.max(originalHeight, props.minSize);
    const width = Math.max(originalWidth, props.minSize);

    ratioY.value = originalHeight / (offsetHeight - originalHeight) / (height / (offsetHeight - height));
    ratioX.value = originalWidth / (offsetWidth - originalWidth) / (width / (offsetWidth - width));

    sizeHeight.value = height + GAP < offsetHeight ? addUnit(height) : '';
    sizeWidth.value = width + GAP < offsetWidth ? addUnit(width) : '';
};

let stopResizeObserver;
let stopResizeListener;

watch(() => props.noresize, (noresize) => {
    if (noresize) {
        stopResizeObserver?.();
        stopResizeListener?.();

        return;
    }

    ({ stop: stopResizeObserver } = useResizeObserver(resizeRef, update));
    stopResizeListener = useEventListener('resize', update);
}, { immediate: true });

watch(() => [props.maxHeight, props.height], () => {
    if (props.native) return;

    nextTick(() => {
        update();

        if (wrapRef.value) {
            barRef.value?.handleScroll(wrapRef.value);
        }
    });
});

provide('baseScroll', reactive({
    scrollElement: scrollRef,
    wrapElement:   wrapRef,
}));

onMounted(() => {
    if (props.native) return;

    nextTick(() => {
        update();
    });
});

onUpdated(() => update());

defineExpose({
    wrapRef,
    update,
    scrollTo,
    setScrollTop,
    setScrollLeft,
    handleScroll,
});
</script>

<template>
    <div
        class="relative overflow-hidden"
        ref="scrollRef"
    >
        <div
            ref="wrapRef"
            class="h-full overflow-auto"
            :class="[
                wrapClass,
                {
                    'base-scroll-wrap': !native,
                },
            ]"
            :style="style"
            @scroll="handleScroll"
        >
            <component
                :is="tag"
                ref="resizeRef"
                :class="viewClass"
                :style="viewStyle"
            >
                <slot />
            </component>
        </div>
        <template v-if="!native">
            <base-scroll-bar
                :move="moveX"
                :ratio="ratioX"
                :size="sizeWidth"
                :always="always"
            />
            <base-scroll-bar
                :move="moveY"
                :ratio="ratioY"
                :size="sizeHeight"
                :always="always"
                vertical
            />
        </template>
    </div>
</template>

<style>
.base-scroll-wrap {
    scrollbar-width: none;
}

.base-scroll-wrap::-webkit-scrollbar {
    display: none;
}
</style>
