import { debounce } from 'lodash';
import { APP } from '@/assets/util/event-constants';

const PAGE_LEAVE_TIMEOUT = 1000 * 60; // 1 minute

let worker;
let visible;

const startWatch = () => {
    worker.postMessage({ watch: true });
};

const stopWatch = () => {
    worker.postMessage({ watch: false });
};

const onVisible = () => {
    visible = true;

    startWatch();
};

const onHidden = debounce(() => {
    visible = !document.hidden;

    if (!visible) {
        stopWatch();
    }
}, PAGE_LEAVE_TIMEOUT);

const visibilityChange = () => {
    if (document.hidden && visible) {
        onHidden();
        return;
    }

    if (!visible) {
        onVisible();
    }
};

export default function registerVersionWorker() {
    if (!window.Worker) return;

    worker = new Worker('/static/dist/js/workers/version-worker.js');

    worker.onmessage = ({ data: { updated } }) => {
        window.emitter.emit(APP.UPDATE, updated);

        if (updated) {
            document.removeEventListener('visibilitychange', visibilityChange);

            stopWatch();
        }
    };

    visible = !document.hidden;

    if (visible) {
        onVisible();
    }

    document.addEventListener('visibilitychange', visibilityChange);
}
