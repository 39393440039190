import each from 'lodash/each';
import capitalize from './capitalize';

export default function isAvailableAuthorization(credentials, prefix) {
    let available = true;
    each(credentials, (credential) => {
        if (credential[`is${capitalize(prefix)}Available`] === false) {
            available = false;
        }
    });

    return available;
}
