export default class BaseApi {
    provider;

    get(url, config) {
        return this.provider.get(url, config);
    }

    post(url, data, config) {
        return this.provider.post(url, data, config);
    }

    put(url, data, config) {
        return this.provider.put(url, data, config);
    }

    delete(url, config) {
        return this.provider.delete(url, config);
    }
}
