import { isFunction } from 'lodash';

/**
 * Invoke callback if callable
 *
 * @param callback
 * @param args
 *
 * @returns {*}
 */
export default function callIfFunction(callback, ...args) {
    if (isFunction(callback)) {
        return callback(...args);
    }
}
