import formatBytes from '@/plugins/filters/format-bytes';
import formatCurrency from '@/plugins/filters/format-currency';
import formatDate from '@/plugins/filters/format-date';
import formatDecimal from '@/plugins/filters/format-decimal';
import formatDiscount from '@/plugins/filters/format-discount';
import formatLetterGrade from '@/plugins/filters/format-letter-grade';
import formatNumber from '@/plugins/filters/format-number';
import formatPercent from '@/plugins/filters/format-percent';
import formatTime from '@/plugins/filters/format-time';
import urls from '@/plugins/filters/public-site-url';
import percent from '@/plugins/filters/percent';
import secondsToTime from '@/plugins/filters/seconds-to-time';

const filters = {
    install(Vue) {
        const publicUrls = urls();

        Vue.prototype.$filters = {
            formatBytes,
            formatCurrency,
            formatDate,
            formatDecimal,
            formatDiscount,
            formatLetterGrade,
            formatNumber,
            formatPercent,
            formatTime,
            percent,
            secondsToTime,
            ...publicUrls,
        };

        Vue.filter('formatBytes', formatBytes);
        Vue.filter('formatCurrency', formatCurrency);
        Vue.filter('formatDate', formatDate);
        Vue.filter('formatDecimal', formatDecimal);
        Vue.filter('formatNumber', formatNumber);
        Vue.filter('formatPercent', formatPercent);
        Vue.filter('formatTime', formatTime);
        Vue.filter('secondsToTime', secondsToTime);

        Object.entries(publicUrls).forEach(([name, callback]) => {
            Vue.filter(name, callback);
        });
    },
};

export default filters;
