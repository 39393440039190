<script>
export default {
    name: 'BaseButton',
};
</script>

<script setup>
import { computed, inject } from 'vue';
import { baseButtonGroupKey, SIZE, TYPE } from '@/components/base-components/components/button';

const props = defineProps({
    label:             {
        type: [String, Number],
    },
    size:              {
        type:      String,
        validator: value => Object.values(SIZE).includes(value),
    },
    type:              {
        type:      String,
        validator: value => Object.values(TYPE).includes(value),
    },
    text:              {
        type: Boolean,
    },
    link:              {
        type: Boolean,
    },
    round:             {
        type: Boolean,
    },
    circle:            {
        type: Boolean,
    },
    loading:           {
        type: Boolean,
    },
    disabled:          {
        type: Boolean,
    },
    nativeType:        {
        type:      String,
        default:   'button',
        validator: value => ['button', 'submit', 'reset'].includes(value),
    },
    icon:              {
        type: Object,
    },
    customTypeClass:   {
        type: [String, Array, Object],
    },
    customRoundClass:  {
        type: String,
    },
    customBorderClass: {
        type: String,
    },
    customSizeClass:   {
        type: String,
    },
    contentClass:      {
        type: String,
    },
    textTransform:     {
        type:    Boolean,
        default: true,
    },
    stretch:           {
        type: Boolean,
    },
});

const groupContext = inject(baseButtonGroupKey, null);
const isGroup = computed(() => groupContext !== null);

const labelClass = computed(() => props.contentClass || [
    (!props.textTransform || props.link) ? 'normal-case' : 'uppercase',
    'whitespace-nowrap',
]);

const size = computed(() => props.size || (groupContext?.size ?? SIZE.MEDIUM));

const textClass = computed(() => ({
    [SIZE.LARGE]:  'text-sm',
    [SIZE.MEDIUM]: 'text-sm',
    [SIZE.SMALL]:  'text-xs',
}[size.value]));

const round = computed(() => props.round || (groupContext?.round ?? false));

const roundClass = computed(() => {
    if (props.customRoundClass) return props.customRoundClass;

    const isRound = round.value || props.circle;

    if (!isGroup.value) return isRound ? 'rounded-full' : 'rounded';

    return isRound ? 'first:rounded-l-full last:rounded-r-full' : 'first:rounded-l last:rounded-r';
});

const sizeClass = computed(() => {
    if (props.customSizeClass) return props.customSizeClass;

    if (props.link) return;

    if (props.circle) {
        return {
            [SIZE.LARGE]:  'w-10 h-10',
            [SIZE.MEDIUM]: 'w-9 h-9',
            [SIZE.SMALL]:  'w-8 h-8',
        }[size.value];
    }

    if (round.value) {
        return {
            [SIZE.LARGE]:  'px-6 py-3',
            [SIZE.MEDIUM]: 'px-5 py-2.5',
            [SIZE.SMALL]:  'px-4 py-2',
        }[size.value];
    }

    return {
        [SIZE.LARGE]:  'px-5 py-3',
        [SIZE.MEDIUM]: 'px-4 py-2.5',
        [SIZE.SMALL]:  'px-3 py-2',
    }[size.value];
});

const type = computed(() => props.type ?? groupContext?.type ?? TYPE.DEFAULT);

const typeClass = computed(() => {
    if (props.customTypeClass) return props.customTypeClass;

    if (props.link) {
        return {
            [TYPE.DEFAULT]:       'text-gray-600 hover:text-science-blue-800 active:text-science-blue-900 disabled:text-gray-600',
            [TYPE.PRIMARY]:       'text-primary-600 hover:text-primary-500 active:text-primary-700 disabled:text-primary-600',
            [TYPE.SECONDARY]:     'text-secondary-950 hover:text-secondary-600 active:text-secondary-900 disabled:text-secondary-950',
            [TYPE.PRIMARY_OLD]:   'text-science-blue-500 hover:text-science-blue-400 active:text-science-blue-600 disabled:text-science-blue-500',
            [TYPE.SECONDARY_OLD]: 'text-science-blue-800 hover:text-science-blue-600 active:text-science-blue-900 disabled:text-science-blue-800',
            [TYPE.SUCCESS]:       'text-emerald-600 hover:text-emerald-500 active:text-emerald-800 disabled:text-emerald-600',
            [TYPE.WARNING]:       'text-orange-500 hover:text-orange-400 active:text-orange-600 disabled:text-orange-500',
            [TYPE.DANGER]:        'text-red-600 hover:text-red-500 active:text-red-800 disabled:text-red-600',
            [TYPE.INFO]:          'text-gray-500 hover:text-gray-400 active:text-gray-600 disabled:text-gray-500',
        }[type.value];
    }

    if (props.text) {
        return {
            [TYPE.DEFAULT]:       'text-gray-600 hover:text-science-blue-900 hover:bg-science-blue-900 hover:bg-opacity-5 active:bg-science-blue-900 active:bg-opacity-10 disabled:text-gray-600 disabled:bg-transparent',
            [TYPE.PRIMARY]:       'text-primary-600 hover:bg-primary-600 hover:bg-opacity-10 active:bg-primary-600 active:bg-opacity-20 disabled:text-primary-600 disabled:bg-transparent',
            [TYPE.SECONDARY]:     'text-secondary-950 hover:bg-secondary-950 hover:bg-opacity-10 active:bg-secondary-950 active:bg-opacity-20 disabled:text-secondary-600 disabled:bg-transparent',
            [TYPE.PRIMARY_OLD]:   'text-science-blue-500 hover:bg-science-blue-500 hover:bg-opacity-10 active:bg-science-blue-500 active:bg-opacity-20 disabled:text-science-blue-500 disabled:bg-transparent',
            [TYPE.SECONDARY_OLD]: 'text-science-blue-800 hover:bg-science-blue-700 hover:bg-opacity-10 active:bg-science-blue-700 active:bg-opacity-20 disabled:text-science-blue-800 disabled:bg-transparent',
            [TYPE.SUCCESS]:       'text-emerald-600 hover:bg-emerald-600 hover:bg-opacity-10 active:bg-emerald-600 active:bg-opacity-20 disabled:text-emerald-600 disabled:bg-transparent',
            [TYPE.WARNING]:       'text-orange-500 hover:bg-amber-500 hover:bg-opacity-20 active:bg-amber-500 active:bg-opacity-30 disabled:text-amber-500 disabled:bg-transparent',
            [TYPE.DANGER]:        'text-red-600 hover:bg-red-600 hover:bg-opacity-10 active:bg-red-600 active:bg-opacity-20 disabled:text-red-600 disabled:bg-transparent',
            [TYPE.INFO]:          'text-gray-500 hover:bg-gray-500 hover:bg-opacity-10 active:bg-gray-500 active:bg-opacity-20 disabled:text-gray-500 disabled:bg-transparent',
        }[type.value];
    }

    return {
        [TYPE.DEFAULT]:       'text-gray-600 bg-white hover:text-science-blue-900 hover:bg-gray-100 disabled:text-gray-600 disabled:bg-white',
        [TYPE.PRIMARY]:       'text-white bg-primary-600 hover:bg-primary-500 active:bg-primary-700 disabled:text-white disabled:bg-primary-600',
        [TYPE.SECONDARY]:     'text-white bg-secondary-950 hover:bg-secondary-800 active:bg-secondary-900 disabled:text-white disabled:bg-secondary-950',
        [TYPE.PRIMARY_OLD]:   'text-white bg-science-blue-500 hover:bg-science-blue-400 active:bg-science-blue-600 disabled:text-white disabled:bg-science-blue-500',
        [TYPE.SECONDARY_OLD]: 'text-white bg-science-blue-800 hover:bg-science-blue-700 active:bg-science-blue-900 disabled:text-white disabled:bg-science-blue-800',
        [TYPE.SUCCESS]:       'text-white bg-emerald-600 hover:bg-emerald-500 active:bg-emerald-800 disabled:text-white disabled:bg-emerald-600',
        [TYPE.WARNING]:       'text-white bg-amber-500 hover:bg-amber-400 active:bg-amber-700 disabled:text-white disabled:bg-amber-500',
        [TYPE.DANGER]:        'text-white bg-red-600 hover:bg-red-500 active:bg-red-800 disabled:text-white disabled:bg-red-600',
        [TYPE.INFO]:          'text-white bg-gray-500 hover:bg-gray-400 active:bg-gray-600 disabled:text-white disabled:bg-gray-500',
    }[type.value];
});

const borderClass = computed(() => {
    if (props.customBorderClass) return props.customBorderClass;
    if (props.customTypeClass || props.link || props.text) return;

    if (type.value === TYPE.DEFAULT) return 'border border-gray-200 hover:border-gray-300 active:border-science-blue-900 disabled:border-gray-200';

    return [
        'border border-transparent',
        {
            'first:border-r-white/75 last:border-l-white/75 [&:not(:first-child)&:not(:last-child)]:border-x-white/75': isGroup.value,
        },
    ];
});

const loaderClass = computed(() => ({
    [SIZE.LARGE]:  '-ml-2 h-4 w-4',
    [SIZE.MEDIUM]: '-ml-1 h-4 w-4',
    [SIZE.SMALL]:  '-ml-0.5 h-4 w-4',
}[size.value]));

const iconClass = computed(() => {
    if (props.icon?.class) return props.icon.class;

    if (props.circle || round.value || props.link) {
        return {
            [SIZE.LARGE]:  'h-4 w-4',
            [SIZE.MEDIUM]: 'h-4 w-4',
            [SIZE.SMALL]:  'h-4 w-4',
        }[size.value];
    }

    return {
        [SIZE.LARGE]:  '-ml-1 h-4 w-4',
        [SIZE.MEDIUM]: '-ml-1 h-4 w-4',
        [SIZE.SMALL]:  'h-4 w-4',
    }[size.value];
});

const emit = defineEmits({
    click: null,
});

function click(event) {
    emit('click', event);
}
</script>

<template>
    <button
        class="inline-flex items-center gap-x-2 font-medium leading-none transition-colors duration-100 font-avenir disabled:cursor-not-allowed disabled:opacity-60"
        :class="[
            sizeClass,
            textClass,
            typeClass,
            borderClass,
            roundClass,
            stretch ? 'justify-start flex-1' : 'justify-center',
            {
                '[&:not(:first-child)]:-ml-px hover:z-10 active:z-10': isGroup && borderClass,
            },
        ]"
        :type="nativeType"
        :disabled="disabled || loading"
        :aria-disabled="disabled || loading"
        @click="click"
    >
        <template v-if="loading">
            <slot v-if="$slots.loading" name="loading" />
            <svg
                v-else
                :class="loaderClass"
                class="animate-spin"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
            >
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4" />
                <path class="opacity-75" fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                />
            </svg>
        </template>
        <template v-else>
            <slot name="prefix" :disabled="disabled">
                <base-icon v-if="icon" v-bind="icon" :class="iconClass" />
            </slot>
        </template>

        <span
            v-if="$slots.default"
            :class="labelClass"
        >
            <slot :disabled="disabled" />
        </span>
        <span
            v-else-if="label !== undefined"
            v-text="label"
            :class="labelClass"
        />

        <slot name="suffix" :disabled="disabled" />
    </button>
</template>
