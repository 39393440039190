/**
 * Format DateTime base on Intl.DateTimeFormat
 *
 * @link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat
 *
 * @param {String} value
 * @param {Object} options
 * @param {String} locale
 *
 * @returns {String|null}
 */
const formatDate = (value, options = {
    month:  'long',
    day:    'numeric',
    year:   'numeric',
    hour:   'numeric',
    minute: 'numeric',
}, locale = 'en-EN') => {
    if (!value) {
        return null;
    }

    try {
        return new Intl.DateTimeFormat(locale, options).format(new Date(value.replace(/\+([0-9]{2})([0-9]{2})/g, '+$1:$2')));
    } catch {
        return null;
    }
};

export default formatDate;
