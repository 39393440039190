import Vue from 'vue';
import { get, forEach, isBoolean, debounce, minBy, isEqual, trim, findKey } from 'lodash';
import Loader from './loader';

const Mask = Vue.extend(Loader);

function addClass(el, cls) {
    const list = el.className.split(' ');

    if (list.indexOf(cls) === -1) {
        el.className = el.className ? `${el.className} ${cls}` : cls;
    }
}

function removeClass(el, cls) {
    el.className = trim(el.className.replace(cls, ' '));
}

const insertDom = (parent, el) => {
    parent.appendChild(el.loaderMask);
    el.loaderDomInserted = true;
};

const removeDom = (el) => {
    el.loaderMask.parentNode.removeChild(el.loaderMask);
    el.loaderDomInserted = false;
};

const getBindingShow = binding => get(binding.value, 'show', isBoolean(binding.value) ? binding.value : false);
const getBindingImmediate = binding => get(binding.modifiers, 'immediate', false);
const getBindingFull = binding => get(binding.modifiers, 'full', false);
const getBindingSize = binding => get(binding.value, 'size');

const getBindingData = (binding) => {
    const value = get(binding, 'value', false);
    const modifiers = get(binding, 'modifiers', {});

    return {
        show:          getBindingShow(binding),
        showMessage:   get(modifiers, 'showMessage', get(value, 'showMessage', false)),
        message:       get(value, 'message'),
        messageDots:   get(value, 'messageDots', true),
        socketMessage: get(value, 'socketMessage'),
        size:          getBindingSize(binding),
        dot:           get(modifiers, 'dot', get(value, 'dot', false)),
        opaque:        get(modifiers, 'opaque', get(value, 'opaque', false)),
        lock:          get(modifiers, 'lock', false),
        noZ:           get(modifiers, 'noZ', false),
        full:          getBindingFull(binding),
        immediate:     getBindingImmediate(binding),
        onShow:        get(value, 'onShow'),
        onHide:        get(value, 'onHide'),
    };
};

const getParent = (el, binding) => (getBindingFull(binding) ? document.body : el);

const setData = (el, data) => {
    forEach(data, (value, key) => {
        if (value !== undefined && key !== 'show') {
            Vue.set(el.loaderInstance, key, value);
        }
    });
};

const show = (el, binding) => {
    el.loaderInstance.show = true;

    const data = getBindingData(binding);

    setData(el, data);

    const parent = getParent(el, binding);

    if (!parent.className.includes('absolute') && !parent.className.includes('fixed')) {
        addClass(parent, '!relative');
    }

    if (data.lock) {
        addClass(parent, '!overflow-hidden');
    }

    insertDom(parent, el, data);
};

const hide = (el, binding) => {
    el.loaderInstance.show = false;

    el.loaderInstance.$once('after-leave', () => {
        setData(el, getBindingData(binding));

        const parent = getParent(el, binding);

        removeClass(parent, '!relative');
        removeClass(parent, '!overflow-hidden');
    });
};

const changeShow = (el, binding) => {
    const showLoader = getBindingShow(binding);
    const immediate = getBindingImmediate(binding);

    if (showLoader) {
        if (!immediate) {
            if (el.closeTimeoutId) {
                clearTimeout(el.closeTimeoutId);
            }

            el.loaderStart = new Date();
        }

        show(el, binding);
    } else if (el.loaderInstance.show !== showLoader) {
        if (immediate) {
            hide(el, binding);
        } else {
            const delay = 600;
            const running = new Date().getTime() - el.loaderStart.getTime();

            if (running >= delay) {
                hide(el, binding);
            } else {
                el.closeTimeoutId = setTimeout(() => {
                    hide(el, binding);
                }, delay - running);
            }
        }
    }
};

const updateSize = (el, parent) => {
    const valueBase = 128;
    const item = minBy([
        {
            value:     parent.clientWidth,
            increment: 3,
        },
        {
            value:     parent.clientHeight,
            increment: 2,
        },
    ], 'value');

    const sizes = {
        sm:    item.value <= valueBase,
        md:    item.value <= valueBase * item.increment,
        lg:    item.value <= valueBase * item.increment * 2,
        xl:    item.value <= valueBase * item.increment * 3,
        '2xl': item.value >= valueBase * item.increment * 3,
    };

    const size = findKey(sizes);

    if (size) {
        el.loaderInstance.size = size;
    }
};

const loadingDirective = {
    bind(el, binding) {
        const mask = new Mask({
            el:   document.createElement('div'),
            data: getBindingData(binding),
        });

        el.loaderInstance = mask;
        el.loaderMask = mask.$el;
    },

    inserted(el, binding) {
        if (!getBindingSize(binding)) {
            const parent = getParent(el, binding);
            el.resizeDebounceHandler = debounce(() => {
                updateSize(el, parent);
            }, 200);
            window.addEventListener('resize', el.resizeDebounceHandler);
            updateSize(el, parent);
        }

        changeShow(el, binding);
    },

    update(el, binding) {
        if (isEqual(binding.oldValue, binding.value)) {
            return;
        }

        changeShow(el, binding);
    },

    unbind(el) {
        if (el.loaderDomInserted && el.loaderMask && el.loaderMask.parentNode) {
            removeDom(el);
            hide(el, { value: { show: false } });
        }

        if (el.loaderInstance) {
            el.loaderInstance.$destroy();
        }

        window.removeEventListener('resize', el.resizeDebounceHandler);
    },
};

export default loadingDirective;
