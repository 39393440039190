export const FORMAT = Object.freeze({
    ONE_TIME:     'one-time',
    SUBSCRIPTION: 'subscription',
});

export const CYCLE = Object.freeze({
    ONCE:      'once',
    DAY:       'day',
    WEEK:      'week',
    MONTH:     'month',
    QUARTER:   'quarter',
    HALF_YEAR: 'half-year',
    YEAR:      'year',
});

export const CYCLE_STATUS = Object.freeze({
    WAIT:                 'wait',
    COMPLETE:             'complete',
    ACTIVE:               'active',
    CANCEL_AT_PERIOD_END: 'cancel at period end',
});
