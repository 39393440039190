import VueCookie from 'vue-cookies';

const DataCookies = {
    install(Vue, { config }) {
        Vue.use(VueCookie);

        Vue.prototype.$dataCookies = {
            /**
             * Exist a cookie name
             *
             * @param {String} keyName
             *
             * @return {Boolean}
             */
            has(keyName) {
                return Vue.$cookies.isKey(keyName);
            },

            /**
             * Get a cookie
             *
             * @param {String} keyName
             *
             * @return {*}
             */
            get(keyName) {
                return Vue.$cookies.get(keyName);
            },

            /**
             * Set a cookie
             *
             * @param {String} keyName
             * @param {*} value
             * @param {String|Number|Date} expireTimes
             * @param {String} path
             * @param {String} domain
             * @param {Boolean} secure
             *
             * @return {Object}
             */
            set(keyName, value, expireTimes = '3y', path = '/', domain = config.window.cookieDomain, secure = true) {
                Vue.$cookies.set(keyName, value, expireTimes, path, domain, secure);
                return this;
            },
        };
    },
};

export default DataCookies;
