<script>
export default {
    name: 'ServicePlanFeatureElementsValue',
};
</script>

<script setup>
defineProps({
    name:  {
        type:     String,
        required: true,
    },
    value: {
        type:     [String, Number, Boolean],
        required: true,
    },
});

</script>

<template>
    <span :key="`${name}-${value}`">
        <slot><span v-html="value" /></slot>
    </span>
</template>
