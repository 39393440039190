<template>
    <span>
        <slot :value="progressNumber">
            {{ displayValue }}
        </slot>
    </span>
</template>

<script>
import animate from '@/assets/util/animate';

export default {
    name:  'BaseNumberAnimate',
    props: {
        to:          {
            type:     Number,
            required: true,
        },
        from:        {
            type:    Number,
            default: 0,
        },
        duration:    {
            type:    Number,
            default: 700,
        },
        shortFormat: Boolean,
        precision:   Number,
    },
    data() {
        return {
            progressNumber: this.from,
            baseFrom:       this.from,
        };
    },
    mounted() {
        this.animate();
    },
    computed: {
        displayValue() {
            return this.$filters.formatNumber(this.progressNumber, this.shortFormat);
        },
    },
    watch:    {
        to() {
            this.animate();
        },
    },
    methods:  {
        animate() {
            animate(
                (progress) => {
                    if (this.to === this.baseFrom) return;

                    if (this.to > this.baseFrom) {
                        this.progressNumber = ((this.to - this.baseFrom) * progress) + this.baseFrom;
                        return;
                    }

                    this.progressNumber = (this.baseFrom - this.to) - ((this.baseFrom - this.to) * progress) + this.to;
                },
                () => {
                    this.baseFrom = this.to;
                },
                this.duration,
            );
        },
    },
};
</script>
