<template>
    <div
        v-show="active"
        class="h-full"
    >
        <div
            class="h-full bg-white"
            :class="isSingle ? 'p-0 border-0' : 'px-4 py-2 rounded-bl rounded-br border border-t-0'"
        >
            <div v-if="isSupported && showSubmit && showAuthorizationDescription" class="my-4 flex items-center">
                {{ $t('authorization message automated') }}
                <base-tooltip
                    v-if="authorization.description"
                    placement="top"
                    :content="authorization.description"
                >
                    <base-icon
                        class="mx-1 text-primary-600"
                        name="question-circle"
                        prefix="far"
                    />
                </base-tooltip>
            </div>
            <div
                v-if="!isSupported && showSubmit && showAuthorizationDescription"
                class="my-4 flex items-center"
            >
                {{ $t('authorization message custom') }}
                <base-tooltip
                    v-if="authorization.description"
                    placement="top"
                    :content="authorization.description"
                >
                    <base-icon
                        class="mx-1 text-primary-600"
                        name="question-circle"
                        prefix="far"
                    />
                </base-tooltip>
            </div>

            <div v-if="isAvailable && showCredentials" class="flex h-full flex-col justify-between">
                <div v-if="showAuthorizationCredentials(authorization) && !isDone">
                    <base-alert
                        v-if="authorization.notice"
                        class="mt-4"
                        icon
                    >
                        <span v-html="authorization.notice" />
                    </base-alert>

                    <div
                        v-if="isFile(authorization)"
                        slot="field"
                        class="mt-4"
                    >
                        <span class="block pr-1 pl-3 text-sm font-semibold">
                            {{ $t('form file faq text') }}
                            <authorization-field-info
                                :text="authorization.description"
                                :faq-link="authorization.urlFaq"
                            />
                        </span>
                    </div>
                    <authorizationFieldWrapper
                        v-for="(credential, key) in extendedCredentials"
                        v-show="!credential.isHidden"
                        :required="credential.required"
                        :label="!isFile(authorization) ? credential.label : undefined"
                        :show-more-button="getLabelName(credential)"
                        :notice="credential.notice"
                        :hint-text="credential.description"
                        :hint-faq-link="credential.faqLink"
                        :hint-video="credential.videoLink"
                        :show-error="!credential.valid && isFormSubmitted"
                        :error-message="getErrorMessage(credential)"
                        :prevent="isFile(authorization)"
                        :break-note="isFile(authorization)"
                        :key="key"
                        show-not-required-identifier
                    >
                        <div
                            v-if="isFile(authorization)"
                            slot="field"
                            class="flex w-full flex-col items-center gap-y-2 border-b pb-4 font-normal"
                            @mouseover="onFileFocus(credential, authorization)"
                        >
                            <base-select
                                v-loader="!credential.entities"
                                class="w-full"
                                size="medium"
                                option-value="label"
                                :name="credential.entityLabel || ''"
                                :options="credential.entities || []"
                                :value="credential.entityLabel"
                                creatable
                                use-first
                                @change="value => changeEntitySelect(credential, {label: value})"
                            />

                            <div class="flex w-full items-center gap-x-2">
                                <base-button
                                    class="w-full"
                                    size="small"
                                    :label="fileButtonLabel(credential)"
                                    :icon="{ name: 'file-alt', prefix: 'far' }"
                                    @click="showFileUploaderPopup(credential)"
                                />

                                <base-tooltip
                                    :content="$t('form file button delete hint')"
                                    :disabled="extendedCredentials.length <= 1"
                                >
                                    <base-button
                                        size="small"
                                        :icon="{ name: 'trash-alt', prefix: 'far' }"
                                        :disabled="extendedCredentials.length <= 1"
                                        @click="removeFileBlock(key, credential)"
                                    />
                                </base-tooltip>
                            </div>
                        </div>
                        <div
                            v-else-if="credential.enum"
                            slot="field"
                        >
                            <base-select
                                class="w-full max-w-full grow font-normal"
                                size="large"
                                :name="credential.name"
                                :options="credential.enum"
                                :value="credential.value"
                                use-first
                                @change="value => updateCredentialDropDown(credential, value)"
                            />
                        </div>
                        <uiTextarea
                            v-else-if="credential.type === 'textarea'"
                            slot="field"
                            :name="namePrefix ? namePrefix + '[' + credential.name + ']' : credential.name"
                            :value="credential.value"
                            @update:value="value => credential.value = value"
                            @updateValue="updateValue"
                            :key="key"
                        />
                        <component
                            v-else
                            :is="getField(credential)"
                            slot="field"
                            :type="credential.type"
                            :name="namePrefix ? namePrefix + '[' + credential.name + ']' : credential.name"
                            :label="credential.label"
                            :placeholder="credential.placeholder"
                            :value="credential.value"
                            :max-items="credential.maxItems"
                            :errors="!credential.valid ? credential.errorMessage : []"
                            :has-error="!credential.valid"
                            :is-key="isKey(authorization)"
                            :required="credential.required"
                            @update:value="value => credential.value = value"
                            @updateValue="updateValue"
                            @onFocus="onFocus(credential)"
                        />
                    </authorizationFieldWrapper>
                    <div
                        v-if="isFile(authorization)"
                        class="mt-4 self-center"
                    >
                        <base-button
                            class="w-full"
                            :icon="{ name: 'plus-circle', prefix: 'fac' }"
                            :disabled="disabledAddingNewFileButton"
                            text
                            @click="addNewFileBlock"
                        >
                            {{ $t('form file button add new') }}
                        </base-button>
                    </div>
                </div>
                <div />
                <authorizationFieldWrapper
                    v-if="showSubmit"
                    :prevent="true"
                    :show-error="submitShowError"
                    :error-message="getSubmitErrorMessage()"
                >
                    <div
                        slot="field"
                        class="flex justify-between border-t pt-4"
                    >
                        <div v-if="!isDone">
                            <base-button
                                v-if="authorization.internalType === 'oAuth-external' && !oauthCallbackDone && state === 'active'"
                                class="platform-authorize"
                                type="primary"
                                round
                                :disabled="disabledSubmit"
                                :data-test="dataTest('authorize-button')"
                                @click="authorize"
                            >
                                {{ $t('form button authorize') }}
                            </base-button>
                            <base-button
                                v-else
                                class="step-submit"
                                type="primary"
                                round
                                :disabled="disabledSubmit || state !== 'active'"
                                :data-test="dataTest('test-connection-button')"
                                @click="submitHandler"
                            >
                                {{ connectButtonTitle }}
                            </base-button>
                        </div>
                        <div v-else class="w-full text-center">
                            <div>{{ $t('form button test connection verified') }}</div>
                        </div>
                    </div>
                </authorizationFieldWrapper>
            </div>
        </div>
        <file-uploader
            v-if="showAuthorizationCredentials(authorization) && showFileUploader"
            v-model="showFileUploader"
            :data-path="dataPath"
            :platform="selectedCRM"
            :selected-file="selectedCredentialFile"
            :fields="selectedCredentialFields"
            :selected-field-id="selectedCredentialFieldId"
            @upload="saveFileUploadData"
        />
        <o-auth-modal ref="oAuthModal" />
    </div>
</template>

<script>
import Vue from 'vue';
import {
    isEmpty,
    map,
    extend,
    find,
    each,
    has,
    uniqueId,
    filter,
    get,
} from 'lodash';
import { mapActions, mapMutations, mapState } from 'vuex';
import notify from '@/components/base-components/components/notification/notify';
import OAuthModal from '@/components/o-auth/modal';
import convertToValidDropDownList from '../../assets/util/convert-to-valid-drop-down-list';
import authorizationFieldWrapper from '../authorization-field-wrapper/authorization-field-wrapper';
import authorizationField from '../authorization-field/authorization-field';
import authorizationFieldInfo from '../authorization-field-info/authorization-field-info';
import authorizationMultiField from '../authorization-multifield/authorization-multifield';
import getData from '../get-data/get-data';
import uiTextarea from '../ui-textarea/ui-textarea';
import fileUploader from '../file-uploader/file-uploader';
import isAvailableAuthorization from '../../assets/util/is-available-authorization';
import capitalize from '../../assets/util/capitalize';

export default {
    name:       'AuthorizationTabContent',
    extends:    getData,
    components: {
        OAuthModal,
        authorizationFieldWrapper,
        authorizationField,
        authorizationFieldInfo,
        authorizationMultiField,
        uiTextarea,
        fileUploader,
    },
    props:      {
        authorization:                {
            type: Object,
        },
        active:                       {
            type:    Boolean,
            default: false,
        },
        update:                       {
            type:    Boolean,
            default: false,
        },
        selectedCRM:                  {
            type:    String,
            default: '',
        },
        isSingle:                     {
            type:    Boolean,
            default: false,
        },
        namePrefix:                   {
            type:    String,
            default: '',
        },
        isSubmitted:                  {
            type:    Boolean,
            default: false,
        },
        showSubmit:                   {
            type:    Boolean,
            default: true,
        },
        showAuthorizationDescription: {
            type:    Boolean,
            default: true,
        },
        showFilesCredentials:         {
            type:    Boolean,
            default: true,
        },
        predefinedData:               {
            type: [Object, Array],
        },
        prefix:                       {
            type:    String,
            default: '',
            validator(value) {
                return [
                    'source',
                    'target',
                    '',
                ].indexOf(value) !== -1;
            },
        },
        state:                        {
            type:    String,
            default: 'active',
            validator(type) {
                return ['active', 'done', 'disabled'].indexOf(type) !== -1;
            },
        },
        product:                      {
            type: String,
        },
    },
    data() {
        return {
            emptyFieldMessage:     'tmp Please, fill this field to continue',
            emptyFileFieldMessage: 'tmp Please, choose file to continue',
            disabledSubmit:        false,
            forceUpdate:           this.update,
            entities:              [],
            submittedData:         undefined,
            extendedCredentials:   [],
            isFormSubmitted:       this.isSubmitted,
            submitShowError:       false,
            submitErrorMessage:    '',
            faqContent:            undefined,
            showFileUploader:      false,
            filePathUpload:        undefined,
            selectedCredential:    undefined,
            showCredentials:       true,
            oauthCallbackSubmit:   false,
            oauthCallbackDone:     false,
            oauthUrl:              undefined,
        };
    },
    watch:    {
        active() {
            this.faqContent = undefined;
            if (this.active === true) {
                this.subscribe();
            } else {
                this.unSubscribe();
            }
        },
        authorization() {
            this.faqContent = undefined;
            this.extendCredentials();
        },
        extendedCredentials() {
            this.checkSubmitButton();
            this.updateCredentialData();
        },
        entities: {
            handler() {
                this.updateFileEntities();
            },
            deep: true,
        },
        isSubmitted() {
            this.isFormSubmitted = this.isSubmitted;
            this.validateFields();
        },
    },
    computed: {
        ...mapState('platform', {
            platformData: 'data',
        }),
        isAvailable() {
            return isAvailableAuthorization(this.extendedCredentials, this.prefix);
        },
        isSupported() {
            return this.authorization[`is${capitalize(this.prefix)}Supported`];
        },
        id() {
            return uniqueId('authorizationTab');
        },
        sourcePlatformType() {
            return get(this.platformData, ['source', 'type']);
        },
        isDone() {
            return this.state === 'done';
        },
        connectButtonTitle() {
            return this.authorization.type === 'file' ? this.$t('form button proceed') : this.$t('form button connect');
        },
        disabledAddingNewFileButton() {
            return this.extendedCredentials.some(item => isEmpty(item.entityName));
        },
        selectedCredentialFile() {
            return this.selectedCredential?.value;
        },
        selectedCredentialFields() {
            return this.selectedCredential?.fields;
        },
        selectedCredentialFieldId() {
            return this.selectedCredential?.fieldId;
        },
    },
    mounted() {
        const data = this.getData();

        if (data && this.prefix && has(data, this.prefix)) {
            this.submittedData = data[this.prefix];
        }
        this.extendCredentials();
        this.updateCredentialData();

        if (this.state === 'active') {
            window.eventBus.$on(this.$store.state.eventConstants.formValidation, () => {
                this.validateFields();
            });
            window.eventBus.$on(
                this.$store.state.eventConstants.credentialValidationFail,
                (authorizationType, authField) => {
                    if (this.authorization.type === authorizationType) {
                        const credential = find(
                            this.extendedCredentials,
                            cred => cred.name === authField.name,
                        );
                        credential.valid = false;
                        credential.errorMessage = authField.errorMessage;
                    }
                },
            );
            this.subscribe();
        }
    },
    beforeDestroy() {
        this.unSubscribe();
    },
    methods: {
        ...mapMutations({
            addEvent:  'events/addEvent',
            dropEvent: 'events/dropEvent',
        }),
        ...mapActions({
            showLoader: 'loader/SHOW',
            hideLoader: 'loader/HIDE',
        }),
        subscribe() {
            this.addEvent(
                {
                    id:       this.id,
                    key:      'keypress',
                    callback: this.keypressTab,
                },
            );
        },
        unSubscribe() {
            this.dropEvent(
                {
                    id:       this.id,
                    key:      'keypress',
                    callback: this.keypressTab,
                },
            );
        },
        keypressTab(event) {
            const key = event.keyCode;
            if (key === 13 && this.active && this.state === 'active') {
                if (this.disabledSubmit === false) {
                    if (this.authorization.internalType === 'oAuth-external' && !this.oauthCallbackDone) {
                        this.authorize();
                    } else {
                        this.submitHandler();
                    }
                }
            }
        },
        changeShowLoading(show) {
            if (show) {
                this.showLoader({ dot: true, showMessage: false });
            } else {
                this.hideLoader();
            }
        },
        oauthCallback({ status, message, data }) {
            if (!this.active) {
                return;
            }

            if (status !== true) {
                window.eventBus.$emit(
                    this.$store.state.eventConstants.showPageMessage,
                    {
                        type:    'danger',
                        content: message,
                    },
                );
                this.changeShowLoading(false);
                return;
            }

            each(data, (credential, name) => {
                const field = find(this.extendedCredentials, cred => cred.name === name);
                if (field) {
                    field.value = credential;
                }
            });

            this.oauthCallbackDone = true;
            this.disabledSubmit = false;
            this.updateCredentialData();
            this.submitOauth();
        },
        changeEntitySelect(credential, option) {
            this.changeEntityState(credential.entityLabel, 'selected', false);
            this.changeEntityState(credential.entityLabel, 'disabled', false);

            this.changeEntityState(option.label, 'selected', true);
            this.changeEntityState(option.label, 'disabled', true);

            const existing = find(this.entities, entity => entity.label === option.label);

            if (existing) {
                option.name = existing.name;
            } else {
                option.name = `c_${option.label.replace(/\W+/g, '')}`;
                option.selected = true;
                option.disabled = true;

                this.entities.push(option);
            }

            credential.entityLabel = option.label;
            credential.entityName = option.name;

            this.updateCredentialData();
        },
        changeEntityState(entityLabel, property, value) {
            this.entities.some((entity) => {
                if (entity.label === entityLabel) {
                    entity[property] = value;
                }

                return entity.label === entityLabel;
            });
        },
        showFileUploaderPopup(credential) {
            this.selectedCredential = credential;
            this.showFileUploader = true;
        },
        saveFileUploadData({ path, fields, fieldId }) {
            this.extendedCredentials.forEach((item) => {
                if (item.entityName === this.selectedCredential.entityName) {
                    item.value = path;
                    item.fields = fields;
                    item.fieldId = fieldId;

                    this.validateFields();
                    this.updateCredentialData();
                }
            });
            this.selectedCredential = undefined;
        },
        addNewFileBlock() {
            const fileCredential = this.getNewFileCredential();
            const followingEntity = this.getFollowingEntity();

            fileCredential.value = '';
            fileCredential.fieldId = undefined;
            fileCredential.fields = [];
            fileCredential.entityLabel = followingEntity.label;
            fileCredential.entityName = followingEntity.name;
            fileCredential.entities = this.createFileFieldEntities(fileCredential.entityLabel);
            fileCredential.valid = true;
            fileCredential.errorMessage = '';

            this.extendedCredentials.push(fileCredential);

            if (this.submitShowError === true) {
                this.submitShowError = false;
                this.submitErrorMessage = '';
                this.disabledSubmit = false;
            }
        },
        removeFileBlock(index, credential) {
            this.extendedCredentials.splice(index, 1);

            this.changeEntityState(credential.entityLabel, 'selected', false);
            this.changeEntityState(credential.entityLabel, 'disabled', false);
            // Need to force update credentials
            this.showCredentials = false;
            this.$nextTick(() => {
                this.showCredentials = true;
            });

            if (Object.keys(this.extendedCredentials).length === 0) {
                this.submitShowError = true;
                this.submitErrorMessage = 'tmp Please, select your Source files to continue';
            } else {
                this.submitShowError = false;
                this.submitErrorMessage = '';
            }

            this.validateFields();
        },
        createFileFieldEntities(currentFieldValue) {
            return this.entities.map(entity => ({
                ...entity,
                disabled: entity.label === currentFieldValue ? false : entity.disabled,
            })).sort((one, two) => {
                if (one.disabled === two.disabled) return 0;

                return one.disabled ? 1 : -1;
            });
        },
        updateFileEntities() {
            this.extendedCredentials.forEach((credential) => {
                credential.entities = this.createFileFieldEntities(credential.entityLabel);
            });
        },
        async transformEntities() {
            const entities = this.getData('entities') ?? await this.getEntities();

            map(entities, (entity, key) => {
                const credential = this.extendedCredentials.find(i => i.entityName === key);

                this.entities.push(
                    {
                        name:     key,
                        label:    entity.label,
                        selected: credential ? true : entity.selected,
                        disabled: credential ? true : entity.selected, // if entity is selected then it should be disabled
                    },
                );
            });

            if (this.isFile(this.authorization) && this.showFilesCredentials) {
                this.checkForEmptyFileCredentials();
            }
        },
        async getEntities() {
            try {
                return Vue.prototype.$http.get('/entities');
            } catch {
                notify.error({
                    title:    this.$t('request error notification'),
                    message:  this.$t('tmp An error occurred when getting entities names'),
                    duration: 7500,
                });
            }
        },
        updateValue() {
            this.checkSubmitButton();
            this.updateCredentialData();
        },
        async showOauthPopup() {
            const result = await this.$refs.oAuthModal.process(
                this.authorization.authorizationKey ?? this.selectedCRM,
                {
                    keywords:    [this.product, this.sourcePlatformType],
                    credentials: this.getOauthCredentials(),
                },
            );

            if (!result) return;

            this.oauthCallback(result);
        },
        submitOauth() {
            if (this.disabledSubmit === false) {
                this.submitTab(this.authorization.type);
            }
        },
        getUrl() {
            const field = find(this.extendedCredentials, cred => cred.name === 'url');
            return this.prepareUrl(field.value);
        },
        prepareUrl(url) {
            let prepared = url.trim();

            if (prepared.indexOf('https://') === -1 && prepared.indexOf('http://') === -1) {
                prepared = `https://${prepared}`;
            }

            return prepared;
        },
        extendCredentials() {
            this.extendedCredentials = map(
                this.authorization.credential,
                (element) => {
                    const value = element.isMultiple ? [''] : '';
                    const entityName = '';
                    const entityLabel = '';
                    return extend({}, element, {
                        value,
                        entityName,
                        entityLabel,
                        enum:  this.convertToValidEnum(element.enum),
                        valid: true,
                    });
                },
            );
            this.setSubmittedData();
            this.transformEntities();
        },
        setSubmittedData() {
            if (this.isSubmittedDataExists()) {
                const auth = this.submittedData.credential[this.authorization.type];
                this.extendedCredentials.forEach(
                    credential => this.enrichCredential(auth, credential),
                );
            } else if (this.isPredefinedDataExists()) {
                this.extendedCredentials.forEach(
                    (credential) => {
                        if (has(this.predefinedData, credential.name)) {
                            credential.value = this.predefinedData[credential.name];
                        }
                    },
                );
            }
        },
        enrichCredential(credential, submittedCredentialData) {
            const e = submittedCredentialData;

            if (!has(credential, e.name)) {
                return true;
            }

            if (!this.isFile(this.authorization)) {
                e.value = credential[e.name];
                if (e.enum) {
                    e.enum.forEach((item) => {
                        item.selected = e.value === item.name;
                    });
                }
                return true;
            }

            e.value = credential[e.name][0].value;
            e.entityName = credential[e.name][0].name;
            e.entityLabel = credential[e.name][0].label;
            e.fieldId = credential[e.name][0]?.fieldId ?? null;
            e.fields = credential[e.name][0]?.fields ?? [];

            if (credential[e.name].length === 1) {
                return true;
            }

            credential[e.name].forEach(
                (submittedFileCredential, index) => this.addSubmittedFileBlocks(
                    submittedFileCredential,
                    index,
                ),
            );

            return true;
        },
        addSubmittedFileBlocks(submittedFileCredential, index) {
            if (index === 0) {
                return true;
            }

            const newCredential = this.getNewFileCredential(
                {
                    value:       submittedFileCredential.value,
                    entityName:  submittedFileCredential.name,
                    entityLabel: submittedFileCredential.label,
                    fieldId:     submittedFileCredential?.fieldId ?? null,
                    fields:      submittedFileCredential?.fields ?? [],
                },
            );
            this.extendedCredentials.push(newCredential);

            return true;
        },
        isSubmittedDataExists() {
            return this.submittedData
                && this.submittedData.platform
                && this.selectedCRM === this.submittedData.platform.type
                && this.submittedData.credential
                && has(this.submittedData.credential, this.authorization.type);
        },
        isPredefinedDataExists() {
            return !!this.predefinedData;
        },
        getNewFileCredential(predefinedData = {}) {
            return { ...this.extendedCredentials[0], ...predefinedData };
        },
        authorize() {
            this.showOauthPopup();
        },
        submitHandler() {
            this.isFormSubmitted = true;
            const validationResult = this.validateFields();

            if (validationResult === true) {
                this.submitTab(this.authorization.type);
            } else if (this.authorization.internalType === 'oAuth-external') {
                this.authorize();
            }
        },
        submitTab(authorizationType) {
            this.$emit('submitTab', authorizationType, null, (data, response) => {
                this.oauthCallbackDone = false;
                this.findCredentialsErrors(
                    response.data.detail.fields,
                );
                setTimeout(() => {
                    const element = document.querySelector('.page-error');
                    if (element) {
                        element.scrollIntoView({ behavior: 'smooth' });
                    }
                }, 500);
            });
        },
        findCredentialsErrors(errors) {
            each(errors.credential, (error, key) => {
                let field = find(this.extendedCredentials, cred => cred.name === key);

                if (!field) {
                    field = find(this.extendedCredentials, cred => cred.entityName === key);

                    if (!field) {
                        return true;
                    }
                }

                field.valid = false;
                field.errorMessage = this.decodeMessage(error);

                window.eventBus.$emit(
                    this.$store.state.eventConstants.credentialValidationFail,
                    field,
                );

                return true;
            });
        },
        decodeMessage(errors) {
            if (typeof errors === 'string') {
                return decodeURIComponent(errors);
            }

            each(errors, (error, key) => {
                if (typeof error === 'string') {
                    errors[key] = decodeURIComponent(error);
                } else {
                    each(error, (item, itemKey) => {
                        errors[key][itemKey] = decodeURIComponent(item);
                    });
                }
            });

            return errors;
        },
        validateFields() {
            if (this.isFile(this.authorization) && this.showFilesCredentials === false) {
                return true;
            }

            each(
                this.extendedCredentials,
                (element) => {
                    if (element.isHidden === true || element.required === false) {
                        element.valid = true;
                    } else {
                        element.valid = element.value !== '' && element.value !== false;
                    }
                },
            );
            const hasInvalidField = !!find(
                this.extendedCredentials,
                element => element.valid === false,
            );
            const length = Object.keys(this.extendedCredentials).length;
            const validationResult = length > 0 && !hasInvalidField;

            this.disabledSubmit = !validationResult;
            this.updateCredentialData();

            return validationResult;
        },
        checkSubmitButton() {
            this.disabledSubmit = false;
            each(
                this.extendedCredentials,
                (element) => {
                    const mustPresent = element.isHidden === false && element.required === true;
                    const emptyValue = element.value === '' || element.value === false;
                    if (emptyValue && mustPresent) {
                        this.disabledSubmit = true;
                    }
                },
            );
        },
        updateCredentialData() {
            if (Array.isArray(this.extendedCredentials)) {
                this.$emit(
                    'updateCredential',
                    this.authorization.type,
                    this.extendedCredentials,
                );
            }

            this.updateAddingNewFileButton();
        },
        updateAddingNewFileButton() {
            // TODO uncomment when https://trujay-company.atlassian.net/browse/DATATOCRM-11305 will be completed
            // this.disabledAddingNewFileButton = this.extendedCredentials.some(item => isEmpty(item.value));
        },
        checkForEmptyFileCredentials() {
            each(this.extendedCredentials, (c) => {
                const credential = c;

                if (credential.entityName === '') {
                    const entity = this.getFollowingEntity();

                    credential.entityLabel = entity.label;
                    credential.entityName = entity.name;
                }

                return false;
            });
        },
        getFollowingEntity() {
            const followingEntity = find(this.entities, entity => entity.selected === false);

            if (followingEntity === undefined) {
                return { name: '', label: '' };
            }

            this.changeEntityState(followingEntity.label, 'selected', true);
            this.changeEntityState(followingEntity.label, 'disabled', true);

            return followingEntity;
        },
        isFile(authorization) {
            // ToDo check for field type field.type === 'file'
            return authorization ? authorization.type === 'file' : false;
        },
        isKey(authorization) {
            return authorization ? authorization.internalType === 'key' : false;
        },
        showAuthorizationCredentials(authorization) {
            return this.isFile(authorization) === true ? this.showFilesCredentials : true;
        },
        convertToValidEnum(credentialEnum) {
            if (!credentialEnum) {
                return null;
            }

            return convertToValidDropDownList(credentialEnum);
        },
        updateCredentialDropDown(credential, value) {
            credential.value = value;
            this.checkSubmitButton();
            this.updateCredentialData();
        },
        onFocus(credential) {
            this.$emit('onFieldFocus', credential);
        },
        getLabelName(credential) {
            return !isEmpty(credential.description);
        },
        getErrorMessage(credential) {
            if (credential.errorMessage) {
                const errorKey = find(
                    Object.keys(credential.errorMessage),
                    key => key !== parseInt(key, 10).toString(),
                );

                if (errorKey) {
                    if (credential.errorMessage[errorKey] instanceof Array) {
                        return credential.errorMessage[errorKey][0];
                    }

                    return credential.errorMessage[errorKey];
                }

                return null;
            }

            return (this.isFile(this.authorization)
                ? this.$t(this.emptyFileFieldMessage)
                : this.$t(this.emptyFieldMessage));
        },
        getSubmitErrorMessage() {
            return this.submitErrorMessage ? this.$t(this.submitErrorMessage) : '';
        },
        getField(credential) {
            return credential.isMultiple ? authorizationMultiField : authorizationField;
        },
        onFileFocus(credential, authorization) {
            if (this.isFile(authorization) && credential.description !== this.faqContent) {
                this.faqContent = credential.description;
                this.onFocus(credential);
            }
        },
        fileButtonLabel(credential) {
            if (this.isFile(this.authorization) && credential.value) {
                return credential.value.split('\\').pop().split('/').pop();
            }

            return this.$t('form file button upload hint');
        },
        getOauthCredentials() {
            const result = {};
            const filtered = filter(this.extendedCredentials, credential => ['string', 'url'].includes(credential.type));

            map(filtered, (credential) => {
                this.$set(
                    result,
                    credential.name,
                    credential.type === 'url' ? this.prepareUrl(credential.value) : credential.value,
                );
            });

            return result;
        },
    },
};
</script>
