<template>
    <list-wrapper
        :title="$t('home migrations title')"
        :title-message="$t('migration list message')"
    >
        <template #list="{ list }">
            <product-list :list="list">
                <template #item="{ item }">
                    <migration-list-item :migration="item" />
                </template>
            </product-list>
        </template>
        <template #actions>
            <div
                class="flex flex-col flex-wrap items-end justify-end space-x-0 space-y-2 sm:space-x-3 sm:flex-row lg:flex-col lg:justify-start xl:space-x-3 xl:space-y-0 xl:flex-row xl:flex-nowrap xl:items-start"
            >
                <base-button
                    round
                    @click="$router.push({ name: 'migrationCustomMigrationQuote' })"
                >
                    {{ $t('custom migration quote title') }}
                </base-button>
                <base-button
                    type="primary"
                    round
                    @click="$router.push({ name: 'migrationWizard' })"
                >
                    {{ $t('start new') }}
                </base-button>
            </div>
        </template>
        <template #empty>
            <base-button
                type="primary"
                round
                @click="$router.push({ name: 'migrationWizard' })"
            >
                <span class="px-4 text-xl leading-10">
                    {{ $t('migration start new') }}
                </span>
            </base-button>
            <span class="text-center text-xl">
                {{ $t('migration start new message') }}
            </span>
            <div class="flex items-center justify-center text-sm text-gray-500 space-x-2">
                <span>
                    {{ $t('migration start new message or') }}
                </span>
                <router-link class="lowercase" :to="{ name: 'migrationCustomMigrationQuote' }">
                    {{ $t('custom migration quote button title') }}
                </router-link>
            </div>
        </template>
    </list-wrapper>
</template>

<script>
import ListWrapper from '@/pages/components/list-wrapper';
import ProductList from '@/components/app/product/list';
import MigrationListItem from '@/components/migration/components/list-item';

export default {
    name:       'MigrationListPage',
    components: {
        ListWrapper,
        ProductList,
        MigrationListItem,
    },
};
</script>
