export const DEFAULT_CALENDAR_COLOR = '#f87171';

export const CALENDAR_FIELD = Object.freeze({
    ID:             'id',
    CALENDAH:       'calendah',
    OBJECT:         'object',
    LABEL:          'label',
    FIELD:          'field',
    FILTER:         'filter',
    RECORD_LABEL:   'recordLabel',
    DETAILS_LABEL:  'detailsLabel',
    DETAILS_FIELDS: 'detailsFields',
    FIELDS:         'fields',
    TYPE:           'type',
    CREATED_BY:     'createdBy',
    CREATED_AT:     'createdAt',
    UPDATED_AT:     'updatedAt',
});

export const VIEW_FIELD = Object.freeze({
    ID:         'id',
    CALENDAR:   'calendar',
    LABEL:      'label',
    ACTIVE:     'active',
    COLOR:      'color',
    CREATED_BY: 'createdBy',
    CREATED_AT: 'createdAt',
    UPDATED_AT: 'updatedAt',
});

export const CALENDAR_TYPE = Object.freeze({
    PRIVATE:     'private',
    SHARED:      'shared',
    SHARED_READ: 'shared read',
});

export const CALENDAR_TYPE_LABEL = Object.freeze({
    [CALENDAR_TYPE.PRIVATE]:     'Private',
    [CALENDAR_TYPE.SHARED]:      'Shared',
    [CALENDAR_TYPE.SHARED_READ]: 'View Only',
});

export const USER_FIELD = Object.freeze({
    ID:               'id',
    CALENDAH:         'calendah',
    PLATFORM_USER_ID: 'platformUserId',
    EMAIL:            'email',
    LAST_USED_AT:     'lastUsedAt',
    CREATED_AT:       'createdAt',
    UPDATED_AT:       'updatedAt',
    IS_OWNER:         'isOwner',
});

export const FIELD_FORMAT = Object.freeze({
    NUMBER:         'number',
    CURRENCY:       'currency',
    DROPDOWN:       'dropdown',
    DATETIME:       'datetime',
    DATE:           'date',
    STRING:         'string',
    HTML:           'html',
    MULTI_DROPDOWN: 'multi dropdown',
    OWNER:          'owner',
});

export const USER_SETTINGS = Object.freeze({
    FIRST_DAY_OF_WEEK:     'firstDayOfWeek',
    MONTHLY_EXTENDED_VIEW: 'monthlyExtendedView',
});
