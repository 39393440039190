export default (x1, y1, x2, y2, epsilon) => {
    const curveX = (t) => {
        const v = 1 - t;
        return 3 * v * v * t * x1 + 3 * v * t * t * x2 + t * t * t;
    };

    const curveY = (t) => {
        const v = 1 - t;
        return 3 * v * v * t * y1 + 3 * v * t * t * y2 + t * t * t;
    };

    const derivativeCurveX = (t) => {
        const v = 1 - t;
        return 3 * (2 * (t - 1) * t + v * v) * x1 + 3 * (-t * t * t + 2 * v * t) * x2;
    };

    return (t) => {
        const x = t;
        let t0;
        let t1;
        let t2;
        let xx2;
        let d2;
        let i;

        for (t2 = x, i = 0; i < 8; i++) {
            xx2 = curveX(t2) - x;
            if (Math.abs(xx2) < epsilon) return curveY(t2);
            d2 = derivativeCurveX(t2);
            if (Math.abs(d2) < 1e-6) break;
            t2 -= xx2 / d2;
        }

        t0 = 0;
        t1 = 1;
        t2 = x;

        if (t2 < t0) return curveY(t0);
        if (t2 > t1) return curveY(t1);

        while (t0 < t1) {
            xx2 = curveX(t2);
            if (Math.abs(xx2 - x) < epsilon) return curveY(t2);
            if (x > xx2) t0 = t2;
            else t1 = t2;
            t2 = (t1 - t0) * 0.5 + t0;
        }

        return curveY(t2);
    };
};
