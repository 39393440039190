import Vue from 'vue';
import qs from 'qs';

const baseRoute = '/connection';

export async function connectionGet(connectionId) {
    return Vue.prototype.$http.get(`${baseRoute}/${connectionId}`);
}

export async function connectionGetList(params = {}) {
    return Vue.prototype.$http.post(`${baseRoute}/list`, qs.stringify(params));
}

export async function connectionIsAuthorized(connectionId) {
    return Vue.prototype.$http.get(`${baseRoute}/${connectionId}/is-authorized`);
}

export async function connectionRename(connectionId, name) {
    return Vue.prototype.$http.post(`${baseRoute}/${connectionId}/rename`, qs.stringify({ name }));
}

export async function connectionGetConnectData(connectionId, direction, origin) {
    return Vue.prototype.$http.post(`${baseRoute}/connect-data`, qs.stringify({ id: connectionId, direction, origin }));
}

export async function connectionConnect(data) {
    return Vue.prototype.$http.post(`${baseRoute}/connect`, qs.stringify(data));
}

export async function connectionDisconnect(connectionId) {
    return Vue.prototype.$http.get(`${baseRoute}/${connectionId}/disconnect`, {});
}
