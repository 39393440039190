import Vue from 'vue';

function castArray(value) {
    return Array.isArray(value) ? value : [value];
}

function set(target, path, value, merge = false) {
    const [key, ...tail] = castArray(path);

    if (!target[key]) {
        Vue.set(target, key, {});
    }

    if (tail.length) return set(target[key], tail, value);

    if (merge && value && typeof value === 'object' && !Array.isArray(value)) {
        Vue.set(target, key, { ...target[key], ...value });
    } else {
        Vue.set(target, key, value);
    }
}

function get(target, path) {
    const [key, ...tail] = castArray(path);

    if (target[key] && tail.length) return get(target[key], tail);

    return target[key];
}

function del(target, path) {
    const [key, ...tail] = castArray(path);

    if (!target[key]) return;

    if (tail.length) return del(target[key], tail);

    Vue.delete(target, key);
}

export default {
    set,
    get,
    del,
};
