const prefix = 'fac';
const iconName = 'arrow-right';
const width = 512;
const height = 512;
const ligatures = [];
const unicode = null;
const svgPathData = 'M506.247 265.339L394.085 377.255C389.262 382.248 381.204 382.248 376.2 377.255C371.365 372.442 371.365 364.401 376.2 359.408L466.891 269.084H311.244H140.645H15.5212C8.54497 269.084 3 263.371 3 256.41C3 249.449 8.54497 243.916 15.5212 243.905H140.645H311.244H466.891L376.2 153.412C371.365 148.598 371.365 140.569 376.2 135.745C381.204 130.752 389.251 130.752 394.085 135.745L506.247 247.672C511.251 252.485 511.251 260.515 506.247 265.339V265.339Z';

export const definition = {
    prefix,
    iconName,
    icon: [
        width,
        height,
        ligatures,
        unicode,
        svgPathData,
    ],
};

export { definition as faArrowRight };
