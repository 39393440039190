<template>
    <div class="flex flex-col gap-y-10 px-10 py-5 mx-auto my-14 w-full max-w-screen-xl bg-white rounded-xl">
        <div class="flex flex-col gap-y-4 justify-center items-center">
            <h1>
                {{ $t('plugin title', { crm }) }}
            </h1>
            <p v-if="subtitleText">
                {{ subtitleText }}
            </p>
            <a :href="buttonLink" target="_blank"
               class="inline-flex justify-center items-center px-6 py-4 text-white no-underline uppercase bg-blue-500 rounded shadow transition-all duration-300 ease-in-out hover:bg-blue-400 focus:bg-blue-400 active:bg-blue-400 hover:text-white focus:text-white active:text-white"
            >
                {{ $t('plugin wizard button') }}
            </a>
            <p class="text-lg">
                {{ $t('plugin follow steps') }}
            </p>
        </div>
        <div class="flex flex-col divide-y">
            <div
                v-for="(step, key) in steps"
                :key="key"
                class="flex items-center py-10"
                :class="{ 'flex-row-reverse': key % 2 !== 0 }"
            >
                <div class="flex flex-1 gap-x-5 items-center">
                    <div class="flex shrink-0 justify-center items-center w-12 h-12 text-xl text-white bg-blue-400 rounded-full border-2 border-blue-500">
                        {{ key + 1 }}
                    </div>
                    <p class="max-w-xl" v-html="step.text" />
                </div>
                <div class="flex flex-1 justify-center items-center">
                    <img :src="step.url" :alt="step.alt" class="w-96 cursor-pointer" @click="zoomImg(key)">
                </div>
            </div>
        </div>
        <div class="flex justify-center items-center mb-5">
            <a :href="buttonLink" target="_blank"
               class="inline-flex justify-center items-center px-6 py-4 text-white no-underline uppercase bg-blue-500 rounded shadow transition-all duration-300 ease-in-out hover:bg-blue-400 focus:bg-blue-400 active:bg-blue-400 hover:text-white focus:text-white active:text-white"
            >
                {{ $t('plugin wizard button') }}
            </a>
        </div>
        <uiGallery :items="steps" :pointer="pointer" :show-gallery="showGallery" @closeGallery="closeGallery" />
    </div>
</template>

<script>
import uiGallery from '../ui-gallery/ui-gallery';

export default {
    name:       'HiwPage',
    components: {
        uiGallery,
    },
    props:      {
        crm:          {
            type:     String,
            required: true,
        },
        crmType:      {
            type:     String,
            required: true,
        },
        sourceCRM:    {
            type:    String,
            default: 'SuiteCRM',
        },
        steps:        {
            type:     Array,
            required: true,
        },
        medium:       {
            type: String,
        },
        campaign:     {
            type: String,
        },
        source:       {
            type:    String,
            default: 'go-to-wizard-up',
        },
        subtitleText: {
            type: String,
        },
    },
    data() {
        return {
            pointer:     0,
            showGallery: false,
        };
    },
    computed: {
        buttonLink() {
            return `/migration/wizard?source=${this.sourceCRM}&target=${this.crmType}&utm_source=${this.source}&utm_medium=${this.medium}&utm_campaign=${this.campaign}`;
        },
    },
    methods: {
        zoomImg(key) {
            this.showGallery = true;
            this.pointer = key;
        },
        closeGallery() {
            this.showGallery = false;
        },
    },
};
</script>
