const prefix = 'fac';
const iconName = 'plus-circle-many';
const width = 1009;
const height = 512;
const ligatures = [];
const unicode = null;
const svgPathData = 'M282.3,123.9c-48,76-47.9,188.7,0.3,264.8c-7.4,11.9-26.8,8.3-39,8.5c-26-7.1-15.6-24.5-17.8-45.5c0-26.9,0-64.9,0-65.1  c-90.9-1.8-113.8,10.9-110.7-31.8c-1.5-33.3,18.8-28.2,44.1-28.5c26.9,0,66.4,0,66.6,0c1.6-77.5-8.4-110,20.3-110.7  C259.7,116,274.5,112.8,282.3,123.9z M326.5,68.8C197,21,63.1,112.3,56.2,246.4c-6.4,147.6,139,246.1,270.3,196.9  c13.2,12.5,27.7,23.7,43.3,33.2c-165.9,84-357.3-33-361.8-215.6C5.6,68.6,206.1-48.5,369.8,35.6C354.2,45.1,339.7,56.2,326.5,68.8z   M531.3,123.9c-48,76-47.9,188.7,0.3,264.8c-7.4,11.9-26.8,8.3-39,8.5c-26-7.1-15.6-24.5-17.8-45.5c0-26.9,0-64.9,0-65.1  c-90.9-1.8-113.8,10.9-110.7-31.8c-1.5-33.3,18.8-28.2,44.1-28.5c26.9,0,66.4,0,66.6,0c1.6-77.5-8.4-110,20.3-110.7  C508.7,116,523.5,112.8,531.3,123.9z M575.5,68.8C446,21,312.1,112.3,305.2,246.4c-6.4,147.6,139,246.1,270.3,196.9  c13.2,12.5,27.7,23.7,43.3,33.2c-165.9,84-357.3-33-361.8-215.6c-2.5-192.2,198-309.3,361.8-225.2C603.2,45.1,588.7,56.2,575.5,68.8  z M753,8c-331.4,14.8-327.1,483.2,0,496C1084.4,489.2,1080.1,20.8,753,8z M753,456c-267.8-12.2-263.2-390,0-400  C1020.8,68.2,1016.2,446,753,456z M873.8,226.3h-90.6c-1.8-90.9,10.9-113.8-31.8-110.7c-33.3-1.5-28.2,18.8-28.5,44.1  c0,26.9,0,66.4,0,66.6c-90.9,1.8-113.8-10.9-110.7,31.8c-1.5,33.3,18.8,28.2,44.1,28.5c26.9,0,66.4,0,66.6,0  c1.8,90.9-10.9,113.8,31.8,110.7c33.3,1.5,28.2-18.8,28.5-44.1c0-26.9,0-66.4,0-66.6c11.9-1.8,101,5.1,104.8-5.9  C900.4,265.9,896.8,225,873.8,226.3z';

export const definition = {
    prefix,
    iconName,
    icon: [
        width,
        height,
        ligatures,
        unicode,
        svgPathData,
    ],
};

export { definition as faPlusCircleMany };
