import { isEmpty } from 'lodash';

export default {
    namespaced: true,
    state:      {},
    mutations:  {
        addEvent(state, payload) {
            const { id, key, callback } = payload;
            if (isEmpty(state[key])) {
                state[key] = [];
            } else {
                window.removeEventListener(key, state[key][state[key].length - 1].callback);
            }

            state[key].push(
                {
                    id,
                    callback,
                },
            );
            window.addEventListener(key, callback);
        },
        dropEvent(state, payload) {
            const { id, key } = payload;
            if (isEmpty(state[key])) {
                return;
            }

            state[key] = state[key].filter((value) => {
                if (value.id === id) {
                    window.removeEventListener(key, value.callback);
                    return false;
                }

                return true;
            });
            if (isEmpty(state[key]) === false) {
                window.addEventListener(key, state[key][state[key].length - 1].callback);
            }
        },
    },
};
