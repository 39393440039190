<template>
    <div class="flex flex-col gap-y-4">
        <base-scroll
            wrap-class="h-full max-h-64"
            view-class="flex flex-col gap-y-1"
        >
            <div
                v-for="(credential, index) in fieldValue"
                :key="index"
            >
                <authorizationField
                    :type="type"
                    :name="`${name}_${index}`"
                    :required="required"
                    :placeholder="placeholder"
                    :value="credential"
                    :action-delete="showDelete"
                    :apply-focus="applyFocus(index)"
                    :has-error="hasError(index)"
                    :is-key="isKey"
                    @onFocus="focus"
                    @update:value="value => fieldValue[index] = value"
                    @updateValue="updateValue"
                    @onDelete="removeField(index)"
                />
                <fieldError
                    v-if="errors && errors[index.toString()]"
                    :error-text="errors[index.toString()][0]"
                />
            </div>
        </base-scroll>
        <div class="space-x-1">
            <base-button
                v-if="showAddButton"
                size="small"
                round
                :data-test="dataTest('add-field')"
                @click="addField"
            >
                <base-icon name="plus-circle" prefix="fac" size="sm" />
                {{ $t('form field button add new') }}
            </base-button>
            <base-button
                v-if="showAddButton"
                size="small"
                round
                :data-test="dataTest('add-many')"
                @click="editMany"
            >
                <base-icon name="plus-circle-many" prefix="fac" size="sm" />
                {{ $t('form field button edit many') }}
            </base-button>
        </div>
        <authorization-multifield-bulk-popup
            :show-popup="showEditMany"
            :value="fieldValue"
            :field="pluralizedLabel"
            :required="required"
            @apply="applyMany"
            @popupHide="hideEditMany"
        />
    </div>
</template>

<script>
import { forEach, isEmpty } from 'lodash';
import pluralize from 'pluralize';
import fieldError from '../field-error/field-error';
import authorizationField from '../authorization-field/authorization-field';
import AuthorizationMultifieldBulkPopup from '../authorization-multifield-bulk-popup/authorization-multifield-bulk-popup';

export default {
    name:       'AuthorizationMultifield',
    components: {
        AuthorizationMultifieldBulkPopup,
        fieldError,
        authorizationField,
    },
    props: {
        type:        {
            type:    String,
            default: 'text',
        },
        name:        {
            type:    [String, Boolean],
            default: false,
        },
        label:        {
            type:    String,
        },
        value:       {
            type:    Array,
        },
        errors:       {
            type:    [Array, Object],
        },
        placeholder: {
            type:    [String, Boolean],
            default: false,
        },
        maxItems: {
            type:    Number,
            default: 1,
        },
        required:    {
            type:    Boolean,
            default: false,
        },
        isKey:      {
            type:    Boolean,
            default: false,
        },
    },
    data() {
        return {
            fieldValue:   this.value ? this.value : [''],
            focusIndex:   undefined,
            showEditMany: false,
        };
    },
    watch: {
        value() {
            this.fieldValue = this.value;
        },
    },
    computed: {
        showDelete() {
            return this.fieldValue.length > 1;
        },
        showAddButton() {
            return this.fieldValue.length < this.maxItems;
        },
        pluralizedLabel() {
            return pluralize(this.label);
        },
    },
    methods: {
        applyFocus(index) {
            return index === this.focusIndex;
        },
        focus() {
            this.$emit('onFocus');
        },
        hasError(index) {
            return !isEmpty(this.errors[index.toString()]);
        },
        addField() {
            this.fieldValue.push('');
            this.focusIndex = this.fieldValue.length - 1;
        },
        editMany() {
            this.showEditMany = true;
        },
        hideEditMany() {
            this.showEditMany = false;
        },
        applyMany(values) {
            this.fieldValue = values;
            this.hideEditMany();
            this.updateValue();

            forEach(this.errors, (_, key) => {
                this.$delete(this.errors, key);
            });
        },
        removeField(index) {
            if (this.hasError(index)) {
                this.$delete(this.errors, index.toString());
            }

            forEach(this.errors, (error, key) => {
                const intKey = parseInt(key, 10);
                const decremented = intKey - 1;

                if (intKey > index) {
                    this.$set(this.errors, decremented.toString(), error);
                    this.$delete(this.errors, key);
                }
            });

            this.fieldValue.splice(index, 1);
        },
        updateValue() {
            this.$emit('update:value', this.fieldValue);
            this.$emit('updateValue');
        },
    },
};
</script>
