<template>
    <div class="flex overflow-hidden flex-col w-full bg-white divide-y shadow divider-gray-200 sm:rounded-md">
        <div class="divide-y divide-gray-200">
            <template v-for="item in list">
                <div :key="item.id">
                    <slot name="item" :item="item" />
                </div>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    name:  'ProductList',
    props: {
        list: {
            type:     [Array, Object],
            required: true,
        },
    },
};
</script>
