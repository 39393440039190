<template>
    <base-dropdown placement="bottom" trigger="click">
        <template #reference>
            <div
                class="shrink-0 px-3 py-2 text-sm font-semibold leading-5 rounded-md transition duration-150 ease-in-out cursor-pointer select-none font-avenir hover:text-white hover:bg-gray-700 focus:text-white focus:bg-gray-700 focus:outline-none"
                :class="isActive ? 'text-white bg-gray-900' : 'text-gray-300'"
            >
                {{ item.label }}
            </div>
        </template>

        <base-dropdown-item
            v-for="child in item.items"
            :key="child.link"
        >
            <template #content>
                <router-link
                    :to="{ name: child.name }"
                    class="text-gray-600 block px-5 py-2 hover:text-gray-600 focus:text-gray-600 active:text-gray-600"
                    :class="{ 'font-semibold': $route.name === child.name }"
                >
                    {{ child.label }}
                </router-link>
            </template>
        </base-dropdown-item>
    </base-dropdown>
</template>

<script>
export default {
    name:     'ManagerHeaderNavigationNested',
    props:    {
        item: {
            type:     Object,
            required: true,
        },
    },
    computed: {
        isActive() {
            return this.item.items.some(item => item.name === this.$route.name);
        },
    },
};
</script>
