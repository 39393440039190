export const FEATURE = Object.freeze({
    BACKUP_ENTITY:   'BackupEntity',
    BACKUP_LIFESPAN: 'BackupLifespan',
    BACKUP_RECORD:   'BackupRecord',
    BACKUP_RUN:      'BackupRun',
    BACKUP_SCHEDULE: 'BackupSchedule',

    MIGRATION_RECORD: 'MigrationRecord',
    MIGRATION_RUN:    'MigrationRun',
    MIGRATION_SCOPE:  'MigrationScope',

    MIGRATION_LICENSE_RECORD: 'MigrationLicenseRecord',
    MIGRATION_LICENSE_RUN:    'MigrationLicenseRun',
    MIGRATION_LICENSE_SCOPE:  'MigrationLicenseScope',

    CALENDAH_AVAILABLE:       'CalendahAvailable',
    CALENDAH_ACTIVE_CALENDAR: 'CalendahActiveCalendar',
    CALENDAH_ACCESS_LEVEL:    'CalendahAccessLevel',
    CALENDAH_SCOPE:           'CalendahScope',
});

export const FIELD = Object.freeze({
    NAME:          'name',
    IS_PRIMARY:    'isPrimary',
    INCREMENTABLE: 'incrementable',
    DATA:          'data',
});

export const DATA = Object.freeze({
    INCREMENT:  'increment',
    VALUE:      'value',
    VALUE_USED: 'valueUsed',
    VALUE_UNIT: 'valueUnit',
    SCOPE:      'scope',
    EXCLUDE:    'exclude',
});

export const RECORD_SCOPE = Object.freeze({
    INSTANCE:        'instance',
    INSTANCE_ENTITY: 'instance entity',
});

export const RUN_SCOPE = Object.freeze({
    DEMO: 'demo',
    FULL: 'full',
});

export const VALUE_UNIT = Object.freeze({
    DAY:   'day',
    WEEK:  'week',
    MONTH: 'month',
});

export const SCHEDULE = Object.freeze({
    DAILY:   'daily',
    WEEKLY:  'weekly',
    MONTHLY: 'monthly',
});
