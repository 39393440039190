import { onMounted, onUnmounted, useSlots } from 'vue';

export function useReference(onReference, events = {}) {
    const slots = useSlots();

    function getReference() {
        const defaultSlot = slots.default?.();

        return defaultSlot?.[0]?.elm;
    }

    onMounted(() => {
        const reference = getReference();

        if (!reference) return;

        Object.keys(events).forEach(event => reference.addEventListener(event, events[event], false));

        onReference?.(reference);
    });

    onUnmounted(() => {
        const reference = getReference();

        if (!reference) return;

        Object.keys(events).forEach(event => reference.removeEventListener(event, events[event], false));
    });
}
