import axios from 'axios';
import { Forbidden, Ok, Unauthorized } from '@/api/status-codes';

function createInstance() {
    const instance = axios.create();

    instance.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

    instance.interceptors.response.use((response) => {
        if (response.status === Ok
            && response.headers.location
            && response.data.redirect
        ) {
            window.location = response.data.redirect;
            return;
        }

        return response.data;
    }, (error) => {
        const response = error.response;
        const detail = response?.data?.detail;

        const reason = {
            error,
            response,
            data:    detail?.data ?? [],
            message: detail?.message ?? undefined,
            fields:  detail?.fields ?? [],
            code:    detail?.code,
        };

        return Promise.reject(reason);
    });

    return instance;
}

function onReject(response) {
    if (response.response.status === Unauthorized) {
        window.location = '/user/login'; // TODO DATATOCRM-15581
        return;
    }

    if (response.response.status === Forbidden) {
        window.location = '/'; // TODO DATATOCRM-15581
        return;
    }

    return Promise.reject(response);
}

export default class Axios {
    constructor() {
        this.instance = createInstance();
    }

    get(url, config) {
        return this.instance.get(url, config).catch(onReject);
    }

    post(url, data, config) {
        return this.instance.post(url, data, config).catch(onReject);
    }

    put(url, data, config) {
        return this.instance.put(url, data, config).catch(onReject);
    }

    delete(url, config) {
        return this.instance.delete(url, config).catch(onReject);
    }
}
