<script>
export default {
    name: 'BaseCodeEditor',
};
</script>

<script setup>
import { computed, shallowRef } from 'vue';

const props = defineProps({
    value:   {
        type: String,
    },
    attrs:   {
        type: Object,
    },
    options: {
        type: Object,
    },
});

const emit = defineEmits({
    input:    null,
    validate: null,
});

const model = computed({
    get: () => props.value,
    set: value => emit('input', value),
});

const editorRef = shallowRef();
const handleMount = (editor) => {
    editorRef.value = editor;
};

const options = computed(() => ({
    automaticLayout: true,
    ...(props.options ?? {}),
}));

defineExpose({
    editor: editorRef,
});
</script>

<template>
    <vue-monaco-editor
        v-model="model"
        v-bind="attrs"
        :options="options"
        @mount="handleMount"
        @validate="markers => emit('validate', markers)"
    />
</template>
