import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

function loadLocaleMessages() {
    const locales = import.meta.glob('@/lang/**/*.json', { eager: true });

    return Object.keys(locales).reduce((messages, path) => {
        const matched = path.match(/([A-Za-z0-9-_]+)\.json$/i);
        if (matched && matched.length > 1) {
            messages[matched[1]] = locales[path].default;
        }
        return messages;
    }, {});
}

export default new VueI18n({
    locale:         import.meta.env.VITE_APP_I18N_LOCALE || 'en',
    fallbackLocale: import.meta.env.VITE_APP_I18N_FALLBACK_LOCALE || 'en',
    messages:       loadLocaleMessages(),
    missing:        (locale, key) => {
        const error = new Error(`i18n :: Key '${key}' not found for locale '${locale}'`);

        Vue.prototype.$bugsnag?.notify(error, (event) => {
            event.groupingHash = 'missingTranslation';
        });
    },
});
