import BackupList from '@/pages/components/backup/list';
import {
    BACKUP,
    BACKUP_ENTITY,
    BACKUP_EXPORT,
    BACKUP_INSTANCE,
    BACKUP_PRICING,
    BACKUP_SCHEDULE,
} from '@/store/modules/acl-access/constants';

export default [
    {
        name:      'backupList',
        path:      '/backup/list',
        component: BackupList,
        meta:      {
            acl:         BACKUP.LIST,
            section:     'backup',
            title:       (route, { i18n }) => i18n.t('page title backup'),
            breadcrumbs: (route, { i18n }) => ([
                {
                    label: i18n.t('header breadcrumb backup'),
                },
                {
                    label: i18n.t('header breadcrumb list'),
                },
            ]),
        },
    },
    {
        name:      'backupConnect',
        path:      '/backup/connect/:backupId?',
        component: () => import('@/pages/components/backup/connect'),
        meta:      {
            acl:         BACKUP.CONNECT,
            section:     'backup',
            title:       (route, { i18n }) => i18n.t('page title backup'),
            breadcrumbs: (route, { i18n }) => ([
                {
                    component: () => import('@/components/app/header/breadcrumbs/link'),
                    attrs:     {
                        label: i18n.t('header breadcrumb backup'),
                        to:    {
                            name: 'backupList',
                        },
                        icon:  {
                            name:   'database',
                            prefix: 'fas',
                        },
                    },
                },
                {
                    label: i18n.t('header breadcrumb connect'),
                },
            ]),
        },
    },
    {
        name:      'backup',
        path:      '/backup/:backupId/',
        component: () => import('@/pages/components/backup'),
        redirect:  { name: 'backupInstances' },
        meta:      {
            section: 'backup',
            acl:     BACKUP.VIEW,
        },
        children:  [
            {
                name:      'backupInstances',
                path:      'runs/',
                component: () => import('@/components/backup/components/instance'),
                meta:      {
                    title:      (route, { i18n }) => i18n.t('page title backup runs'),
                    label:      (route, { i18n }) => i18n.t('backup runs title'),
                    breadcrumb: (route, { i18n }) => i18n.t('backup runs'),
                    acl:        BACKUP_INSTANCE.VIEW,
                },
            },
            {
                name:      'backupInstanceDetails',
                path:      'runs/:instanceId/',
                component: () => import('@/components/backup/components/instance/backup-instance-details'),
                meta:      {
                    label:          'Backup details',
                    transitionName: 'slide',
                    acl:            BACKUP_INSTANCE.VIEW,
                },
            },
            {
                name:      'backupObjects',
                path:      'objects/:objectId?',
                component: () => import('@/components/backup/components/entity'),
                meta:      {
                    title:      (route, { i18n }) => i18n.t('page title backup objects'),
                    label:      (route, { i18n }) => i18n.t('backup objects title'),
                    breadcrumb: (route, { i18n }) => i18n.t('backup objects'),
                    acl:        BACKUP_ENTITY.LIST,
                },
            },
            {
                name:      'backupExports',
                path:      'exports/:exportId?',
                component: () => import('@/components/backup/components/export'),
                meta:      {
                    title:      (route, { i18n }) => i18n.t('page title backup exports'),
                    label:      (route, { i18n }) => i18n.t('backup exports title'),
                    breadcrumb: (route, { i18n }) => i18n.t('backup exports'),
                    acl:        BACKUP_EXPORT.VIEW,
                },
            },
            {
                name:      'backupSchedule',
                path:      'schedule/',
                component: () => import('@/components/backup/components/schedule'),
                meta:      {
                    title:      (route, { i18n }) => i18n.t('page title backup schedule'),
                    label:      (route, { i18n }) => i18n.t('backup schedule title'),
                    breadcrumb: (route, { i18n }) => i18n.t('backup schedule'),
                    acl:        BACKUP_SCHEDULE.VIEW,
                },
            },
            {
                name:      'backupPricing',
                path:      'pricing/',
                component: () => import('@/components/backup/components/pricing'),
                meta:      {
                    title:      (route, { i18n }) => i18n.t('page title backup pricing'),
                    label:      (route, { i18n }) => i18n.t('backup pricing title'),
                    breadcrumb: (route, { i18n }) => i18n.t('backup pricing'),
                    acl:        BACKUP_PRICING.LIST,
                },
            },
            // {
            //     name:      'backupHistory',
            //     path:      'history',
            //     component: () => import('@/components/backup/components/history'),
            //     meta:      {
            //         label:      'The list of all logs',
            //         breadcrumb: 'Logs',
            //     },
            // },
            {
                path:     '*',
                redirect: { name: 'backupInstances' },
            },
        ],
    },
];
