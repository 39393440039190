<template>
    <div>
        <div
            class="fixed flex h-full shrink-0 bg-gradient-to-b shadow-2xl transition-all duration-500 ease-in-out from-secondary-950 to-secondary-800 z-120 md:relative md:shadow-none"
            :class="collapsed ? 'w-64 -translate-x-full md:w-18 md:translate-x-0' : 'w-64 translate-x-0'"
        >
            <div class="flex w-full flex-col">
                <div class="relative flex h-16 items-center justify-center">
                    <div
                        class="flex h-full items-center justify-center overflow-hidden transition-all duration-500 ease-in-out [direction:rtl]"
                        :class="collapsed ? 'md:w-10' : 'w-full'"
                    >
                        <div
                            class="flex items-center"
                            :data-test="dataTest('logo')"
                        >
                            <router-link
                                class="flex shrink-0 items-center justify-center"
                                to="/"
                            >
                                <div class="relative flex items-center justify-center">
                                    <template v-if="isCobranding">
                                        <img
                                            v-if="showIcon"
                                            :src="icon"
                                            class="h-8"
                                            alt="MigrateMyCRM"
                                        >
                                        <img
                                            v-else
                                            :src="logo"
                                            class="h-10 w-40"
                                            alt="MigrateMyCRM"
                                        >
                                    </template>
                                    <template v-else>
                                        <img
                                            :src="icon"
                                            class="absolute h-8"
                                            :class="{
                                                'duration-500 transition-transform opacity-100 translate-x-[60px]': showIcon,
                                                'opacity-0 translate-x-[38px] -translate-y-[10px] scale-[0.63] [transition:opacity_50ms_450ms_ease-in-out,transform_500ms_ease-in-out]': !showIcon,
                                            }"
                                            alt="MigrateMyCRM"
                                        >
                                        <img
                                            :src="logo"
                                            class="h-10 w-40 transition-opacity duration-300 ease-in-out"
                                            :class="showIcon ? 'opacity-0' : 'opacity-100 delay-300'"
                                            alt="MigrateMyCRM"
                                        >
                                    </template>
                                </div>
                            </router-link>
                        </div>
                    </div>
                    <div
                        class="absolute right-0 flex h-6 w-6 cursor-pointer items-center justify-center rounded-full text-white ring-1 ring-white transition-all duration-500 ease-in-out bg-secondary-950 active:bg-secondary-800 active:ring-secondary-800"
                        :class="collapsed ? 'translate-x-9 md:ml-0 md:translate-x-3' : 'translate-x-3'"
                        @click="toggleSidebar"
                    >
                        <base-icon
                            class="h-3 w-3"
                            :class="collapsed ? 'rotate-180' : 'rotate-0'"
                            name="chevron-left"
                            prefix="fas"
                        />
                    </div>
                </div>
                <base-scroll
                    class="flex flex-1 flex-col"
                    wrap-class="flex flex-1 flex-col"
                    view-class="flex flex-1 flex-col justify-between p-2 text-gray-300 space-y-2"
                >
                    <div class="-mx-2 flex shrink-0 flex-col space-y-1">
                        <sidebar-migration
                            class="px-2"
                            @navigate="sectionNavigate"
                        />
                        <sidebar-backup
                            class="px-2"
                            @navigate="sectionNavigate"
                        />
                        <component
                            v-for="section in $options.mainSections"
                            :key="section.name"
                            :is="section.component"
                            class="px-2"
                            @navigate="sectionNavigate"
                        />
                    </div>
                    <div class="flex shrink-0 flex-col gap-y-1">
                        <component
                            v-for="section in $options.additionalSections"
                            :key="section.name"
                            :is="section.component"
                            @navigate="sectionNavigate"
                        />
                        <sidebar-settings
                            @navigate="sectionNavigate"
                        />
                        <div class="flex flex-col border-t border-white/20 pt-1 space-y-1">
                            <router-link
                                v-for="(helpButton, index) in helpButtons"
                                v-slot="{ href, navigate, isActive }"
                                :key="index"
                                :to="helpButton.route"
                                custom
                                @click.native="sectionNavigate"
                            >
                                <a
                                    :href="href"
                                    class="flex w-full rounded-md font-semibold uppercase tracking-wide hover:bg-black/30 hover:text-white focus:text-white focus:outline-none"
                                    :class="{
                                        'text-white bg-black/40 shadow-inner shadow-black/30': isActive,
                                        'text-gray-300': !isActive,
                                    }"
                                    :title="collapsed ? helpButton.label : undefined"
                                    @click="navigate"
                                >
                                    <div class="flex w-full items-center px-4 py-2 space-x-3">
                                        <div class="flex h-5 w-6 shrink-0 items-center justify-center">
                                            <base-icon
                                                class="text-xl"
                                                :name="helpButton.icon.name"
                                                :prefix="helpButton.icon.prefix"
                                                fixed-width
                                            />
                                        </div>
                                        <span class="overflow-hidden whitespace-nowrap text-xs leading-none">
                                            {{ helpButton.label }}
                                        </span>
                                    </div>
                                </a>
                            </router-link>
                        </div>
                    </div>
                </base-scroll>
            </div>
        </div>
        <div
            class="fixed h-screen w-screen bg-gray-800 transition-all duration-300 ease-in-out z-110 md:hidden"
            :class="collapsed ? 'bg-opacity-0 pointer-events-none' : 'bg-opacity-50'"
            @click="toggleSidebar"
        />
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import SidebarBackup from '@/components/app/sidebar/section/backup';
import SidebarMigration from '@/components/app/sidebar/section/migration';
import SidebarSettings from '@/components/app/sidebar/section/settings';
import SidebarLogo from '@/assets/img/logo/sidebar.svg';
import SidebarIcon from '@/assets/img/logo/sidebar-icon.svg';

export default {
    name:       'AppSidebar',
    components: {
        SidebarBackup,
        SidebarMigration,
        SidebarSettings,
    },
    data() {
        return {
            logo:         null,
            icon:         null,
            isCobranding: !!window.config.cobranding,
            helpButtons:  [
                {
                    icon:  {
                        name:   'question-circle',
                        prefix: 'far',
                    },
                    label: this.$t('header breadcrumb help'),
                    route: {
                        name: 'help',
                    },
                },
                {
                    icon:  {
                        name:   'file-signature',
                        prefix: 'fas',
                    },
                    label: this.$t('header breadcrumb policy'),
                    route: {
                        name: 'policy',
                    },
                },
            ],
        };
    },
    mainSections:       [],
    additionalSections: [],
    created() {
        this.logo = this.getLogoPath();
        this.icon = this.getIconPath();

        this.$options.mainSections = this.getInjectedModulesSidebars('main');
        this.$options.additionalSections = this.getInjectedModulesSidebars('additional');

        this.initSidebar();
    },
    computed: {
        ...mapState('sidebar', {
            collapsed: 'collapsed',
        }),
        showIcon() {
            const order = this.$screen.config.breakpointsOrder;

            if (order.indexOf(this.$screen.breakpoint) < order.indexOf('md')) return false;

            return this.collapsed;
        },
    },
    methods:  {
        ...mapActions({
            initSidebar:   'sidebar/INIT',
            toggleSidebar: 'sidebar/TOGGLE',
        }),
        sectionNavigate() {
            if (!this.collapsed && this.$screen.width < 768) {
                this.toggleSidebar();
            }
        },
        getInjectedModulesSidebars(key) {
            const components = [];

            this.$appModules.forEach((module, name) => {
                if (!module.sidebar?.[key]) return;

                components.push({
                    name,
                    component: module.sidebar[key],
                });
            });

            return components;
        },
        getLogoPath() {
            if (!window.config.cobranding) return SidebarLogo;

            return `${import.meta.env.BASE_URL}img/cobranding/${window.config.cobranding}/sidebar.svg`;
        },
        getIconPath() {
            if (!window.config.cobranding) return SidebarIcon;

            return `${import.meta.env.BASE_URL}img/cobranding/${window.config.cobranding}/sidebar-icon.svg`;
        },
    },
};
</script>
