export default function toISOTime(date) {
    return [
        date.getHours(),
        date.getMinutes(),
        date.getSeconds(),
    ].map((num) => {
        const norm = Math.floor(Math.abs(num));

        return (norm < 10 ? '0' : '') + norm;
    }).join(':');
}
