<template>
    <div class="flex w-full items-center gap-x-2">
        <slot name="button-left" />
        <input
            ref="input"
            :type="type"
            :name="name"
            :id="id"
            :required="required"
            :placeholder="placeholder"
            v-model="fieldValue"
            class="relative w-full rounded-xl border bg-gray-100 px-4 font-normal outline-none transition-all duration-300 py-1.5 focus:bg-gray-200"
            :class="hasError? 'border-red-500 hover:border-red-500 focus:border-red-500' : 'border-gray-300 hover:border-primary-600 focus:border-primary-600'"
            autocomplete="off"
            @focus="focus"
        >
        <slot name="button-right" />
        <div v-if="showActions" class="flex items-center gap-x-2">
            <div v-if="actionClear" />
            <div v-if="actionShow" />
            <base-button
                v-if="actionDelete"
                circle
                :icon="{ name: 'trash-alt', prefix: 'far' }"
                @click="deleteField"
            />
        </div>
    </div>
</template>

<script>
export default {
    name:  'AuthorizationField',
    props: {
        type:         {
            type:    String,
            default: 'text',
        },
        name:         {
            type:    [String, Boolean],
            default: false,
        },
        value:        {
            type:    [String, Boolean],
            default: false,
        },
        id:           {
            type:    [String, Boolean],
            default: false,
        },
        placeholder:  {
            type:    [String, Boolean],
            default: false,
        },
        required:     {
            type:    Boolean,
            default: false,
        },
        actionShow:   {
            type:    Boolean,
            default: false,
        },
        actionClear:  {
            type:    Boolean,
            default: false,
        },
        actionDelete: {
            type:    Boolean,
            default: false,
        },
        applyFocus:   {
            type:    Boolean,
            default: false,
        },
        hasError:     {
            type:    Boolean,
            default: false,
        },
        isKey:        {
            type:    Boolean,
            default: false,
        },
    },
    data() {
        return {
            fieldValue: this.value ? this.value : '',
        };
    },
    mounted() {
        if (this.applyFocus === true) {
            this.$refs.input.focus();
        }
    },
    watch:    {
        value() {
            this.fieldValue = this.value;
        },
        fieldValue() {
            this.$emit('update:value', (this.type === 'string' && this.isKey === true) ? this.fieldValue.trim() : this.fieldValue);
            this.$emit('updateValue');
        },
    },
    computed: {
        showActions() {
            return this.actionShow || this.actionClear || this.actionDelete;
        },
    },
    methods:  {
        focus() {
            this.$emit('onFocus');
        },
        deleteField() {
            this.$emit('onDelete');
        },
    },
};
</script>
