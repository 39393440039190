import { get } from 'lodash';
import { mapGetters } from 'vuex';
import backupEntities from '@/components/backup/mixins/backup-entities';

export default {
    mixins:   [
        backupEntities,
    ],
    computed: {
        ...mapGetters({
            backupPlan: 'backup/getPlan',
        }),
        backupRecordData() {
            return get(this.backupPlan.options.backup.features.BackupRecord, 'data');
        },
        backupRecordLimit() {
            return get(this.backupRecordData, 'value');
        },
        backupRecordLimitEntity() {
            return this.backupRecordData.scope === 'instance entity' ? this.backupRecordLimit : undefined;
        },
    },
    methods:  {
        isRecordLimitExceeded(entities) {
            return this.backupRecordData.scope === 'instance' ? this.getSelectedTotalRecords(entities) > this.backupRecordLimit : false;
        },
        isRecordLimitEntityExceeded(entities) {
            const limit = this.backupRecordLimitEntity;

            if (!limit) {
                return false;
            }

            return entities
                .map(entity => entity.count)
                .reduce((result, currentValue) => (result || currentValue > limit), false);
        },
    },
};
