import { mapGetters } from 'vuex';
import { getFeatureExclude } from '@/components/payments/components/service-plan/components/features/utils';
import { FEATURE } from '@/components/payments/components/service-plan/components/features/constants';

export default {
    computed: {
        ...mapGetters({
            backupPlan: 'backup/getPlan',
        }),
    },
    methods: {
        backupEntityAllow(entity) {
            return !getFeatureExclude(this.backupPlan.options.backup.features[FEATURE.BACKUP_ENTITY]).includes(entity);
        },
    },
};
