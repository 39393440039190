<script>
export default {
    name: 'ServicePlanFeatureSchedule',
};
</script>

<script setup>
import { computed, toRef } from 'vue';
import i18n from '@/plugins/i18n';
import { FIELD } from '@/components/payments/components/service-plan/components/features/constants';
import useFeature from '@/components/payments/components/service-plan/components/features/composables/use-feature';
import ServicePlanFeatureElementsValue from '@/components/payments/components/service-plan/components/features/components/elements/value';

const props = defineProps({
    feature: {
        type:     Object,
        required: true,
    },
    short:   {
        type: Boolean,
    },
});

const { value } = useFeature(toRef(props, 'feature'));

const formatted = computed(() => {
    if (value.value) return i18n.t('payment.servicePlan.feature.schedule.available');

    return i18n.t('payment.servicePlan.feature.schedule.unavailable');
});
</script>

<template>
    <service-plan-feature-elements-value
        v-if="!short"
        :name="feature[FIELD.NAME]"
        :value="formatted"
    />
    <svg v-else-if="value" class="w-5 h-5 text-emerald-500" xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 20 20" fill="currentColor"
    >
        <path fill-rule="evenodd"
              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
              clip-rule="evenodd"
        />
    </svg>
    <svg v-else class="w-5 h-5 ml-auto text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
         fill="currentColor"
    >
        <path fill-rule="evenodd" d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd" />
    </svg>
</template>
