import Vue from 'vue';
import { tryOnScopeDispose } from '@vueuse/core';

export default function useWebSockets() {
    const subs = new Set();

    function on(channel, handler, timeout = 0) {
        Vue.prototype.$socket.on(channel, handler, timeout);

        subs.add(channel);
    }

    function off(channel, handler) {
        Vue.prototype.$socket.off(channel, handler);

        subs.delete(channel);
    }

    function emit(channel, data, unique = false) {
        Vue.prototype.$socket.emit(channel, data, unique);
    }

    function clear() {
        Vue.prototype.$socket.clear(...subs);

        subs.clear();
    }

    tryOnScopeDispose(clear);

    return {
        on,
        off,
        emit,
        clear,
    };
}
