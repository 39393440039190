<script>
export default {
    name: 'ServicePlanFeatureRecord',
};
</script>

<script setup>
import { computed, toRef } from 'vue';
import i18n from '@/plugins/i18n';
import formatNumber from '@/plugins/filters/format-number';
import { FIELD, RECORD_SCOPE } from '@/components/payments/components/service-plan/components/features/constants';
import useFeature from '@/components/payments/components/service-plan/components/features/composables/use-feature';
import ServicePlanFeatureElementsValue from '@/components/payments/components/service-plan/components/features/components/elements/value';

const props = defineProps({
    feature: {
        type:     Object,
        required: true,
    },
});

const { value, scope } = useFeature(toRef(props, 'feature'));

const formatted = computed(() => {
    if (!value.value) return i18n.t('payment.servicePlan.feature.record.all');

    const count = formatNumber(value.value, true);

    return {
        [RECORD_SCOPE.INSTANCE]:        i18n.t('payment.servicePlan.feature.record.instance', { count }),
        [RECORD_SCOPE.INSTANCE_ENTITY]: i18n.t('payment.servicePlan.feature.record.instanceEntity', { count }),
    }[scope.value];
});
</script>

<template>
    <service-plan-feature-elements-value
        :name="feature[FIELD.NAME]"
        :value="formatted"
    />
</template>
