import { computed } from 'vue';
import {
    getFeatureExclude,
    getFeatureScope,
    getFeatureUnit,
    getFeatureUsed,
    getFeatureValue,
} from '@/components/payments/components/service-plan/components/features/utils';

export default function useFeature(feature) {
    return {
        value:   computed(() => getFeatureValue(feature.value)),
        scope:   computed(() => getFeatureScope(feature.value)),
        used:    computed(() => getFeatureUsed(feature.value)),
        exclude: computed(() => getFeatureExclude(feature.value)),
        unit:    computed(() => getFeatureUnit(feature.value)),
    };
}
