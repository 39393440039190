<template>
    <div
        class="flex flex-col p-3 gap-y-1"
        :data-test="dataTest()"
    >
        <template v-for="(item, index) in items">
            <component
                :key="`section-${index}`"
                :is="item.component"
                @navigate="$emit('navigate')"
            />
            <div
                v-if="item.divider"
                :key="`divider-${index}`"
                class="border-t w-full"
            />
        </template>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import NavigationLogin from '@/components/app/header/navigation/login';
import NavigationLogout from '@/components/app/header/navigation/logout';
import NavigationBalance from '@/components/app/header/navigation/balance';
import NavigationDevSandbox from '@/components/app/header/navigation/dev-sandbox';
import NavigationManager from '@/components/app/header/navigation/manager';
import NavigationPartner from '@/components/app/header/navigation/partner';
import NavigationSettings from '@/components/app/header/navigation/settings';
import { DEV, OTHER, USER } from '@/store/modules/acl-access/constants';

export default {
    name:     'HeaderNavigation',
    computed: {
        ...mapGetters({
            isGranted:         'aclAccess/isGranted',
            userGroup:         'user/getGroup',
            getPartnerRequest: 'user/getPartnerRequest',
        }),
        items() {
            return [
                {
                    component: NavigationSettings,
                    divider:   true,
                },
                {
                    component: NavigationManager,
                    condition: this.userGroup === 'employee',
                },
                {
                    component: NavigationDevSandbox,
                    condition: this.isGranted(DEV.SANDBOX) && window?.config?.isDevelopment,
                },
                {
                    component: NavigationLogin,
                    condition: this.isGranted(USER.LOGIN),
                },
                {
                    component: NavigationBalance,
                    condition: this.isGranted(OTHER.PAYMENT_ADD),
                },
                {
                    component: NavigationPartner,
                    condition: this.isGranted(USER.PARTNER_REQUEST),
                },
                {
                    component: NavigationLogout,
                    condition: this.isGranted(USER.LOGOUT),
                },
            ].filter(item => item.condition ?? true);
        },
    },
};
</script>
