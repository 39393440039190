const prefix = 'fac';
const iconName = 'plus-circle';
const width = 512;
const height = 512;
const ligatures = [];
const unicode = null;
const svgPathData = 'M256,8C119,8,8,119,8,256s111,248,248,248s248-111,248-248S393,8,256,8z M256,456c-110.5,0-200-89.5-200-200  S145.5,56,256,56s200,89.5,200,200S366.5,456,256,456z M376.8,226.3h-90.6v-90.6c0-11.1-9-20.1-20.1-20.1h-20.1  c-11.1,0-20.1,9-20.1,20.1v90.6h-90.6c-11.1,0-20.1,9-20.1,20.1v20.1c0,11.1,9,20.1,20.1,20.1h90.6v90.6c0,11.1,9,20.1,20.1,20.1  h20.1c11.1,0,20.1-9,20.1-20.1v-90.6h90.6c11.1,0,20.1-9,20.1-20.1v-20.1C396.9,235.3,387.9,226.3,376.8,226.3z';

export const definition = {
    prefix,
    iconName,
    icon: [
        width,
        height,
        ligatures,
        unicode,
        svgPathData,
    ],
};

export { definition as faPlusCircle };
