<template>
    <div
        id="app-version-banner"
        class="pointer-events-none fixed inset-x-0 bottom-0 transition-all duration-500 ease-in-out z-max font-avenir"
        :class="show ? 'translate-y-0' : 'translate-y-full'"
    >
        <div class="pointer-events-auto mx-auto max-w-xl px-4 pb-4 md:max-w-4xl">
            <div
                class="flex flex-col items-center justify-between gap-x-5 gap-y-3 rounded-lg bg-gray-900 px-3 py-3 shadow-lg md:flex-row md:px-4"
            >
                <div class="flex items-center gap-x-3 md:gap-x-5">
                    <img
                        class="mx-2 w-10 md:mx-0"
                        src="@/assets/img/logo/icon-white.svg"
                        alt="MigrateMyCRM"
                    >
                    <div class="flex flex-col gap-y-1">
                        <div class="text-lg font-medium text-white" v-text="$t('app.version.title')" />
                        <div class="text-sm text-gray-200" v-text="$t('app.version.description')" />
                    </div>
                </div>
                <div class="flex w-full justify-center gap-x-3 md:w-auto">
                    <button
                        class="flex max-w-xs flex-1 items-center justify-center gap-x-2 rounded-md bg-white px-4 py-2 font-medium uppercase hover:bg-gray-200 focus:bg-gray-200 focus:outline-none"
                        @click="refresh"
                    >
                        <span v-text="$t('app.version.button')" />
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="h-5 w-5">
                            <path fill-rule="evenodd"
                                  d="M15.312 11.424a5.5 5.5 0 01-9.201 2.466l-.312-.311h2.433a.75.75 0 000-1.5H3.989a.75.75 0 00-.75.75v4.242a.75.75 0 001.5 0v-2.43l.31.31a7 7 0 0011.712-3.138.75.75 0 00-1.449-.39zm1.23-3.723a.75.75 0 00.219-.53V2.929a.75.75 0 00-1.5 0V5.36l-.31-.31A7 7 0 003.239 8.188a.75.75 0 101.448.389A5.5 5.5 0 0113.89 6.11l.311.31h-2.432a.75.75 0 000 1.5h4.243a.75.75 0 00.53-.219z"
                                  clip-rule="evenodd"
                            />
                        </svg>
                    </button>
                    <button
                        class="inline-flex items-center justify-center rounded-md p-2 text-gray-100 hover:bg-gray-700 focus:bg-gray-700 focus:outline-none"
                        @click="close"
                    >
                        <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
    name:     'AppVersion',
    computed: {
        ...mapState({
            actual: state => state.version.actual,
        }),
        show: {
            get() {
                return !this.actual;
            },
            set(value) {
                this.setActual(!value);
            },
        },
    },
    methods:  {
        ...mapActions({
            setActual: 'SET_VERSION_ACTUAL',
        }),
        refresh() {
            window.location.reload();
        },
        close() {
            this.show = false;

            setTimeout(() => {
                this.show = true;
            }, 30000);
        },
    },
};
</script>
