import { computed, inject, onUnmounted, provide, readonly, ref } from 'vue';

export function useReferenceStack(key, reference) {
    const stack = inject(key, ref([]));
    const nested = computed(() => {
        const index = stack.value.indexOf(reference);

        if (index === -1) return [];

        return stack.value.slice(index);
    });

    provide(key, stack);

    function add() {
        stack.value.push(reference);
    }

    function remove() {
        const index = stack.value.indexOf(reference);

        if (index === -1) return;

        stack.value.splice(index, 1);
    }

    onUnmounted(() => remove());

    return {
        stack: readonly(stack),
        nested,
        add,
        remove,
    };
}
