<script>
export default {
    name: 'ServicePlanExpires',
};
</script>

<script setup>
import { computed } from 'vue';
import formatDate from '@/plugins/filters/format-date';
import { CYCLE_STATUS, FORMAT } from '@/components/payments/constants';

const props = defineProps({
    format: {
        type:       String,
        required:   true,
        validation: format => Object.values(FORMAT).includes(format),
    },
    cycleStatus: {
        type:       String,
        required:   true,
        validation: status => Object.values(CYCLE_STATUS).includes(status),
    },
    expiresAt:   {
        type:     String,
        required: true,
    },
    label:       {
        type: String,
    },
});

const title = computed(() => {
    if (props.label) return props.label;
    if (props.format === FORMAT.ONE_TIME) return 'Expires';

    return props.cycleStatus === CYCLE_STATUS.ACTIVE ? 'Renew' : 'Expires';
});

const date = computed(() => formatDate(props.expiresAt, { month: 'short', day: 'numeric', year: 'numeric' }));
</script>

<template>
    <span class="inline-flex select-none items-center gap-x-1 font-medium leading-none">
        <span>{{ title }} on</span>
        <span class="font-semibold">{{ date }}</span>
    </span>
</template>
