<template>
    <base-modal
        size="small"
        :value="showPopup"
        :close-on-click-overlay="false"
        @close="hideAuthorization"
    >
        <crm-authorization-form
            v-if="showPopup"
            :data-path="dataPath"
            :type="direction"
            :product="product"
            :locked="true"
        />
    </base-modal>
</template>

<script>
import qs from 'qs';
import { get } from 'lodash';
import { mapActions, mapMutations } from 'vuex';
import crmAuthorizationForm from '@/components/crm-authorization-form/crm-authorization-form';

export default {
    name:       'AuthorizationWrapper',
    components: {
        crmAuthorizationForm,
    },
    props: {
        dataPath: {
            type:     String,
            required: true,
        },
        product:  {
            type: String,
        },
    },
    data() {
        return {
            direction:    'source',
            migrationId:  undefined,
            crmId:        undefined,
            showPopup:    false,
        };
    },
    mounted() {
        window.eventBus.$on('showAuthorization', (data) => {
            this.$store.commit('applyManagerData', data);

            this.direction = data.direction;
            this.migrationId = data.migrationId;
            this.crmId = data.crmId;

            this.setPlatformData({
                prefix: data.direction,
                data:   data.platformData,
            });
            window.eventBus.$emit(this.$store.state.eventConstants.platformDataSet);

            this.showPopup = true;
        });
        window.eventBus.$on(this.$store.state.eventConstants.showStepLoading, () => {
            this.showLoader();
        });
        window.eventBus.$on(this.$store.state.eventConstants.hideStepLoading, () => {
            this.hideLoader();
        });
        window.eventBus.$on(this.$store.state.eventConstants.submitStep, this.submitStep);
    },
    methods:    {
        ...mapActions({
            showLoader: 'loader/SHOW',
            hideLoader: 'loader/HIDE',
        }),
        ...mapMutations({
            setPlatformData: 'platform/SET_PLATFORM_DATA',
        }),
        hideAuthorization() {
            this.showPopup = false;
            this.$store.commit('clearManagerData');
            this.$emit('close');
        },
        submitStep(send, success, error, stringify = true) {
            window.eventBus.$emit(this.$store.state.eventConstants.showStepLoading);

            const sendData = {
                ...send,
                direction:   this.direction,
                migrationId: this.migrationId,
                crmId:       this.crmId,
            };

            this.$http.post(
                '/manager/authorization/authorize',
                stringify ? qs.stringify(sendData) : sendData,
            ).then((data) => {
                window.eventBus.$emit(this.$store.state.eventConstants.hideStepLoading);

                if (typeof success === 'function') {
                    success(data);
                }

                this.$emit('success', this.direction);

                this.hideAuthorization();
            }).catch(({ data, response }) => {
                window.eventBus.$emit(this.$store.state.eventConstants.hideStepLoading);

                if (!Object.is(get(response.data.detail, 'message'), null)) {
                    window.eventBus.$emit(
                        this.$store.state.eventConstants.showPageMessage,
                        {
                            type:    'danger',
                            content: response.data.detail.message,
                        },
                    );
                    setTimeout(() => {
                        const elem = document.getElementById('page-error');
                        if (elem) {
                            elem.scrollIntoView({ behavior: 'smooth' });
                        }
                    }, 100);
                }

                if (typeof error === 'function') {
                    error(data, response);
                }
            });
        },
    },
};
</script>
