<script>
export default {
    name: 'BasePagination',
};
</script>

<script setup>
import { computed, ref } from 'vue';
import { SIZE, TYPE } from '@/components/base-components/components/pagination';
import BasePaginationJumper from '@/components/base-components/components/pagination/components/jumper';
import BasePaginationPager from '@/components/base-components/components/pagination/components/pager';
import BasePaginationSizer from '@/components/base-components/components/pagination/components/sizer';
import BasePaginationSummary from '@/components/base-components/components/pagination/components/summary';

const props = defineProps({
    value:            {
        type: Number,
    },
    total:            {
        type:     Number,
        required: true,
    },
    pageSize:         {
        type:    Number,
        default: 10,
    },
    type:             {
        type:      String,
        default:   TYPE.DEFAULT,
        validator: value => Object.values(TYPE).includes(value),
    },
    size:             {
        type:      String,
        default:   SIZE.SMALL,
        validator: value => Object.values(SIZE).includes(value),
    },
    pagerCount:       {
        type:       Number,
        default:    7,
        validation: v => v >= 7 && v <= 21 && v % 2 === 1,
    },
    sizerList:        {
        type: Array,
    },
    summary:          {
        type: Boolean,
    },
    summaryUnits:     {
        type: String,
    },
    jumper:           {
        type: Boolean,
    },
    sizer:            {
        type: Boolean,
    },
    disabled:         {
        type: Boolean,
    },
    hideOnSinglePage: {
        type: Boolean,
    },
});

const emit = defineEmits({
    input:    null,
    pageSize: null,
});

function onPage(value) {
    emit('input', value);
}

function onPageSize(value) {
    emit('pageSize', value);
    emit('input', 1);
}

const show = computed(() => {
    if (!props.hideOnSinglePage) return true;

    return props.total > props.pageSize;
});

const pager = ref(null);

defineExpose({
    goTo: page => pager.value.goTo(page),
});
</script>

<template>
    <div
        v-show="show"
        class="inline-flex items-center justify-center flex-wrap gap-x-4 gap-y-3"
    >
        <base-pagination-summary
            v-if="summary"
            :page="value"
            :page-size="pageSize"
            :total="total"
            :units="summaryUnits"
            :type="type"
            :size="size"
        />

        <base-pagination-pager
            ref="pager"
            :value="value"
            :count="total"
            :page-size="pageSize"
            :pager-count="pagerCount"
            :type="type"
            :size="size"
            :disabled="disabled"
            @input="onPage"
        />

        <div class="inline-flex items-center gap-x-4">
            <base-pagination-jumper
                v-if="jumper"
                :value="value"
                :count="total"
                :page-size="pageSize"
                :type="type"
                :size="size"
                :disabled="disabled"
                @input="onPage"
            />
            <base-pagination-sizer
                v-if="sizer"
                class="w-32"
                :value="pageSize"
                :count="total"
                :list="sizerList"
                :type="type"
                :size="size"
                :disabled="disabled"
                @input="onPageSize"
            />
        </div>
    </div>
</template>
