<script>
export default {
    name: 'BaseModal',
};
</script>

<script setup>
import { ref, computed, watch, onMounted, onBeforeUnmount } from 'vue';
import { useStack } from '@/components/base-components/composables/stack';

const props = defineProps({
    value:               {
        type: Boolean,
    },
    title:               {
        type: String,
    },
    size:                {
        type:      [Number, String],
        default:   'small',
        validator: (type) => {
            if (Number.isInteger(type)) return type > 0;

            return ['mini', 'small', 'medium', 'large', 'extra', 'fit'].indexOf(type) !== -1;
        },
    },
    verticalAlign:       {
        type:      String,
        default:   'center',
        validator: type => ['top', 'center'].indexOf(type) !== -1,
    },
    showClose:           {
        type:    Boolean,
        default: true,
    },
    closeOnClickOverlay: {
        type:    Boolean,
        default: true,
    },
    closeOnPressEscape:  {
        type:    Boolean,
        default: true,
    },
    transition:          {
        type:    [String, Object],
        default: () => ({
            'enter-active-class': 'transition-all duration-300 ease-in-out',
            'leave-active-class': 'transition-all duration-300 ease-in-out',
            'leave-to-class':     'opacity-0 -translate-y-10',
            'enter-class':        'opacity-0 -translate-y-10',
        }),
    },
    overlayClass:        {
        type: [String, Array, Object],
    },
    overlayTransition:   {
        type: [String, Object],
    },
    zIndexBase:          {
        type:    Number,
        default: 20200,
    },
});

const emit = defineEmits({
    input:  null,
    open:   null,
    opened: null,
    close:  null,
    closed: null,
});

const model = computed({
    get: () => props.value,
    set: value => emit('input', value),
});

watch(model, () => {
    if (!model.value) return;

    emit('open');
});

function close() {
    model.value = false;
    emit('close');
}

function clickOverlay() {
    if (!props.closeOnClickOverlay) return;

    close();
}

const { isActive, zIndex } = useStack(model, props.zIndexBase);

function handleEscape(event) {
    if (!props.closeOnPressEscape) return;
    if (event.code?.toLowerCase() !== 'escape') return;
    if (!isActive.value) return;

    close();
}

onMounted(() => {
    window.addEventListener('keyup', handleEscape);
});

onBeforeUnmount(() => {
    window.removeEventListener('keyup', handleEscape);
});

const modalClosed = ref(!props.value);
const overlayClosed = ref(!props.value);

watch(
    [modalClosed, overlayClosed],
    () => {
        if (modalClosed.value !== overlayClosed.value || modalClosed.value === null) return;

        if (!modalClosed.value) return emit('opened');

        emit('closed');
    },
);

const modalStyle = computed(() => {
    const width = {
        mini:   '480px',
        small:  '600px',
        medium: '768px',
        large:  '992px',
        extra:  '1200px',
        fit:    'fit-content',
    }[props.size] ?? `${props.size}px`;

    return {
        width,
    };
});
</script>

<template>
    <portal>
        <base-overlay
            :show="model"
            :transition="overlayTransition"
            :z-index="zIndex"
            :custom-class="overlayClass"
            @after-enter="overlayClosed = false"
            @enter="overlayClosed = null"
            @after-leave="overlayClosed = true"
            @click="clickOverlay"
        />

        <transition
            v-bind="transition"
            appear
            @after-enter="modalClosed = false"
            @enter="modalClosed = null"
            @after-leave="modalClosed = true"
        >
            <div
                v-if="model"
                class="pointer-events-none fixed inset-0 flex flex-col items-center overflow-hidden"
                :class="[
                    {
                        top: 'justify-start',
                        center: 'justify-center',
                    }[verticalAlign],
                ]"
                :style="{
                    'z-index': zIndex,
                }"
            >
                <div
                    class="fixed pointer-events-auto flex flex-col overflow-hidden rounded-xl m-4 bg-white shadow-md max-h-[calc(100vh-2rem)] max-w-[calc(100vw-2rem)]"
                    :style="modalStyle"
                >
                    <div
                        v-if="showClose || $scopedSlots.close"
                        class="absolute right-2 top-1.5"
                    >
                        <slot
                            name="close"
                            :close="close"
                        >
                            <div
                                class="cursor-pointer p-1 text-gray-500 opacity-50 transition-opacity duration-300 hover:opacity-100"
                                @click="close"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                     stroke="currentColor" class="h-5 w-5"
                                >
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </div>
                        </slot>
                    </div>

                    <slot name="header-content" :close="close">
                        <div
                            v-if="title || $scopedSlots.header"
                            class="w-full px-5 pt-5"
                        >
                            <slot name="header" :close="close">
                                <span class="text-2xl font-black">{{ title }}</span>
                            </slot>
                        </div>
                    </slot>

                    <slot name="content" :close="close">
                        <div
                            v-if="$scopedSlots.default"
                            class="my-4 overflow-auto px-5"
                        >
                            <slot :close="close" />
                        </div>
                    </slot>

                    <slot name="footer-content" :close="close">
                        <div v-if="$scopedSlots.footer" class="w-full px-5 pb-5 text-right">
                            <slot name="footer" :close="close" />
                        </div>
                    </slot>
                </div>
            </div>
        </transition>
    </portal>
</template>
