<template>
    <base-button
        id="dev-sandbox-button"
        label="Dev Sandbox"
        size="small"
        text
        stretch
        :data-test="dataTest()"
        @click="go"
    >
        <template #prefix>
            <svg
                class="w-5 h-5"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                role="img"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 512 512"
            >
                <path
                    d="M0 357.723l247 140v-120l-247-110v90zm512-89.982L265 377.723v120l247-139.485V267.74zm-56.836 10.165l38.761-17.14l-85.089-32.248l-7.875 21.283c15.527 6.87 34.05 16.686 54.203 28.105zM357.346 14.277l-16.093 45.184c-3.573 12.497 2.835 31.654 23.408 35.674l-26.813 74.81c-21.792-9.103-41.243-2.83-52.934 9.831l-29.391-11.287l-237.463 92.24l38.686 16.895c63.562-37.882 138.756-64.027 210.764-59.892l-10.436 28.036c-27.346.81-50.73 7.678-71.233 18.952c71.534-11.513 146.05 7.61 201.156 35.938c-1.33-6.491-4.985-12.38-11.079-18.21l19.494-55.249c5.88-15.783 1.238-45.648-35.281-51.064l26.925-74.585c25.23 6.35 40.246-10.084 43.793-20.961l14.698-41.863l-88.201-24.449zM407.6 74.891c-.728 3.033-6.074 5.518-10.314 3.64l-26.33-7.401c-5.125-2.017-7.111-5.153-5.46-9.586l6.189-17.472l42.952 12.012l-7.037 18.807z"
                    fill="currentColor"
                />
            </svg>
        </template>
    </base-button>
</template>

<script>
export default {
    name:    'NavigationDevSandbox',
    methods: {
        go() {
            this.$emit('navigate');

            this.$router.push({ name: 'devSandbox' });
        },
    },
};
</script>
