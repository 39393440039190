<script>
import SidebarSection from '@/components/app/sidebar/section/section';

export default {
    name:     'SidebarMigration',
    extends:     SidebarSection,
    data() {
        return {
            sectionName:  'migration',
            sectionRoute: 'migrationList',
            sectionLabel: this.$t('home migrations title'),
            sectionIcon:  {
                name:   'file-import',
                prefix: 'fas',
            },
        };
    },
};
</script>
