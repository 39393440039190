<script>
export default {
    name: 'BaseTag',
};
</script>

<script setup>
import { SIZE, TYPE } from '@/components/base-components/components/tag';

defineProps({
    label:    {
        type:     [Number, String],
        required: true,
    },
    type:     {
        type:      String,
        default:   TYPE.DEFAULT,
        validator: value => Object.values(TYPE).includes(value),
    },
    size:     {
        type:      String,
        default:   SIZE.SMALL,
        validator: value => Object.values(SIZE).includes(value),
    },
    round:    {
        type: Boolean,
    },
    closable: {
        type: Boolean,
    },
});

const emit = defineEmits({
    close: null,
});
</script>

<template>
    <div
        class="inline-flex items-center self-center border"
        :class="[
            {
                [TYPE.DEFAULT]: 'text-gray-600/60 bg-gray-600/5 border-gray-600/10',
                [TYPE.PRIMARY]: 'text-primary-600/60 bg-primary-600/5 border-primary-600/20',
                [TYPE.SECONDARY]: 'text-secondary-800/60 bg-secondary-800/5 border-secondary-800/10',
                [TYPE.PRIMARY_OLD]: 'text-science-blue-500/60 bg-science-blue-500/5 border-science-blue-500/10',
                [TYPE.SECONDARY_OLD]: 'text-science-blue-800/60 bg-science-blue-800/5 border-science-blue-800/10',
                [TYPE.SUCCESS]: 'text-emerald-600/60 bg-emerald-600/5 border-emerald-600/10',
                [TYPE.WARNING]: 'text-orange-500/60 bg-orange-500/5 border-orange-500/10',
                [TYPE.DANGER]: 'text-red-600/60 bg-red-600/5 border-red-600/10',
                [TYPE.INFO]: 'text-gray-500/60 bg-gray-500/5 border-gray-500/10',
            }[type],
            {
                [SIZE.LARGE]: 'text-base',
                [SIZE.MEDIUM]: 'text-base',
                [SIZE.SMALL]: 'text-sm',
                [SIZE.MINI]: 'text-xs',
            }[size],
            round ? 'rounded-full' : 'rounded',
        ]"
    >
        <span
            :class="[
                {
                    [SIZE.LARGE]: 'px-2.5 leading-7',
                    [SIZE.MEDIUM]: 'px-2 leading-6',
                    [SIZE.SMALL]: 'px-1.5 leading-5',
                    [SIZE.MINI]: 'px-1 leading-4',
                }[size],
            ]"
        >
            <slot>
                {{ label }}
            </slot>
        </span>

        <svg
            v-if="closable"
            class="cursor-pointer transition-colors duration-150 hover:text-white"
            :class="[
                {
                    [TYPE.DEFAULT]: 'text-gray-600/50 hover:bg-gray-600/20',
                    [TYPE.PRIMARY]: 'text-primary-500/50 hover:bg-primary-500/20',
                    [TYPE.SECONDARY]: 'text-secondary-800/50 hover:bg-secondary-800/20',
                    [TYPE.PRIMARY_OLD]: 'text-science-blue-500/50 hover:bg-science-blue-500/20',
                    [TYPE.SECONDARY_OLD]: 'text-science-blue-800/50 hover:bg-science-blue-800/20',
                    [TYPE.SUCCESS]: 'text-emerald-600/50 hover:bg-emerald-600/20',
                    [TYPE.WARNING]: 'text-orange-500/50 hover:bg-orange-500/20',
                    [TYPE.DANGER]: 'text-red-600/50 hover:bg-red-600/20',
                    [TYPE.INFO]: 'text-gray-500/50 hover:bg-gray-500/20',
                }[type],
                {
                    [SIZE.LARGE]: 'mr-1 h-5 w-5',
                    [SIZE.MEDIUM]: 'mr-0.5 h-5 w-5',
                    [SIZE.SMALL]: 'mr-0.5 h-4 w-4',
                    [SIZE.MINI]: 'mr-px h-3.5 w-3.5',
                }[size],
                round ? 'rounded-full' : 'rounded-sm'
            ]"
            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
            @click="e => emit('close', e)"
        >
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
    </div>
</template>
