<script>
export default {
    name: 'OAuthModal',
};
</script>

<script setup>
import crs from 'crypto-random-string';
import { ref } from 'vue';
import notify from '@/components/base-components/components/notification/notify';
import { getUrl } from '@/api/utils';
import i18n from '@/plugins/i18n';
import { getChannel } from '@/components/o-auth/constants';
import useBroadcastChannel from '@/composables/useBroadcastChannel';

const props = defineProps({
    type:   {
        type:      String,
        default:   'window',
        validator: value => ['tab', 'window'].includes(value),
    },
    height: {
        type:    Number,
        default: 750,
    },
    width:  {
        type:    Number,
        default: 800,
    },
});

const callbacks = ref({});

function resetCallbacks() {
    callbacks.value = {};
}

function onReject() {
    callbacks.value.reject?.();
}

function onResolve(data) {
    callbacks.value.resolve?.(data);
}

function getFeatures() {
    if (props.type === 'tab') return;

    const left = (window.screen.width / 2) - (props.width / 2);
    const top = (window.screen.height / 2) - (props.height / 2);

    const options = {
        toolbar:     'no',
        location:    'no',
        directories: 'no',
        status:      'no',
        menubar:     'no',
        scrollbars:  'no',
        resizable:   'no',
        copyhistory: 'no',
        top:         Math.round(top),
        left:        Math.round(left),
        width:       props.width,
        height:      props.height,
    };

    return Object.entries(options).map(([key, value]) => `${key}=${value}`).join(', ');
}

let oAuthWindow = null;

function openWindow(url, features = null) {
    oAuthWindow = window.open(url, '_blank', features);

    if (!oAuthWindow || oAuthWindow.closed || typeof oAuthWindow.closed === 'undefined') {
        notify.warning({
            title:   i18n.t('request warning notification'),
            message: i18n.t('tmp Popups is blocked in your browser, please, allow popups to continue.'),
        });

        return false;
    }

    const checkClosed = () => {
        if (!oAuthWindow || !!oAuthWindow.closed) return onReject();

        setTimeout(checkClosed, 10);
    };

    checkClosed();

    window.addEventListener('beforeunload', closeWindow);

    return true;
}

function closeWindow() {
    if (oAuthWindow.closed) return;

    oAuthWindow?.close();
    window.removeEventListener('beforeunload', closeWindow);
}

function focusWindow() {
    oAuthWindow?.focus();
}

const show = ref(false);

function close() {
    closeWindow();
    show.value = false;
}

defineExpose({
    process: (platformType, queryParams = {}) => {
        const state = crs({ length: 20, type: 'alphanumeric' });

        const url = getUrl(`/oauth/${platformType}/authorize`, {
            ...queryParams,
            state,
        });
        const features = getFeatures();

        if (!openWindow(url, features)) return null;

        show.value = true;

        const { close: closeBroadcast } = useBroadcastChannel(getChannel(state), (data) => {
            if (!data) return onReject();

            onResolve(data);
        });

        return new Promise((resolve) => {
            callbacks.value = {
                resolve: (data) => {
                    close();
                    closeBroadcast();

                    resolve(data);
                },
                reject:  () => {
                    close();
                    closeBroadcast();

                    resolve(null);
                },
            };
        });
    },
});
</script>

<template>
    <base-modal
        :value="show"
        size="fit"
        :title="$t('oauth waiting connection title')"
        :show-close="false"
        :close-on-click-overlay="false"
        :close-on-press-escape="false"
        @close="onReject"
        @closed="resetCallbacks"
    >
        <ol class="list-inside list-decimal text-sm">
            <li>{{ $t('oauth waiting connection step 1') }}</li>
            <li>{{ $t('oauth waiting connection step 2') }}</li>
        </ol>

        <template #footer>
            <div class="inline-flex items-center justify-center gap-x-2">
                <base-button
                    type="primary"
                    round
                    @click="focusWindow"
                >
                    {{ $t('form button continue') }}
                </base-button>
                <base-button
                    round
                    @click="onReject"
                >
                    {{ $t('tmp Cancel') }}
                </base-button>
            </div>
        </template>
    </base-modal>
</template>
