<template>
    <div
        class="inline-flex items-center gap-x-3 rounded-md px-4 py-2.5"
        :class="typeClass"
    >
        <slot v-if="icon || $slots.prefix" name="prefix">
            <base-icon v-bind="iconData" />
        </slot>
        <div class="flex flex-1 flex-col gap-y-1 text-sm">
            <slot
                v-if="title || $slots.title"
                name="title"
            >
                <span
                    v-text="title"
                    class="font-semibold"
                />
            </slot>
            <slot v-if="content || $slots.default">
                <span v-text="content" />
            </slot>
        </div>
        <div
            v-if="closable"
            class="-mt-1 -mr-3 self-stretch"
        >
            <base-button
                custom-size-class="h-6 w-6"
                size="small"
                text
                :type="type"
                :icon="{
                    name: 'times',
                    class: 'text-xs',
                }"
                @click="$emit('close')"
            />
        </div>
    </div>
</template>

<script>
export default {
    name:     'BaseAlert',
    props:    {
        type:     {
            type:      String,
            default:   'info',
            validator: value => ['success', 'warning', 'danger', 'info'].includes(value),
        },
        title:    {
            type: String,
        },
        content:  {
            type: String,
        },
        icon:     {
            type: [Boolean, Object],
        },
        closable: {
            type: Boolean,
        },
    },
    computed: {
        typeClass() {
            return {
                success: 'bg-emerald-600 bg-opacity-10 text-emerald-600',
                warning: 'bg-amber-500 bg-opacity-20 text-orange-500',
                danger:  'bg-red-500 bg-opacity-10 text-red-600',
                info:    'bg-gray-400 bg-opacity-10 text-gray-500',
            }[this.type];
        },
        iconData() {
            if (!this.icon) return;

            const typeIcon = {
                success: {
                    name:  'check-circle',
                    class: 'text-emerald-600',
                },
                warning: {
                    name:  'exclamation-triangle',
                    class: 'text-orange-500',
                },
                danger:  {
                    name:  'exclamation-circle',
                    class: 'text-red-600',
                },
                info:    {
                    name:  'info-circle',
                    class: 'text-gray-400',
                },
            }[this.type];

            return {
                ...typeIcon,
                ...(this.icon === true ? {} : this.icon),
            };
        },
    },
};
</script>
