<template>
    <div class="flex flex-wrap flex-1 items-center gap-x-2 gap-y-1">
        <component
            v-for="(item, index) in list"
            :key="index"
            :is="getComponent(item)"
            :item="item"
        />
    </div>
</template>

<script>
import ManagerHeaderNavigationItem from '@/components/manager/header/navigation/item';
import ManagerHeaderNavigationNested from '@/components/manager/header/navigation/nested';

export default {
    name:       'ManagerHeaderNavigation',
    components: { ManagerHeaderNavigationNested, ManagerHeaderNavigationItem },
    props:      {
        list: {
            type:     Array,
            required: true,
        },
    },
    methods:    {
        getComponent(item) {
            return item.items ? ManagerHeaderNavigationNested : ManagerHeaderNavigationItem;
        },
    },
};
</script>
