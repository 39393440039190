import { script } from '@/assets/util/append-file';
import { getWindowHashParam } from '@/assets/util/url-hash';

export default {
    install(Vue, { config }) {
        if (!config.statistic?.hubspotId) return;

        const openChat = getWindowHashParam('openChat') === 'true';

        window.hsConversationsOnReady = [
            () => {
                if (window.innerWidth < 768) {
                    window.HubSpotConversations.widget.load({ widgetOpen: false });
                }

                if (window.HubSpotConversations && openChat) {
                    window.HubSpotConversations.widget.open();
                }
            },
        ];

        script(`//js.hs-scripts.com/${config.statistic.hubspotId}.js`, true, {
            id:    'hs-script-loader',
            defer: true,
        });
    },
};
