export default {
    methods: {
        getCrmCookie(key) {
            return this.$dataCookies.get(this.formatCrmCookieKey(key));
        },
        setCrmCookie(key, data) {
            if (typeof data === 'object') {
                this.$dataCookies.set(this.formatCrmCookieKey(key), JSON.stringify(data));
                return true;
            }

            return false;
        },
        formatCrmCookieKey(prefix) {
            return `${prefix}Data`;
        },
    },
};
