<script>
export default {
    name: 'BaseVideo',
};
</script>

<script setup>
import { computed } from 'vue';

defineProps({
    value:       {
        type: Boolean,
    },
    link:        {
        type:     String,
        required: true,
    },
    customStyle: {
        type:    [String, Object],
        default: 'aspect-ratio: 16 / 9;',
    },
});

const emit = defineEmits({
    input: null,
    close: null,
});

const model = computed({
    get() {
        return this.value;
    },
    set(value) {
        emit('input', value);
    },
});
</script>

<template>
    <base-modal
        v-model="model"
        :show-close="false"
        size="large"
        @close="emit('close')"
    >
        <template #content>
            <iframe
                allowfullscreen
                class="w-full"
                allow="autoplay; encrypted-media"
                frameborder="0"
                :src="`${link}?rel=0&showinfo=0&autoplay=1`"
                :style="customStyle"
            />
        </template>
    </base-modal>
</template>
