const grades = {
    A: 71,
    B: 41,
    C: 21,
    D: 11,
    F: 0,
};

/**
 * Format letter grade
 *
 * @param {Number} percentage
 *
 * @returns {String}
 */
const formatLetterGrade = (percentage) => {
    let result = 'F';

    Object.entries(grades).some(([grade, score]) => {
        if (percentage >= score) {
            result = grade;
            return true;
        }

        return false;
    });

    return result;
};

export default formatLetterGrade;
