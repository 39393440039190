<template>
    <header class="bg-white shadow z-105" :data-test="dataTest()">
        <div class="mx-auto flex h-16 items-center justify-between pr-4 pl-12">
            <header-breadcrumbs />
            <div class="flex items-center gap-x-2">
                <router-link
                    :to="{ name: 'help' }"
                    class="text-gray-600 hover:text-science-blue-900 focus:text-science-blue-900 active:text-science-blue-900"
                >
                    <base-icon
                        name="question-circle"
                        prefix="far"
                    />
                </router-link>

                <div class="-mr-2 flex shrink-0 items-center justify-end space-x-2 md:hidden">
                    <button
                        class="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 focus:bg-gray-100 focus:outline-none"
                        @click="showMobileMenu = !showMobileMenu"
                    >
                        <svg
                            class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24"
                            :class="showMobileMenu ? 'hidden' : 'block'"
                        >
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                        </svg>
                        <svg
                            class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24"
                            :class="showMobileMenu ? 'block' : 'hidden'"
                        >
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
                <div class="hidden shrink-0 space-x-4 md:flex md:items-center md:justify-end">
                    <header-login v-if="!userExists" />
                    <header-user v-else />
                </div>
            </div>
        </div>
        <div v-if="showMobileMenu" class="border-t border-gray-200 py-1 md:hidden">
            <header-navigation />
        </div>
    </header>
</template>

<script>
import { mapGetters } from 'vuex';
import HeaderBreadcrumbs from '@/components/app/header/breadcrumbs';
import HeaderLogin from '@/components/app/header/navigation/login';
import HeaderUser from '@/components/app/header/user';
import HeaderNavigation from '@/components/app/header/navigation';

export default {
    name:       'AppHeader',
    components: {
        HeaderLogin,
        HeaderUser,
        HeaderBreadcrumbs,
        HeaderNavigation,
    },
    data() {
        return {
            showMenu:       false,
            showMobileMenu: false,
            showAddFunds:   false,
        };
    },
    computed: {
        ...mapGetters({
            userExists: 'user/isExists',
            userEmail:  'user/getEmail',
        }),
        email() {
            return this.userEmail || this.$t('header user guest');
        },
    },
};
</script>
