<script>
export default {
    name: 'BaseJsonFormItemError',
};
</script>

<script setup>
import { computed } from 'vue';

const props = defineProps({
    validations: {
        type: Object,
    },
    errors:      {
        type: [Array, Object],
    },
    maxErrors:   {
        type:    Number,
        default: 1,
    },
});

const fieldErrors = computed(() => {
    if (props.errors === undefined) return [];

    if (Array.isArray(props.errors)) return props.errors;

    return Object.values(props.errors);
});

const validationErrors = computed(() => props.validations?.$silentErrors?.map(({ $message }) => $message) ?? []);

const displayErrors = computed(() => [
    ...fieldErrors.value,
    ...validationErrors.value,
].slice(0, props.maxErrors));
</script>

<template>
    <div v-if="displayErrors.length">
        <div
            v-for="(error, index) in displayErrors"
            :key="index"
            class="text-xs text-red-500"
        >
            {{ error }}
        </div>
    </div>
</template>
