import { isString } from 'lodash';

export {
    isNumber,
    isString,
    isArray,
    isPlainObject as isObject,
} from 'lodash';

export const isStringNumber = (value) => {
    if (!isString(value)) return false;

    return !Number.isNaN(Number(value));
};
