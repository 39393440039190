export const GAP = 4;

export const BAR_MAP = {
    vertical:   {
        offset:            'offsetHeight',
        scroll:            'scrollTop',
        scrollSize:        'scrollHeight',
        size:              'height',
        oppositeSize:      'width',
        key:               'vertical',
        axis:              'Y',
        client:            'clientY',
        direction:         'top',
        oppositeDirection: 'left',
    },
    horizontal: {
        offset:            'offsetWidth',
        scroll:            'scrollLeft',
        scrollSize:        'scrollWidth',
        size:              'width',
        oppositeSize:      'height',
        key:               'horizontal',
        axis:              'X',
        client:            'clientX',
        direction:         'left',
        oppositeDirection: 'top',
    },
};

export const renderThumbStyle = ({ move, size, bar }) => ({
    [bar.size]: size,
    transform:  `translate${bar.axis}(${move}%)`,
});
