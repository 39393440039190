const prefix = 'fac';
const iconName = 'function';
const width = 512;
const height = 512;
const ligatures = [];
const unicode = null;
const svgPathData = 'M324.278,238.934v-51.201c0-4.72-3.822-8.534-8.533-8.534H256.01v-68.266c0-24.166,17.784-43.103,40.482-43.103  c23.894,0,44.851,20.139,44.851,43.103c0,4.72,3.823,8.534,8.534,8.534h51.2c4.712,0,8.534-3.814,8.534-8.534  C409.612,50.8,357.814,0,296.494,0c-59.964,0-108.748,49.768-108.748,110.933v68.266h-59.735c-4.711,0-8.533,3.814-8.533,8.534  v51.201c0,4.72,3.822,8.533,8.533,8.533h59.735v153.6c0,23.125-19.542,42.667-42.667,42.667s-42.667-19.542-42.667-42.667  c0-4.72-3.823-8.533-8.534-8.533h-51.2c-4.712,0-8.534,3.813-8.534,8.533C34.144,462.235,83.911,512,145.076,512  c61.166,0,110.934-49.768,110.934-110.934v-153.6h59.734C320.455,247.467,324.278,243.654,324.278,238.934z M439.402,426.668  l28.766-28.766c12.921-12.921,12.921-33.946,0-46.857c-12.927-12.921-33.945-12.921-46.866,0l-28.766,28.757l-28.767-28.757  c-12.92-12.921-33.945-12.921-46.866,0c-12.92,12.92-12.92,33.945,0,46.857l28.767,28.766l-28.767,28.767  c-12.92,12.92-12.92,33.945,0,46.857c6.461,6.46,14.941,9.694,23.432,9.694c8.482,0,16.973-3.234,23.433-9.694l28.767-28.758  l28.766,28.758c12.921,12.92,33.955,12.92,46.866,0c12.921-12.921,12.921-33.946,0-46.857L439.402,426.668z';

export const definition = {
    prefix,
    iconName,
    icon: [
        width,
        height,
        ligatures,
        unicode,
        svgPathData,
    ],
};

export { definition as faFunction };
