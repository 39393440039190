<script>
export default {
    name: 'BaseJsonFormItemDate',
};
</script>

<script setup>
import { computed } from 'vue';
import toISOString from '@/assets/util/to-iso-string';
import toISOTime from '@/assets/util/to-iso-time';

const props = defineProps({
    value:    {
        type: String,
    },
    title:    {
        type: String,
    },
    type:     {
        type: String,
    },
    format:   {
        type: String,
    },
    disabled: {
        type: Boolean,
    },
});

const isDate = computed(() => props.type === 'date' || props.format === 'date');
const isTime = computed(() => props.type === 'time' || props.format === 'time');
const formattedValue = computed(() => (!isTime.value ? props.value : `2000-01-01T${props.value}`));

const emit = defineEmits({
    input: null,
});

const model = computed({
    get() {
        const parsed = Date.parse(formattedValue.value);

        if (Number.isNaN(parsed)) return undefined;

        return new Date(formattedValue.value);
    },
    set(value) {
        emit('input', value);
    },
});

function input(value) {
    if (!value) return;

    if (!isTime.value) {
        model.value = toISOString(value);
        return;
    }

    model.value = toISOTime(value);
}
</script>

<template>
    <el-date-picker
        v-if="isDate"
        type="date"
        format="MM/dd/yyyy"
        :value="model"
        :clearable="false"
        :disabled="disabled"
        :placeholder="title"
        @input="input"
    />
    <el-time-picker
        v-else-if="isTime"
        format="HH:mm:ss"
        :value="model"
        :clearable="false"
        :disabled="disabled"
        :placeholder="title"
        @input="input"
    />
    <el-date-picker
        v-else
        type="datetime"
        format="MM/dd/yyyy HH:mm:ss"
        :value="model"
        :clearable="false"
        :disabled="disabled"
        :placeholder="title"
        @input="input"
    />
</template>
