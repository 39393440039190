<script>
export default {
    name: 'ServicePlanFeatureCalendahActiveCalendar',
};
</script>

<script setup>
import { computed, toRef } from 'vue';
import i18n from '@/plugins/i18n';
import { CALENDAR_TYPE_LABEL } from '@/modules/calendah/constants';
import { FIELD } from '@/components/payments/components/service-plan/components/features/constants';
import useFeature from '@/components/payments/components/service-plan/components/features/composables/use-feature';
import ServicePlanFeatureElementsValue from '@/components/payments/components/service-plan/components/features/components/elements/value';

const props = defineProps({
    feature: {
        type:     Object,
        required: true,
    },
});

const { value } = useFeature(toRef(props, 'feature'));

const formatted = computed(() => {
    const mappedLevels = value.value.map(level => `<b>${CALENDAR_TYPE_LABEL[level]}</b>`);

    if (value.value.length < 2) return i18n.t('payment.servicePlan.feature.product.calendah.accessLevel.one', { level: mappedLevels });

    const last = mappedLevels.pop();
    const levels = `${mappedLevels.join(', ')} and ${last}`;

    return i18n.t('payment.servicePlan.feature.product.calendah.accessLevel.many', { levels });
});
</script>

<template>
    <service-plan-feature-elements-value
        :name="feature[FIELD.NAME]"
        :value="formatted"
    />
</template>
