import qs from 'qs';
import Api from '@/api/api';
import MappingProvider from '@/modules/mapping/api/provider';
import { getUrl } from '@/api/utils';
import { MAPPING_STRATEGY } from '@/modules/mapping/constants';

const api = new Api(new MappingProvider());

export const actionUrl = (action, queryParams = {}) => getUrl(`/mapping/mapping-${action}`, queryParams);

function POST(action, data, config) {
    return api.post(actionUrl(action), qs.stringify(data), config);
}

function GET(action, config) {
    return api.get(actionUrl(action), config);
}

export function mappingGet(productId, productType, entityId) {
    return POST('get', {
        productId,
        productType,
        entityId,
    });
}

export function mappingInit() {
    return GET('init');
}

export function mappingAutomap(source, target, mapping, { productId, productType, entityId }) {
    return POST('automapp', {
        mapping: JSON.stringify(mapping),
        source,
        target,
        productId,
        productType,
        entityId,
    });
}

export function mappingAutoMapEnum(source, target, current = [], strategy = MAPPING_STRATEGY.NATIVE, allowOverturn = false) {
    const params = {
        source: JSON.stringify(source),
        target: JSON.stringify(target),
        strategy,
        allowOverturn,
    };

    if (current.length) {
        params.current = JSON.stringify(current);
    }

    return POST('auto-map-enum', params);
}

export function mappingCountTokens(message, strategy = MAPPING_STRATEGY.NATIVE) {
    return POST('count-tokens', {
        message,
        strategy,
    });
}

export function mappingCompare(from, to) {
    return POST('compare', {
        from: JSON.stringify(from),
        to:   JSON.stringify(to),
    });
}

export function mappingConstantValueBlock(value, directionRule) {
    return POST('constant-value-block', {
        value,
        directionRule: JSON.stringify(directionRule),
    });
}

export function mappingCustomFieldsList({ productId, productType, entityId }) {
    return POST('custom-fields-list', {
        productId,
        productType,
        entityId,
    });
}

export function mappingCustomFieldsGenerateData(fieldType, field, { productId, productType, entityId }) {
    return POST('custom-fields-generate-data', {
        field: JSON.stringify(field),
        fieldType,
        productId,
        productType,
        entityId,
    });
}

export function mappingCustomFieldsCreate(originHash, fieldId, fieldType, fieldData, { productId, productType, entityId }) {
    return POST('custom-fields-create', {
        fieldData: JSON.stringify(fieldData),
        originHash,
        fieldId,
        fieldType,
        productId,
        productType,
        entityId,
    });
}

export function mappingCustomFieldsCreateBulk(fields, { productId, productType, entityId }) {
    return POST('custom-fields-create-bulk', {
        fields: JSON.stringify(fields),
        productId,
        productType,
        entityId,
    });
}

export function mappingCustomFieldsDelete(fieldId, { productId, productType, entityId }) {
    return POST('custom-fields-delete', {
        fieldId,
        productId,
        productType,
        entityId,
    });
}

export function mappingCustomFieldsGetTypes(fields, types, platform) {
    return POST('custom-fields-get-types', {
        fields: JSON.stringify(fields),
        types:  JSON.stringify(types),
        platform,
    });
}

export function mappingMultiplyEnum(enums) {
    return POST('multiply-enums', {
        enums: JSON.stringify(enums),
    });
}

export function mappingFieldAction(action, data) {
    return POST(action, data);
}

export function mappingFieldActionTerminate(list) {
    return POST('action-terminate', {
        list,
    });
}

export function mappingPointersCombinations(rules, direction, randomString, { productId, productType, entityId }) {
    return POST('pointers-combinations', {
        rules: JSON.stringify(rules),
        direction,
        randomString,
        productId,
        productType,
        entityId,
    });
}

export function mappingMergeWithActual(payload, { productId, productType, entityId }) {
    return POST('merge-with-actual', {
        payload: JSON.stringify(payload),
        productId,
        productType,
        entityId,
    });
}

export function mappingPresetList({ productId, productType, entityId }) {
    return POST('preset-list', {
        productId,
        productType,
        entityId,
    });
}

export function mappingPresetCreate(checked, { productId, productType, entityId }) {
    return POST('preset-create', {
        checked,
        productId,
        productType,
        entityId,
    });
}

export function mappingPresetEdit(checked, preset, { productId, productType, entityId }) {
    return POST('preset-edit', {
        checked,
        preset,
        productId,
        productType,
        entityId,
    });
}

export function mappingPresetView(preset) {
    return POST('preset-view', {
        preset,
    });
}

export function mappingPresetExportData(preset) {
    return POST('preset-export-data', {
        preset: JSON.stringify(preset),
    });
}

export function mappingRefresh(autoMap, { productId, productType, entityId }) {
    return POST('refresh', {
        autoMap,
        productId,
        productType,
        entityId,
    });
}

export function mappingReset({ productId, productType, entityId }) {
    return POST('reset', {
        productId,
        productType,
        entityId,
    });
}

export function mappingSave(payload, versionInfo, { productId, productType, entityId }) {
    return POST('save', {
        payload:     JSON.stringify(payload),
        versionInfo: JSON.stringify(versionInfo),
        productId,
        productType,
        entityId,
    });
}

export function mappingTestData(recordId, mapping, { productId, productType, entityId }) {
    return POST('test-data', {
        mapping: JSON.stringify(mapping),
        recordId,
        productId,
        productType,
        entityId,
    });
}

export function mappingUnlockRequest({ productId, productType, entityId }) {
    return POST('unlock-request', {
        productId,
        productType,
        entityId,
    });
}

export function mappingValidate(mapping) {
    return POST('validate', {
        mapping: JSON.stringify(mapping),
    });
}

export function mappingVersionUse(versionId, { productId, productType, entityId }) {
    return POST('version-use', {
        versionId,
        productId,
        productType,
        entityId,
    });
}

export function mappingVersionDelete(versionId, { productId, productType, entityId }) {
    return POST('version-delete', {
        versionId,
        productId,
        productType,
        entityId,
    });
}

export function mappingVersionList({ productId, productType, entityId }) {
    return POST('version-list', {
        productId,
        productType,
        entityId,
    });
}

export function mappingVersionGet(versionId, { productId, productType, entityId }) {
    return POST('version-get', {
        versionId,
        productId,
        productType,
        entityId,
    });
}

export function mappingVersionChangeImportant(versionId, value, { productId, productType, entityId }) {
    return POST('version-change-important', {
        versionId,
        value,
        productId,
        productType,
        entityId,
    });
}

export function mappingFieldGet(id, type, direction, { productId, productType, entityId }) {
    return POST('field-get', {
        id,
        type,
        direction,
        productId,
        productType,
        entityId,
    });
}

export function mappingFieldUpdate(id, type, direction, data, { productId, productType, entityId }) {
    return POST('field-update', {
        id,
        type,
        direction,
        data: JSON.stringify(data),
        productId,
        productType,
        entityId,
    });
}

export function mappingNotesGet({ productId, productType, entityId }) {
    return POST('notes-get', {
        productId,
        productType,
        entityId,
    });
}

export function mappingNotesSave(notes, { productId, productType, entityId }) {
    return POST('notes-save', {
        notes: JSON.stringify(notes),
        productId,
        productType,
        entityId,
    });
}
