import { helpers, maxLength, maxValue, minLength, minValue, required as requiredValidator } from '@vuelidate/validators';

export const validations = {
    minLength:     min => helpers.withMessage(`The input is less than ${min} characters long.`, minLength(min)),
    maxLength:     max => helpers.withMessage(`The input is more than ${max} characters long.`, maxLength(max)),
    minimum:       min => helpers.withMessage(`The input is less than ${min}.`, minValue(min)),
    maximum:       max => helpers.withMessage(`The input is greater than ${max}.`, maxValue(max)),
    formatMinimum: min => helpers.withMessage(
        `The input is less than ${min}.`,
        helpers.withParams(
            { type: 'formatMinimum' },
            value => (value ? new Date(value).toISOString() >= new Date(min).toISOString() : true),
        ),
    ),
    formatMaximum: max => helpers.withMessage(
        `The input is greater than ${max}.`,
        helpers.withParams(
            { type: 'formatMaximum' },
            value => (value ? new Date(value).toISOString() <= new Date(max).toISOString() : true),
        ),
    ),
    pattern:       pattern => helpers.withMessage(
        `The input does not match against pattern ${pattern}`,
        helpers.withParams(
            { type: 'pattern' },
            value => (value ? (new RegExp(pattern.slice(1, -1))).test(value) : true),
        ),
    ),
    required:      () => helpers.withMessage('Value is required and can\'t be empty', requiredValidator),
    oneOf:         (list, key = 'name') => helpers.withMessage(
        'Value is not selected',
        helpers.withParams(
            { type: 'list', list, key },
            value => list.map(item => item?.[key] ?? item.name ?? item).includes(value),
        ),
    ),
};

export function getValidationRules(field, schema, required) {
    const validators = Object.entries(validations).reduce((result, [name, callback]) => {
        if (schema[name] !== undefined) {
            result[name] = callback(schema[name]);
        }

        return result;
    }, {});

    if (!required) return validators;

    if (!schema.enum || schema.extra?.restricted === false || schema.type === 'boolean') {
        validators.required = validations.required();

        return validators;
    }

    validators.required = validations.oneOf(schema.enum);

    return validators;
}

export const baseJsonFormDisabledKey = Symbol('baseJsonFormDisabled');
export const baseJsonFormLinksKey = Symbol('baseJsonFormLinks');
export const baseJsonFormPropertiesKey = Symbol('baseJsonFormPropertiesKey');
export const baseJsonFormValidationScopeKey = Symbol('baseJsonFormValidationScope');
