<script>
export default {
    name: 'BaseJsonFormItem',
};
</script>

<script setup>
import { computed, inject, watch } from 'vue';
import { isString as checkIsString } from '@/components/base-components/utils/types';
import BaseJsonFormItemDate from '@/components/base-components/components/json-form/base-json-form-item-date';
import BaseJsonFormItemError from '@/components/base-components/components/json-form/base-json-form-item-error';
import {
    baseJsonFormDisabledKey,
    baseJsonFormLinksKey,
    baseJsonFormPropertiesKey,
} from '@/components/base-components/components/json-form/index';

const props = defineProps({
    value:       {
        type: [Object, Array, String, Number, Boolean],
    },
    name:        {
        type:     String,
        required: true,
    },
    data:        {
        type:     Object,
        required: true,
    },
    errors:      {
        type: Object,
    },
    required:    {
        type: Boolean,
    },
    showTitle:   {
        type: Boolean,
    },
    validations: {
        type: Object,
    },
});

const emit = defineEmits({
    input: null,
});

const type = computed(() => props.data.type);

const isBoolean = computed(() => type.value === 'boolean');
const isNumber = computed(() => ['number', 'integer', 'decimal'].includes(type.value));
const isString = computed(() => type.value === 'string');
const isDate = computed(() => type.value === 'date' || props.data.format === 'date');
const isTime = computed(() => type.value === 'time' || props.data.format === 'time');
const isDatetime = computed(() => type.value === 'datetime' || props.data.format === 'date-time');
const isArray = computed(() => props.data.type === 'array');

const isNumberString = computed(() => isString.value
    && ['number', 'integer', 'decimal'].includes(props.data.extra?.subtype));
const isEnum = computed(() => !!props.data.enum || (isArray.value && !!props.data.items.enum));
const isBoolEnum = computed(() => isEnum.value && isBoolean.value);

const isJSON = computed(() => type.value === 'json');
const isObject = computed(() => type.value === 'object');

const title = computed(() => props.data.title || props.name);
const tooltip = computed(() => props.data.tooltip);
const displayTitle = computed(() => {
    if (!props.showTitle || isBoolean.value) return undefined;
    if (props.data.title === null) return undefined;

    return title.value;
});

const model = computed({
    get() {
        if (isNumberString.value || isNumber.value) return Number.parseInt(props.value, 10);
        if (isBoolean.value || isBoolEnum.value) return !!props.value;

        return props.value;
    },
    set(value) {
        emit('input', isNumberString.value ? (value ?? 0).toString() : value);
    },
});

watch(type, (to, from) => {
    if (to === from) return;

    if (to === 'string' && !checkIsString(model.value) && !isNumberString.value && !isEnum.value) {
        model.value = '';
        return;
    }

    if (to === 'boolean') {
        model.value = !!model.value;
    }
}, { immediate: true });

watch([() => props.data, model], () => {
    if (model.value !== undefined && !Number.isNaN(model.value)) return;

    if (props.data.default !== undefined) {
        model.value = props.data.default;
        return;
    }

    if (!props.required) return;

    if (isBoolean.value) {
        model.value = false;
        return;
    }

    if (isNumber.value) {
        model.value = 0;
        return;
    }

    if (isNumberString.value) {
        model.value = '0';
    }
}, { immediate: true });

const disabled = inject(baseJsonFormDisabledKey);
const baseLinks = inject(baseJsonFormLinksKey);
const properties = inject(baseJsonFormPropertiesKey);

const links = computed(() => {
    const { on, condition } = baseLinks.value?.[props.name] ?? {};

    if (!on) return;

    const onNames = Array.isArray(on) ? on : [on];

    const onFields = onNames
        .filter(onName => onName !== props.name
            && properties.value[onName]
            && (condition?.(properties.value[onName], props.data) ?? true))
        .map(property => properties.value[property]);

    return onFields.length ? onFields : null;
});

const invalid = computed(() => props.validations?.$invalid ?? false);
</script>

<template>
    <div class="flex flex-col gap-y-0.5">
        <div v-if="displayTitle" class="flex items-center gap-x-1 text-sm font-medium">
            <span v-if="required" class="text-red-500">*</span>
            <span class="text-gray-500">{{ displayTitle }}</span>
            <base-tooltip v-if="links" class="inline-block text-gray-400">
                <base-icon name="link" size="sm" />
                <template #content>
                    <span>
                        <span>This field will be automatically updated based on </span>
                        <span
                            v-for="(link, index) in links"
                            :key="link.title"
                        >
                            <span>"{{ link.title }}"</span>
                            <span v-if="(index + 1) < (links.length - 1)">, </span>
                            <span v-else-if="(index + 1) < links.length"> and </span>
                        </span>
                        <span> updates.</span>
                    </span>
                </template>
            </base-tooltip>
            <base-tooltip
                v-if="tooltip"
                class="text-gray-400"
                :content="tooltip"
            >
                <base-icon name="info-circle" size="sm" />
            </base-tooltip>
        </div>
        <div>
            <base-json-editor
                v-if="isJSON"
                v-model="model"
                class="min-h-xs"
            />
            <base-json-form
                v-else-if="isObject"
                v-model="model"
                :schema="data"
                :links="baseLinks"
                :errors="errors"
                wrap-class="inline-flex w-full overflow-hidden"
                item-class="flex-1"
            />
            <base-toggle
                v-else-if="isBoolEnum"
                v-model="model"
                :label="['False', 'True']"
                :disabled="disabled"
            />
            <base-select
                v-else-if="isEnum"
                v-model="model"
                class="inline-flex w-full overflow-hidden"
                size="medium"
                :placeholder="title"
                :disabled="disabled"
                :creatable="data.extra && data.extra.restricted === false"
                :options="data.enum || data.items.enum"
                :multiple="!!data.items && !!data.items.enum"
                :multiple-draggable="!!data.items && !!data.items.enum"
                :multiple-limit="data.maxItems"
            />
            <base-checkbox
                v-else-if="isBoolean"
                v-model="model"
                :label="title"
                :disabled="disabled"
            />
            <base-json-form-item-date
                v-else-if="isTime || isDate || isDatetime"
                v-model="model"
                :title="title"
                :type="data.type"
                :format="data.format"
                :disabled="disabled"
            />
            <base-input-number
                v-else-if="isNumber"
                v-model="model"
                class="w-auto"
                controls-position="right"
                :min="data.minimum"
                :max="data.maximum"
                :disabled="disabled"
            />
            <base-input-number
                v-else-if="isNumberString"
                v-model="model"
                controls-position="right"
                :min="data.extra && data.extra.minimum"
                :max="data.extra && data.extra.maximum"
                :disabled="disabled"
            />
            <base-input
                v-else
                v-model="model"
                class="w-full"
                :placeholder="title"
                :disabled="disabled"
                :type="(invalid || errors) ? 'danger' : 'default'"
            />
        </div>
        <base-json-form-item-error :validations="validations" :errors="errors" />
    </div>
</template>
