import Vue from 'vue';
import { TYPE } from '@/components/base-components/components/message';
import BaseMessage from '@/components/base-components/components/message/base-message';

const MessageConstructor = Vue.extend(BaseMessage);

const list = [];

const GAP_SIZE = 16;
let seed = 1;

export function close(id, userOnClose) {
    const index = list.findIndex(instance => instance.id === id);

    if (index === -1) return;

    const instance = list[index];

    if (!instance) return;

    userOnClose?.();

    const removeHeight = instance.$el.offsetHeight;

    list.splice(index, 1);

    if (list.length < 1) return;

    for (let i = index; i < list.length; i++) {
        const item = list[i];

        item.offset = Number.parseInt(item.$el.style.top, 10) - removeHeight - GAP_SIZE;
    }
}

function message(params) {
    let options = params;

    if (typeof params === 'string') {
        options = { message: params };
    }

    let verticalOffset = options.offset || 0;

    list.forEach(({ $el }) => {
        verticalOffset += ($el?.offsetHeight || 0) + GAP_SIZE;
    });

    verticalOffset += GAP_SIZE;

    const id = `message_${seed}`;

    seed += 1;

    const userOnClose = options.onClose;

    const instance = new MessageConstructor({
        propsData: {
            ...options,
            id,
            offset:    verticalOffset,
            onClose:   () => {
                close(id, userOnClose);
            },
            onDestroy: () => {
                instance.$destroy();
                instance.$el.parentNode.removeChild(instance.$el);
            },
        },
    });

    instance.$mount();
    document.body.appendChild(instance.$el);

    list.push(instance);

    return {
        close: () => {
            instance.close?.();
        },
    };
}

Object.values(TYPE).forEach((type) => {
    message[type] = (params) => {
        let options = params;

        if (typeof params === 'string') {
            options = { message: params };
        }

        return message({
            ...options,
            type,
        });
    };
});

export default message;
