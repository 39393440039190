<template>
    <button
        type="button"
        class="grow px-5 py-3 space-x-2 text-xl text-center rounded-t-md border cursor-pointer hover:bg-white focus:bg-white active:bg-white focus:outline-none focus:text-gray-600"
        :class="[
            active ? 'bg-white text-gray-700 border-b-0' : 'text-gray-500 bg-gray-50'
        ]"
        :key="authorization.type" @click="selectTab(authorization.type)"
        :id="'authorization-' + authorization.type"
    >
        {{ authorization.name }}
        <span
            class="py-0.5 inline-block px-2 rounded text-xs"
            :class="isSupported ? 'bg-emerald-200' : 'bg-amber-200'"
        >{{ isSupported ? $t('authorization label automated') : $t('authorization label custom') }}</span>
        <span
            v-if="isApi"
            class="py-0.5 inline-block px-2 rounded text-xs bg-science-blue-900 text-white"
        >{{ $t('authorization label recommended') }}</span>
    </button>
</template>

<script>
import capitalize from '@/assets/util/capitalize';

export default {
    name:     'AuthorizationTab',
    props:    {
        authorization: {
            type: Object,
        },
        active: {
            type: Boolean,
        },
        prefix: {
            type:    String,
            default: '',
            validator(value) {
                return [
                    'source',
                    'target',
                    '',
                ].indexOf(value) !== -1;
            },
        },
    },
    computed: {
        isSupported() {
            return this.authorization[`is${capitalize(this.prefix)}Supported`];
        },
        isApi() {
            return this.authorization.type === 'api';
        },
    },
    methods:  {
        buttonClasses() {
            return {
                'authorization-tab--active':   this.active,
            };
        },
        selectTab(type) {
            this.$emit('changeActive', type);
        },
    },
};
</script>
