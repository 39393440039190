<template>
    <div class="flex flex-col w-full h-full bg-gray-800">
        <the-loader />
        <manager-header :environment="environment" />
        <slot />
    </div>
</template>

<script>
import ManagerHeader from '@/components/manager/header';
import TheLoader from '@/components/the-loader';

export default {
    name:       'LayoutManager',
    components: {
        ManagerHeader,
        TheLoader,
    },
    data() {
        return {
            environment: window.config?.environment,
        };
    },
};
</script>
