<script>
export default {
    name: 'BaseDrawer',
};
</script>

<script setup>
import { computed } from 'vue';
import {
    enter as chatEnter,
    leave as chatLeave,
} from '@/assets/util/hubspot-chat-position';
import { useStack } from '@/components/base-components/composables/stack';

const props = defineProps({
    value:           {
        type:     Boolean,
        required: true,
    },
    size:            {
        type:    String,
        default: 'xl',
        validator(value) {
            return ['xs', 'sm', 'md', 'lg', 'xl', '2xl'].includes(value);
        },
    },
    render:          {
        type:      String,
        default:   'default',
        validator: value => ['default', 'lazy', 'always'].includes(value),
    },
    wrapperClass:    {
        type: String,
    },
    contentClass:    {
        type: String,
    },
    title:           {
        type: String,
    },
    closableOverlay: {
        type: Boolean,
    },
});

const emit = defineEmits({
    input:  null,
    close:  null,
    closed: null,
    enter:  null,
    leave:  null,
});

const show = computed({
    get() {
        return props.value;
    },
    set(value) {
        emit('input', value);

        if (!value) emit('close');
    },
});

const { zIndex } = useStack(show);

const close = () => {
    show.value = false;
};

const clickOverlay = () => {
    if (!props.closableOverlay) return;

    close();
};

const wrapperSize = computed(() => ({
    xs:    'max-w-xs',
    sm:    'max-w-sm',
    md:    'max-w-md',
    lg:    'max-w-lg',
    xl:    'max-w-xl',
    '2xl': 'max-w-2xl',
}[props.size]));

// TODO: Move outside and refactor usages?
const enter = el => chatEnter(el);
const leave = el => chatLeave(el);
</script>

<template>
    <div
        class="fixed inset-0 overflow-hidden"
        :class="{ 'pointer-events-none': !show }"
        :style="{
            'z-index': zIndex,
        }"
    >
        <div
            class="absolute inset-0 h-screen w-screen bg-black transition duration-500 ease-in-out z-max"
            :class="show ? 'opacity-40' : 'opacity-0 pointer-events-none'"
            @click="clickOverlay"
        />

        <transition
            enter-active-class="transition duration-500 ease-in-out"
            leave-active-class="transition duration-500 ease-in-out"
            leave-to-class="translate-x-full"
            enter-class="translate-x-full"
            @enter="enter"
            @leave="leave"
            @after-leave="emit('closed')"
        >
            <div
                v-show="show"
                class="absolute top-0 right-0 h-screen w-screen shadow-lg font-avenir z-max"
                :class="[
                    wrapperClass || 'bg-white',
                    wrapperSize,
                ]"
            >
                <div class="flex h-full flex-col">
                    <slot name="header" :close="close">
                        <div class="flex justify-end bg-gray-800 p-4 space-x-4">
                            <slot name="title">
                                <span
                                    v-if="title !== undefined"
                                    class="flex-1 self-center whitespace-nowrap text-xl font-semibold text-white"
                                >
                                    {{ title }}
                                </span>
                            </slot>

                            <div
                                class="cursor-pointer rounded-md p-2 text-white hover:bg-gray-700"
                                @click="close"
                            >
                                <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     stroke="currentColor"
                                     aria-hidden="true"
                                >
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                          d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            </div>
                        </div>
                    </slot>

                    <slot name="content">
                        <base-scroll
                            class="flex-1 py-4"
                            wrap-class="overflow-x-hidden"
                            :view-class="contentClass"
                        >
                            <slot />
                        </base-scroll>
                    </slot>

                    <slot name="footer" />
                </div>
            </div>
        </transition>
    </div>
</template>
