import User from '@/pages/components/user';
import { USER } from '@/store/modules/acl-access/constants';

export default [
    {
        name:      'user',
        path:      '/user/',
        component: User,
        meta:      {
            layout: 'content',
        },
        redirect:  { name: 'userLogin' },
        children:  [
            {
                name:      'userSettings',
                path:      'settings/',
                component: () => import('@/pages/components/user/settings'),
                meta:      {
                    section:     'settings',
                    title:       (route, { i18n }) => i18n.t('page title user settings'),
                    acl:         USER.SETTINGS,
                    breadcrumbs: (route, { i18n }) => ([
                        {
                            label: i18n.t('header breadcrumb settings'),
                        },
                    ]),
                },
                redirect:  { name: 'userSettingsGeneral' },
                children:  [
                    {
                        name:      'userSettingsGeneral',
                        path:      'general/',
                        component: () => import('@/pages/components/user/settings/general'),
                        meta:      {
                            title:       (route, { i18n }) => i18n.t('user.settings.section.general.title'),
                            breadcrumbs: (route, { i18n }) => ([
                                {
                                    label: i18n.t('user.settings.breadcrumb'),
                                },
                                {
                                    label: i18n.t('user.settings.section.general.breadcrumb'),
                                },
                            ]),
                        },
                    },
                    {
                        name:      'userSettingsSecurity',
                        path:      'security/',
                        component: () => import('@/pages/components/user/settings/security'),
                        meta:      {
                            title:       (route, { i18n }) => i18n.t('user.settings.section.security.title'),
                            breadcrumbs: (route, { i18n }) => ([
                                {
                                    label: i18n.t('user.settings.breadcrumb'),
                                },
                                {
                                    label: i18n.t('user.settings.section.security.breadcrumb'),
                                },
                            ]),
                        },
                    },
                    {
                        name:      'userSettingsPlan',
                        path:      'plan/',
                        component: () => import('@/pages/components/user/settings/plan'),
                        meta:      {
                            title:       (route, { i18n }) => i18n.t('user.settings.section.plan.title'),
                            breadcrumbs: (route, { i18n }) => ([
                                {
                                    label: i18n.t('user.settings.breadcrumb'),
                                },
                                {
                                    label: i18n.t('user.settings.section.plan.breadcrumb'),
                                },
                            ]),
                        },
                    },
                    {
                        name:      'userSettingsBilling',
                        path:      'billing/',
                        component: () => import('@/pages/components/user/settings/billing'),
                        meta:      {
                            title:       (route, { i18n }) => i18n.t('user.settings.section.billing.title'),
                            breadcrumbs: (route, { i18n }) => ([
                                {
                                    label: i18n.t('user.settings.breadcrumb'),
                                },
                                {
                                    label: i18n.t('user.settings.section.billing.breadcrumb'),
                                },
                            ]),
                        },
                    },
                    {
                        name:     'userSettingsTeams',
                        path:     'teams/',
                        redirect: { name: 'userSettingsGeneral' },
                    },
                ],
            },
            {
                name:      'userLogin',
                path:      'login/',
                component: () => import('@/pages/components/user/login'),
                meta:      {
                    title:  (route, { i18n }) => i18n.t('page title user login'),
                    acl:    USER.LOGIN,
                    layout: 'content',
                },
            },
            {
                name:      'userRegister',
                path:      'register/',
                component: () => import('@/pages/components/user/register'),
                meta:      {
                    title:  (route, { i18n }) => i18n.t('page title user register'),
                    acl:    USER.REGISTER,
                    layout: 'content',
                },
            },
            {
                path:      'password/',
                component: () => import('@/pages/components/user/password'),
                meta:      {
                    layout: 'content',
                },
                redirect:  { name: 'userLogin' },
                children:  [
                    {
                        name:      'userPasswordForgot',
                        path:      'forgot/',
                        component: () => import('@/pages/components/user/password/forgot'),
                        meta:      {
                            title:  (route, { i18n }) => i18n.t('page title user password forgot'),
                            acl:    USER.FORGOT_PASSWORD,
                            layout: 'content',
                        },
                    },
                    {
                        name:      'userPasswordReset',
                        path:      'reset/:hash/:email',
                        component: () => import('@/pages/components/user/password/reset'),
                        meta:      {
                            title:  (route, { i18n }) => i18n.t('page title user password reset'),
                            acl:    USER.RESET_PASSWORD,
                            layout: 'content',
                        },
                    },
                    {
                        name:      'userPasswordExpired',
                        path:      'expired/:hash/:email',
                        component: () => import('@/pages/components/user/password/expired'),
                        meta:      {
                            title:  (route, { i18n }) => i18n.t('page title user password expired'),
                            acl:    USER.EXPIRED_PASSWORD,
                            layout: 'content',
                        },
                    },
                ],
            },
            {
                name:      'userApprove',
                path:      'approve/:hash?',
                component: () => import('@/pages/components/user/approve'),
                meta:      {
                    title:  (route, { i18n }) => i18n.t('page title user approve'),
                    acl:    USER.APPROVE,
                    layout: 'content',
                },
            },
            {
                name:      'userUnsubscribe',
                path:      'unsubscribe/:hash',
                component: () => import('@/pages/components/user/unsubscribe'),
                meta:      {
                    title:  (route, { i18n }) => i18n.t('page title user unsubscribe'),
                    acl:    USER.UN_SUBSCRIBE,
                    layout: 'content',
                },
            },
            {
                name:      'userThankYou',
                path:      'thank-you',
                component: () => import('@/pages/components/user/thank-you'),
                meta:      {
                    title: (route, { i18n }) => i18n.t('page title user thank you'),
                },
            },
            {
                name:      'userTwoFactor',
                path:      '2fa',
                component: () => import('@/pages/components/user/two-factor'),
                meta:      {
                    title:  (route, { i18n }) => i18n.t('page title user two factor'),
                    layout: 'content',
                    acl:    USER.TWO_FACTOR,
                },
            },
        ],
    },
];
