<template>
    <div class="flex-1 flex">
        <base-button
            id="add-funds-button"
            size="small"
            text
            stretch
            :label="`${$t('user balance')} ${formatBalance}`"
            :data-test="dataTest()"
            @click="click"
        >
            <template #prefix>
                <svg
                    class="w-5 h-5"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                </svg>
            </template>
        </base-button>
        <add-funds
            :show="showAddFunds"
            @close="showAddFunds = false"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { isFinite } from 'lodash';
import AddFunds from '@/components/add-funds';

export default {
    name:       'NavigationBalance',
    components: { AddFunds },
    data() {
        return {
            showAddFunds: false,
        };
    },
    computed: {
        ...mapGetters({
            balance: 'user/getBalance',
        }),
        formatBalance() {
            const balance = parseFloat(this.balance);

            return this.$filters.formatCurrency(!balance || !isFinite(balance) ? 0 : balance);
        },
    },
    methods:  {
        click() {
            this.$emit('navigate');
            this.showAddFunds = true;
        },
    },
};
</script>
