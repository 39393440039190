const state = {
    data: undefined,
};

const mutations = {
    applyManagerData(s, payload) {
        s.data = { ...s.data, ...payload };
    },
    clearManagerData(s) {
        s.data = undefined;
    },
};

export default {
    state,
    mutations,
};
