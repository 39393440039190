const directive = {
    inserted(el, binding) {
        if (binding.value !== undefined && !binding.value) {
            return;
        }

        el.focus();
    },
};

export default directive;
