import { useStorage } from '@/storage';

const { get, set, remove } = useStorage('app-return-url', window.sessionStorage);

export const redirect = (routerCallback, defaultRoute = { name: 'root' }) => {
    const returnUrl = get();

    if (!returnUrl) return routerCallback(defaultRoute);

    window.location = returnUrl;
};

export {
    get,
    set,
    remove,
};
