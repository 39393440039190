<template>
    <div class="base-breadcrumb-divider">
        <base-icon
            v-if="isIcon"
            :class="divider.class"
            :name="divider.name"
            :prefix="divider.prefix"
        />
        <span v-else>
            {{ divider }}
        </span>
    </div>
</template>

<script>
import isObject from 'lodash/isObject';

export default {
    name:     'BaseBreadcrumbDivider',
    props:    {
        divider: {
            type:    [String, Object],
            default: '/',
        },
    },
    computed: {
        isIcon() {
            return isObject(this.divider);
        },
    },
};
</script>

<style>
.base-breadcrumb-divider {
    @apply hidden;
}

.base-breadcrumb-item + .base-breadcrumb-divider {
    @apply flex justify-center items-center text-gray-400;
}
</style>
