export const TYPE = Object.freeze({
    SUCCESS: 'success',
    INFO:    'info',
    WARNING: 'warning',
    ERROR:   'error',
});

export const POSITION = Object.freeze({
    TOP_RIGHT:    'top-right',
    TOP_LEFT:     'top-left',
    BOTTOM_RIGHT: 'bottom-right',
    BOTTOM_LEFT:  'bottom-left',
});

export function getVerticalProperty(position) {
    return {
        [POSITION.TOP_RIGHT]:    'top',
        [POSITION.TOP_LEFT]:     'top',
        [POSITION.BOTTOM_RIGHT]: 'bottom',
        [POSITION.BOTTOM_LEFT]:  'bottom',
    }[position];
}

export function getHorizontalProperty(position) {
    return {
        [POSITION.TOP_RIGHT]:    'right',
        [POSITION.TOP_LEFT]:     'left',
        [POSITION.BOTTOM_RIGHT]: 'right',
        [POSITION.BOTTOM_LEFT]:  'left',
    }[position];
}
