const DataTest = {
    install(Vue) {
        Vue.mixin({
            methods: {
                dataTest(name) {
                    return [this.$options.name, name].filter(item => item !== undefined && item !== null).join('-');
                },
                dataTour(name) {
                    return this.dataTest(name);
                },
            },
        });
    },
};

export default DataTest;
