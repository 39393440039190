<script>
export default {
    name: 'BaseScrollBar',
};
</script>

<script setup>
import { computed, inject, onBeforeUnmount, ref, toRef } from 'vue';
import { useEventListener } from '@vueuse/core';
import { BAR_MAP, renderThumbStyle } from '@/components/base-components/components/scroll/utils';

const props = defineProps({
    ratio:    {
        type:     Number,
        required: true,
    },
    vertical: {
        type: Boolean,
    },
    size:     {
        type: String,
    },
    move:     {
        type: Number,
    },
    always:   {
        type: Boolean,
    },
});

const baseScroll = inject('baseScroll');

const instance = ref(null);
const thumb = ref(null);

const thumbState = ref({});
const visible = ref(false);

let cursorDown = false;
let cursorLeave = false;
let originalOnSelectStart = document.onselectstart;

const bar = computed(() => BAR_MAP[props.vertical ? 'vertical' : 'horizontal']);

const thumbStyle = computed(() => renderThumbStyle({
    size: props.size,
    move: props.move,
    bar:  bar.value,
}));

// offsetRatioX = original width of thumb / current width of thumb / ratioX
// offsetRatioY = original height of thumb / current height of thumb / ratioY
// instance height = wrap height - GAP
const offsetRatio = computed(() => instance.value?.[bar.value.offset] ** 2 / baseScroll.wrapElement?.[bar.value.scrollSize] / props.ratio / thumb.value?.[bar.value.offset]);

const clickThumbHandler = (event) => {
    // prevent click event of middle and right button
    event.stopPropagation();

    if (event.ctrlKey || [1, 2].includes(event.button)) return;

    window.getSelection()?.removeAllRanges();

    startDrag(event);

    const el = event.currentTarget;

    if (!el) return;

    thumbState.value[bar.value.axis] = el[bar.value.offset] - (event[bar.value.client] - el.getBoundingClientRect()[bar.value.direction]);
};

const clickTrackHandler = (event) => {
    if (!thumb.value || !instance.value || !baseScroll.wrapElement) return;

    const offset = Math.abs(event.target.getBoundingClientRect()[bar.value.direction] - event[bar.value.client]);
    const thumbHalf = thumb.value[bar.value.offset] / 2;
    const thumbPositionPercentage = ((offset - thumbHalf) * 100 * offsetRatio.value) / instance.value[bar.value.offset];

    baseScroll.wrapElement[bar.value.scroll] = (thumbPositionPercentage * baseScroll.wrapElement[bar.value.scrollSize]) / 100;
};

let cleanMouseMove;
let cleanMouseUp;

const startDrag = (event) => {
    event.stopImmediatePropagation();
    cursorDown = true;

    cleanMouseMove = useEventListener(document, 'mousemove', mouseMoveDocumentHandler);
    cleanMouseUp = useEventListener(document, 'mouseup', mouseUpDocumentHandler);

    originalOnSelectStart = document.onselectstart;

    document.onselectstart = () => false;
};

const mouseMoveDocumentHandler = (event) => {
    if (!instance.value || !thumb.value) return;
    if (cursorDown === false) return;

    const prevPage = thumbState.value[bar.value.axis];

    if (!prevPage) return;

    const offset = (instance.value.getBoundingClientRect()[bar.value.direction] - event[bar.value.client]) * -1;
    const thumbClickPosition = thumb.value[bar.value.offset] - prevPage;
    const thumbPositionPercentage = ((offset - thumbClickPosition) * 100 * offsetRatio.value) / instance.value[bar.value.offset];

    baseScroll.wrapElement[bar.value.scroll] = (thumbPositionPercentage * baseScroll.wrapElement[bar.value.scrollSize]) / 100;
};

const mouseUpDocumentHandler = () => {
    cursorDown = false;
    thumbState.value[bar.value.axis] = 0;

    cleanMouseMove?.();
    cleanMouseUp?.();

    restoreOnselectstart();

    if (cursorLeave) visible.value = false;
};

const mouseMoveScrollbarHandler = () => {
    cursorLeave = false;
    visible.value = !!props.size;
};

const mouseLeaveScrollbarHandler = () => {
    cursorLeave = true;
    visible.value = cursorDown;
};

onBeforeUnmount(() => {
    restoreOnselectstart();
    cleanMouseUp?.();
});

const restoreOnselectstart = () => {
    if (document.onselectstart === originalOnSelectStart) return;

    document.onselectstart = originalOnSelectStart;
};

useEventListener(
    toRef(baseScroll, 'scrollElement'),
    'mousemove',
    mouseMoveScrollbarHandler,
);
useEventListener(
    toRef(baseScroll, 'scrollElement'),
    'mouseleave',
    mouseLeaveScrollbarHandler,
);
</script>

<template>
    <transition
        enter-active-class="transition-opacity duration-300 ease-out"
        leave-active-class="transition-opacity duration-150 ease-out"
        enter-class="opacity-0"
        leave-to-class="opacity-0"
    >
        <div
            v-show="always || visible"
            ref="instance"
            class="absolute rounded right-0.5 bottom-0.5"
            :class="{
                'top-0.5 w-1.5': vertical,
                'left-0.5 h-1.5': !vertical,
            }"
            @mousedown="clickTrackHandler"
        >
            <div
                ref="thumb"
                class="relative block h-0 w-0 cursor-pointer rounded bg-gray-400 bg-opacity-75 transition-colors hover:bg-opacity-100 active:bg-opacity-100"
                :class="[
                    vertical ? 'w-full' : 'h-full',
                ]"
                :style="thumbStyle"
                @mousedown="clickThumbHandler"
            />
        </div>
    </transition>
</template>
