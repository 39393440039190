import Vue from 'vue';
import { getVerticalProperty, POSITION, TYPE } from '@/components/base-components/components/notification';
import BaseNotification from '@/components/base-components/components/notification/base-notification';

const NotificationConstructor = Vue.extend(BaseNotification);

const notifications = {
    [POSITION.TOP_RIGHT]:    [],
    [POSITION.TOP_LEFT]:     [],
    [POSITION.BOTTOM_RIGHT]: [],
    [POSITION.BOTTOM_LEFT]:  [],
};

const GAP_SIZE = 16;
let seed = 1;

export function close(id, position, userOnClose) {
    const list = notifications[position];
    const index = list.findIndex(instance => instance.id === id);

    if (index === -1) return;

    const instance = list[index];

    if (!instance) return;

    userOnClose?.();

    const removeHeight = instance.$el.offsetHeight;

    list.splice(index, 1);

    const length = list.length;

    if (length < 1) return;

    const verticalProperty = getVerticalProperty(position);

    for (let i = index; i < length; i++) {
        const item = list[i];

        item.offset = Number.parseInt(item.$el.style[verticalProperty], 10) - removeHeight - GAP_SIZE;
    }
}

function notify(params) {
    let options = params;

    if (typeof params === 'string') {
        options = { message: params };
    }

    const position = options.position || POSITION.TOP_RIGHT;

    let verticalOffset = options.offset || 0;

    notifications[position].forEach(({ $el }) => {
        verticalOffset += ($el?.offsetHeight || 0) + GAP_SIZE;
    });

    verticalOffset += GAP_SIZE;

    const id = `notification_${seed}`;

    seed += 1;

    const userOnClose = options.onClose;

    const instance = new NotificationConstructor({
        propsData: {
            ...options,
            id,
            offset:    verticalOffset,
            onClose:   () => {
                close(id, position, userOnClose);
            },
            onDestroy: () => {
                instance.$destroy();
                instance.$el.parentNode.removeChild(instance.$el);
            },
        },
    });

    instance.$mount();
    document.body.appendChild(instance.$el);

    notifications[position].push(instance);

    return {
        close: () => {
            instance.close?.();
        },
    };
}

Object.values(TYPE).forEach((type) => {
    notify[type] = (params) => {
        let options = params;

        if (typeof params === 'string') {
            options = { message: params };
        }

        return notify({
            ...options,
            type,
        });
    };
});

export default notify;
