import qs from 'qs';
import { instance } from '@/api/api';
import { getUrl } from '@/api/utils';

export const actionUrl = (backupId, action, queryParams = {}) => getUrl(`/backup/${backupId}/${action}`, queryParams);

function GET(backupId, action, queryParams, config) {
    return instance.get(actionUrl(backupId, action, queryParams), config);
}

function POST(backupId, action, data, config) {
    return instance.post(actionUrl(backupId, action), qs.stringify(data), config);
}

export function backupInfo(backupId) {
    return GET(backupId, 'info');
}

export function backupEntityList(backupId) {
    return GET(backupId, 'entity');
}

export function backupEntityRefresh(backupId) {
    return GET(backupId, 'entity/refresh');
}

export function backupEntitySave(backupId, entities) {
    return POST(backupId, 'entity/save', { entities });
}

export function backupInstance(backupId, instanceId) {
    return GET(backupId, `instance/${instanceId}`);
}

export function backupInstanceList(backupId) {
    return GET(backupId, 'instance');
}

export function backupInstanceCreate(backupId, type) {
    return POST(backupId, 'instance/create', { type });
}

export function backupInstanceStop(backupId, instanceId) {
    return GET(backupId, `instance/${instanceId}/stop`);
}

export function backupExport(backupId, exportId) {
    return GET(backupId, `export/${exportId}`);
}

export function backupExportList(backupId) {
    return GET(backupId, 'export');
}

export function backupExportCreate(backupId, instanceId, format) {
    return POST(backupId, `instance/${instanceId}/export/create`, { format });
}

export function backupExportStop(backupId, exportId) {
    return GET(backupId, `export/${exportId}/stop`);
}

export function backupSchedule(backupId) {
    return GET(backupId, 'schedule');
}

export function backupScheduleSave(backupId, data) {
    return POST(backupId, 'schedule/save', { data });
}

export function backupPricing(backupId) {
    return GET(backupId, 'pricing');
}

export function backupPricingBuy(backupId, { id, format, increment, coupon }) {
    return GET(backupId, 'pricing/buy', { id, format, increment, coupon });
}
