import { MAPPING } from '@/assets/util/event-constants';

export default function processMessages(response) {
    const messages = response.data?.messages;

    if (!messages) return false;

    window.emitter.emit(MAPPING.MESSAGES, messages);

    return true;
}
