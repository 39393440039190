/**
 * Percent

 * @param {Number} total
 * @param {Number} part
 * @param {Boolean} round
 *
 * @returns {Number}
 */
const percent = (total, part, round = true) => {
    if (total === 0 || part === 0) return 0;

    const result = (part * 100) / total;

    return round ? Math.round(result) : result;
};

export default percent;
