<template>
    <div
        class="rounded-md bg-gray-200 transition duration-150 ease-in-out p-0.5"
        :class="{ 'cursor-not-allowed pointer-events-none grayscale opacity-75': disabled }"
    >
        <div class="relative flex items-center font-semibold">
            <div
                v-for="option in options"
                ref="baseSwitcherItem"
                class="z-10 flex select-none items-center justify-center rounded px-3 py-1 transition duration-150 ease-in-out"
                :key="option.value"
                :id="option.value"
                :class="{
                    'text-azure-800 grayscale-0': option.value === activeOption.value,
                    'text-gray-500 grayscale': option.value !== activeOption.value,
                    'cursor-pointer hover:text-azure-800 hover:grayscale-0': option.value !== activeOption.value && option.disabled !== true,
                    'cursor-not-allowed': option.disabled === true,
                    'flex-1': stretch,
                }"
                @click="activate(option)"
            >
                <slot name="option" :option="option" :selected="option.value === activeOption.value">
                    <span v-text="option.label" class="text-center text-sm" />
                </slot>
            </div>
            <div
                class="absolute z-0 m-0 h-full rounded bg-white transition-all duration-300 ease-in-out"
                :style="activeStyle"
            />
        </div>
    </div>
</template>

<script>
import { find } from 'lodash';

export default {
    name:  'BaseSwitcher',
    props: {
        options:  {
            type: Array,
        },
        value:    {
            type: String,
        },
        disabled: {
            type: Boolean,
        },
        stretch:  {
            type: Boolean,
        },
    },
    data() {
        return {
            activeStyle: {},
        };
    },
    computed: {
        activeOption() {
            return find(this.options, option => option.value === this.value) ?? this.options[0];
        },
    },
    watch:    {
        activeOption: {
            immediate: true,
            async handler() {
                await this.$nextTick();

                this.calculateActiveStyle();
            },
        },
    },
    methods:  {
        calculateActiveStyle() {
            const style = {};
            let offset = 0;
            let optionSize = 0;

            this.options.every((option) => {
                const $el = find(this.$refs.baseSwitcherItem || [], refItem => refItem.id === option.value);

                if (!$el) {
                    return false;
                }

                if (option.value !== this.value) {
                    offset += $el.clientWidth;
                    return true;
                }

                optionSize = $el.clientWidth;

                return false;
            });

            const transform = `translateX(${offset}px)`;

            style.width = `${optionSize}px`;
            style.transform = transform;
            style.msTransform = transform;
            style.webkitTransform = transform;

            this.activeStyle = style;
        },
        activate(option) {
            if (this.disabled || option.disabled) {
                return;
            }

            this.$emit('input', option.value);
            this.$emit('change', option.value, option);
        },
    },
};
</script>
