import qs from 'qs';
import Vue from 'vue';
import reactive from '@/assets/util/reactive-object';

const getBaseState = () => ({
    products: {},
});

export default {
    namespaced: true,
    state:      getBaseState(),
    getters:    {
        get: state => (product, direction) => reactive.get(state.products, [product, direction].filter(Boolean)),
    },
    mutations:  {
        SET(state, { product, direction, list }) {
            reactive.set(state.products, [product, direction], list);
        },
        RESET_STATE(state) {
            Object.assign(state, getBaseState());
        },
    },
    actions:    {
        async LOAD({ commit }, { product, direction }) {
            if (!product) return;

            const list = await Vue.prototype.$http.post('/platform/list', qs.stringify({ product, direction }));

            commit('SET', { product, direction, list });
        },
        RESET_STATE({ commit }) {
            commit('RESET_STATE');
        },
    },
};
