<script>
import SidebarSection from '@/components/app/sidebar/section/section';

export default {
    name:     'SidebarSettings',
    extends:     SidebarSection,
    data() {
        return {
            sectionName:  'settings',
            sectionRoute: 'userSettings',
            sectionLabel: this.$t('user.settings.title'),
            sectionIcon:  {
                name:   'sliders',
                prefix: 'fas',
            },
        };
    },
};
</script>
